import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useTheme } from '@material-ui/core/styles';
import moment from 'moment';

import { GalleryTab } from 'components/BoardTabs';

import { CalendarEvent } from '../CalendarEvent/CalendarEvent';

import { useStyle } from './CalendarEvents.style';

const maxEventNumber = 3;

const timeSlots: string[] = [
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '19:30',
  '20:00',
];

export const CalendarEvents: FC<{
  date: string;
  events: GalleryEvent[];
  activeClass: string;
}> = ({ date, events, activeClass }) => {
  const history = useHistory();
  const { groupWrapper, moreEvents } = useStyle();
  const theme = useTheme();

  const matchingOfficeColour = [
    {
      office: 'All Offices',
      colour: theme.palette.officeColours.AllOffices,
    },
    { office: 'Berlin', colour: theme.palette.officeColours.Berlin },
    { office: 'Copenhagen', colour: theme.palette.officeColours.Copenhagen },
    { office: 'Frankfurt', colour: theme.palette.officeColours.Frankfurt },
    { office: 'Hamburg', colour: theme.palette.officeColours.Hamburg },
    { office: 'Helsinki', colour: theme.palette.officeColours.Helsinki },
    { office: 'Munich', colour: theme.palette.officeColours.Munich },
    { office: 'Oslo', colour: theme.palette.officeColours.Oslo },
    { office: 'Stockholm', colour: theme.palette.officeColours.Stockholm },
    { office: 'Zurich', colour: theme.palette.officeColours.Zurich },
    { office: 'Test Office', colour: theme.palette.officeColours.TestOffice },
    {
      office: 'Netlight Calendar',
      colour: theme.palette.officeColours.NetlightCalendar,
    },
  ];

  const startEventsTime = '07:00';
  const endEventsTime = '19:00';

  const isSameDate = (startTime) => {
    return startTime.isSame(moment(date), 'day');
  };

  const isAfterStartOfDay = (startTime) =>
    moment(startTime).format('HH:mm') >= startEventsTime;

  const isBeforeEndOfDay = (startTime) =>
    moment(startTime).format('HH:mm') <= endEventsTime;

  const isTimeInsideRange = (startTime) =>
    isAfterStartOfDay(startTime) && isBeforeEndOfDay(startTime);

  const allWeekEvents = events
    .filter((event) => {
      const startTime = moment(event.startTime);

      return isSameDate(startTime) && isTimeInsideRange(startTime) && events;
    })
    .sort((a, b) => a.startTime.localeCompare(b.startTime));

  const dayEvents = timeSlots.map((timeSlot) => {
    const matchingEvents = allWeekEvents.filter((event) => {
      const eventStartTime = moment(event.startTime).format('HH:mm');
      return timeSlot === eventStartTime && event;
    });

    return matchingEvents;
  });

  const eventDuration = (event) => {
    return moment(event.endTime).diff(moment(event.startTime), 'minutes');
  };

  const renderEmptyEvents = (index) => {
    return (
      <CalendarEvent key={index} activeClass={activeClass} noEvent={true} />
    );
  };

  const renderEvents = (event) => {
    return (
      <CalendarEvent
        id={event._id}
        key={event._id}
        isRemote={!!event.remoteLink}
        title={event.title}
        office={event.office}
        officeColour={matchingOfficeColour.find(
          (office) => office.office === event.office
        )}
        eventDuration={eventDuration(event)}
      />
    );
  };

  const numberOfNotShownEvents = (weekEvents) => {
    return (
      weekEvents.length - maxEventNumber > 0 && (
        <div
          className={moreEvents}
          onClick={() => history.push(`/${GalleryTab.Events}/grid`)}
        >
          + {weekEvents.length - maxEventNumber}
        </div>
      )
    );
  };

  const maxEventsToRender = (weekEvents) => {
    return (
      <div className={`${weekEvents.length > 1 && groupWrapper}`}>
        {weekEvents.length > maxEventNumber ? (
          <>
            {weekEvents
              .slice(0, maxEventNumber)
              .map((event) => renderEvents(event))}
            {numberOfNotShownEvents(weekEvents)}
          </>
        ) : (
          weekEvents.map((event) => renderEvents(event))
        )}
      </div>
    );
  };

  return (
    <>
      {dayEvents.map((weekEvents, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
          {weekEvents.length > 0 && maxEventsToRender(weekEvents)}
          {weekEvents.length === 0 && renderEmptyEvents(index)}
        </div>
      ))}
    </>
  );
};
