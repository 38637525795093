import React, { FC } from 'react';
import { LinearProgress } from '@material-ui/core';

import { useStyles } from './LoadingWrapper.style';

export const LoadingWrapper: FC<{ loading: boolean }> = ({ loading }) => {
  const styles = useStyles();

  return (
    <div className={styles.loadingWrapper}>
      {loading && <LinearProgress variant="indeterminate" color="primary" />}
    </div>
  );
};
