import { makeStyles } from '@material-ui/core/styles';

export const useGalleryDeleteButtonStyles = makeStyles((theme) => {
  return {
    deleteButton: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      width: '36px',
      height: '36px',
      padding: 0,

      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    deleteIcon: {
      fontSize: '1.5rem',
    },
  };
});
