import React, { FC, useEffect } from 'react';
import { Users } from 'react-feather';
import { XCircle as Close } from 'react-feather';
import { InputAdornment, TextField } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Autocomplete } from '@material-ui/lab';
import classNames from 'classnames';
import throttle from 'lodash/throttle';

import { removeNullUsers } from 'utils/userUtils';

import { useGalleryFormStyle } from './GalleryForm.style';
import { useFormfieldStyle } from './GalleryFormfield.style';

const MAX_TAG_AMOUNT = 3;

type AttendeeAutocomplete<T> = {
  existingAttendees: T;
  inputType: T;
  inactive?: boolean;
  name: string;
  label?: string;
  error?: string;
  setField: (value: T) => void;
  getOptionLabel?: (option: T) => T;
  getOptionSelected?: (option: T, value: T) => boolean;
  sortOptions?: (options: T[], prefix: T) => T;
  filterOptions?: (option: T, value: T) => T[];
  asyncApiFunction: (prefix: T) => Promise<T>;
  renderOption?: (option: T, { selected: T }) => JSX.Element;
  renderTags?: (option: T, prop: T) => JSX.Element;
  multiple: boolean;
};

// We do not want to use any here, but FC generic props have to be provided at initialization.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AttendeeAutocomplete: FC<AttendeeAutocomplete<any>> = ({
  existingAttendees,
  inputType,
  name,
  label,
  error,
  setField,
  getOptionLabel,
  getOptionSelected,
  sortOptions,
  filterOptions,
  asyncApiFunction,
  renderOption,
  renderTags,
  multiple,
}) => {
  const [value, setValue] = React.useState<typeof inputType[] | []>(
    existingAttendees ?? []
  );
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState<typeof inputType[]>([]);
  const loading = React.useRef(false);
  const [matchCount, setMatchCount] = React.useState<number>(0);
  const isEmptyOrSpaces = (str: string) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  const hasNoMatches = () => {
    const isEmptyResponse = matchCount === 0;
    const isEmptyInput = isEmptyOrSpaces(inputValue);
    return isEmptyResponse && !isEmptyInput;
  };

  const fetch = React.useMemo(
    () =>
      throttle(
        async (
          request: { input: string },
          callback: (results?: typeof inputType[]) => void
        ) => {
          loading.current = true;
          const response = await asyncApiFunction(request.input);
          callback(response);
        },
        100
      ),
    [asyncApiFunction, inputType]
  );

  useEffect(() => {
    let active = true;
    const cleanedValue = inputValue.replace(/[^\w\s]/gi, '');

    if (isEmptyOrSpaces(inputValue) || cleanedValue.length === 0) {
      return undefined;
    }

    fetch({ input: cleanedValue }, (results?: typeof inputType[]) => {
      loading.current = false;
      if (active) {
        setMatchCount(results?.length ?? 0);
        let newOptions = [] as typeof inputType[];
        if (value) {
          newOptions = value;
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(removeNullUsers(newOptions));
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, inputType, fetch, sortOptions]);

  const classes = useGalleryFormStyle();
  const formfieldClasses = useFormfieldStyle();

  return (
    <Autocomplete
      autoComplete
      value={value}
      limitTags={MAX_TAG_AMOUNT}
      noOptionsText={hasNoMatches() ? 'No options' : 'Start typing...'}
      disableCloseOnSelect={true}
      popupIcon={false}
      forcePopupIcon={false}
      id={name}
      multiple={multiple}
      options={
        !!sortOptions && !!inputValue
          ? sortOptions(options, inputValue)
          : options
      }
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      filterOptions={filterOptions}
      //onChange fires when user select in dropdown
      onChange={(_event, newValue: typeof inputType | null) => {
        setField(newValue);
        setValue(newValue);
      }}
      //onInputChange fires when user types - disallows null or empty space
      onInputChange={(_event, newInputValue: string) => {
        setInputValue(newInputValue);
      }}
      className={classNames(
        formfieldClasses.inputField,
        classes.autocompleteField
      )}
      renderOption={renderOption}
      renderTags={renderTags}
      classes={{ paper: classes.dropdownStyle }}
      closeIcon={<Close />}
      renderInput={(params) => (
        <TextField
          {...params}
          error={!!error}
          classes={{
            root: classes.autocompleteInput,
          }}
          className={formfieldClasses.inputWithAdornment}
          InputLabelProps={{
            className: value.length > 0 ? 'MuiFormLabel-filled' : '',
          }}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading.current ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
            startAdornment: (
              <>
                <span className={classes.absoluteInputStyle}>
                  <InputAdornment position="start">
                    <Users />
                  </InputAdornment>
                </span>
                {params.InputProps.startAdornment}
              </>
            ),
          }}
          variant="outlined"
          fullWidth
        />
      )}
    />
  );
};
