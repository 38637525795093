import React, { FC, useEffect, useState } from 'react';
import { DateRange, DateRangePicker } from 'react-date-range';
import { Button, DialogActions } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment';
import { initialBoardState, useBoardContext } from 'store/BoardProvider';
import { resetDateRange, setDateRange } from 'store/reducers/filter';

import { StoreDateRange } from 'types/Filters';

import { useDateRangePickerDialogStyles } from './DateRangePickerDialogStyle';

type DateRangePickerDialogProps = {
  className: string;
  dateRangeDialogOpen: boolean;
  onClose: () => void;
  clearedDateRange: boolean;
};

// we need to make transformations from datePickerRange to our actual timerange filter
// because dateRangePicker works on native date objects
export const formatRangeForStore = ({
  startDate,
  endDate,
}: DateRange): StoreDateRange => {
  return {
    startDate: moment(startDate).startOf('day'),
    endDate: moment(endDate).endOf('day'),
  };
};

export const formatRangeForPicker = ({
  startDate,
  endDate,
}: StoreDateRange): DateRange => {
  return {
    // ToDo: The actual problem is timezone-related and needs to be investigated further
    // -> setting to start/end of day leads to inconsitencies in the UI because of Sommerzeit
    startDate: startDate
      .hours(12)
      .minutes(0)
      .seconds(0)
      .milliseconds(0)
      .toDate(),
    endDate: endDate.hours(12).minutes(0).seconds(0).milliseconds(0).toDate(),
    key: 'selection',
  };
};

export const DateRangePickerDialog: FC<DateRangePickerDialogProps> = ({
  className,
  dateRangeDialogOpen,
  onClose,
  clearedDateRange = false,
}) => {
  const {
    dateRangeDialog,
    dialogContent,
    dateRange,
    buttonRow,
  } = useDateRangePickerDialogStyles();

  // set now to noon in order to be correct for all timezones and to make selected filter work
  const now = new Date(Date.now()).setHours(12, 0, 0, 0);
  const today = moment(now).toDate();
  const tomorrow = moment(now).add(1, 'd').toDate();
  const oneWeekFromToday = moment(today).add(7, 'd').toDate();

  const startOfThisWeek = moment(now)
    .startOf('isoWeek')
    .hours(12)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .toDate();
  const endOfThisWeek = moment(now)
    .endOf('isoWeek')
    .hours(12)
    .minutes(0)
    .seconds(0)
    .milliseconds(0)
    .toDate();
  const startOfNextWeek = moment(startOfThisWeek).add(7, 'd').toDate();
  const endOfNextWeek = moment(endOfThisWeek).add(7, 'd').toDate();

  const { dispatch, boardFilters } = useBoardContext();
  const { endDate } = boardFilters;

  // ToDo: Try to get rid of this internal state by handling it all in the store
  const [range, setRange] = useState<DateRange>({
    startDate: today,
    endDate: oneWeekFromToday,
    key: 'selection',
  });

  const handleSave = (dateRange: DateRange) => {
    dispatch(setDateRange(formatRangeForStore(dateRange)));
    onClose();
  };

  const handleReset = () => {
    dispatch(resetDateRange());
    setRange(
      formatRangeForPicker({
        startDate: initialBoardState.boardFilters.startDate,
        endDate: initialBoardState.boardFilters.endDate,
      })
    );
  };

  useEffect(() => {
    // reset range if the clear icon is clicked
    if (clearedDateRange) {
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearedDateRange]);

  const disableWeekends = (date: Date) => {
    return date === null || date.getDay() === 0 || date.getDay() === 6;
  };
  return (
    <Dialog
      className={`${dateRangeDialog} ${className}`}
      open={dateRangeDialogOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogContent className={dialogContent}>
        <DateRangePicker
          className={dateRange}
          editableDateInputs={true}
          onChange={({ selection }) => {
            setRange({
              startDate: selection.startDate,
              endDate: selection.endDate,
              key: 'selection',
            });
          }}
          moveRangeOnFirstSelection={false}
          months={1}
          disabledDay={disableWeekends}
          ranges={[range]}
          direction="horizontal"
          rangeColors={['#6559FF']}
          dateDisplayFormat="dd.MM.yyyy"
          weekStartsOn={1}
          showPreview={true}
          showDateDisplay={false}
          showMonthAndYearPickers={false}
          showSelectionPreview={true}
          // for those who follow: we don't want to replicate our real timeranges unless we fix it for local timezones, so we just need day representation here
          staticRanges={[
            {
              label: 'Today',
              range: () => ({
                startDate: today,
                endDate: today,
              }),
              isSelected: () => false,
            },
            {
              label: 'Tomorrow',
              range: () => ({
                startDate: tomorrow,
                endDate: tomorrow,
              }),
              isSelected: () => false,
            },
            {
              label: 'This Week',
              range: () => ({
                startDate: startOfThisWeek,
                endDate: endOfThisWeek,
              }),
              isSelected: () => false,
            },
            {
              label: 'Next Week',
              range: () => ({
                startDate: startOfNextWeek,
                endDate: endOfNextWeek,
              }),
              isSelected: () => false,
            },
          ]}
        />
      </DialogContent>
      <DialogActions className={buttonRow}>
        {endDate !== initialBoardState.boardFilters.endDate && (
          <Button key="resetButton" onClick={handleReset} variant="outlined">
            Reset
          </Button>
        )}
        <Button key="cancelButton" onClick={onClose} variant="outlined">
          Cancel
        </Button>
        <Button
          key="saveButton"
          variant="contained"
          color="primary"
          onClick={() => handleSave(range)}
        >
          Set
        </Button>
      </DialogActions>
    </Dialog>
  );
};
