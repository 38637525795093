import _ from 'lodash';
import { initialBoardState, useBoardContext } from 'store/BoardProvider';

import { OfficeTypeAll } from 'constants/officeType';
import {
  filterByCategories,
  filterByLocation,
  filterByOfficeType,
  searchGalleryEvent,
} from 'utils/galleryEventUtils';

export const useFilteredEvents = (galleryEvents: GalleryEvent[]) => {
  const { searchTerm, boardFilters } = useBoardContext();
  const { location, officeType, categories } = boardFilters;

  let filteredEvents = galleryEvents;

  if (searchTerm) {
    filteredEvents = searchGalleryEvent(filteredEvents, searchTerm);
  }

  if (categories.length) {
    filteredEvents = filterByCategories(filteredEvents, categories);
  }

  if (location.length !== initialBoardState.boardFilters.location.length) {
    filteredEvents = filterByLocation(filteredEvents, location);
  }

  if (officeType !== OfficeTypeAll.All) {
    filteredEvents = filterByOfficeType(filteredEvents, officeType);
  }

  filteredEvents = _.orderBy(
    filteredEvents,
    ['startTime', '_id'],
    ['asc', 'desc']
  );

  return filteredEvents;
};
