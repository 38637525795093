import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  uploadBox: {
    height: '44px',
    boxShadow: `inset 0 0 0 1px ${theme.palette.common.light1}`,
    borderRadius: theme.spacing(1),
    boxSizing: 'border-box',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    color: theme.palette.text.disabled,
    position: 'relative',
    overflow: 'hidden',
    cursor: 'pointer',
    '&:hover': {
      boxShadow: `inset 0 0 0 2px ${theme.palette.common.light1}`,
    },
  },
  uploadedBox: {
    height: theme.spacing(10),
    boxShadow: `inset 0 0 0 1px ${theme.palette.common.light1}`,
    borderRadius: theme.spacing(1),
    boxSizing: 'border-box',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    color: theme.palette.text.disabled,
    position: 'relative',
    overflow: 'hidden',
  },
  uploadIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  uploadBoxInput: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingLeft: '12px',
  },
  uploadBoxLabel: {
    display: 'flex',
    alignItems: 'center',
    ...theme.typography.body1,
  },
  uploadBoxFile: {
    width: '0.1px',
    height: '0.1px',
    opacity: '0',
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  uploadBoxButton: {
    display: 'none',
  },
  uploadImage: {
    maxWidth: '100%',
    top: '50%',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  uploadBoxDrag: {
    borderStyle: 'dashed',
  },
  imageRemoveButton: {
    left: theme.spacing(1),
    top: theme.spacing(1),
    position: 'absolute',
    textAlign: 'left',
    color: theme.palette.common.white,
    padding: theme.spacing(0.5),
  },
  imageRemoveButtonIcon: {
    color: theme.palette.background.paper,
  },
}));
