import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: '64px',
    paddingBottom: '19px',
  },
  headline: {
    whiteSpace: 'pre',
    fontSize: '2.5rem',
    fontWeight: 500,
    lineHeight: '2.8rem',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '20px',
    '& button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorPrimary': {
      marginTop: '50px',
    },
  },
  nav: {
    display: 'flex',
    marginTop: '60px',
  },
  navLink: {
    fontWeight: 700,
    fontSize: '1rem',
    minWidth: 200,
    textDecoration: 'none',
    color: theme.palette.text.primary,
    borderBottom: `1px solid ${theme.palette.text.hint}`,
    marginRight: 35,
    paddingBottom: '13px',
  },
  navLinkActive: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  search: {
    width: '100%',
    height: '48px',
    borderColor: theme.palette.primary.main,
  },
  searchInput: {
    borderRadius: '24px',
    '& .MuiInputAdornment-positionStart': {
      padding: '22px 10px',
      marginLeft: '-12px',
    },

    '&.Mui-focused': {
      '& .MuiInputAdornment-positionStart': {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,
        height: '100%',
        borderTopLeftRadius: '50%',
        borderBottomLeftRadius: '50%',
      },
    },

    '& .MuiOutlinedInput-notchedOutline:active': {
      borderColor: theme.palette.primary.main,
    },
  },
  newButton: {
    display: 'flex',
    border: `1px dashed ${theme.palette.primary.main}`,
    justifyContent: 'flex-end',
    color: theme.palette.primary.main,
    paddingTop: '10px',
    paddingBottom: '10px',
    float: 'right',
  },
  newButtonLink: {
    display: 'flex',
    justifyContent: 'center',
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  newButtonIcon: {
    marginRight: 6,
  },
}));
