import { MenuItem, withStyles } from '@material-ui/core';

export const GalleryFormSelectOption = withStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    fontSize: '0.875rem',
    '&:hover': {
      background: theme.palette.text.hint,
      color: theme.palette.text.primary,
    },
    '&:active': {
      background: theme.palette.text.hint,
      color: theme.palette.text.primary,
    },
  },
}))(MenuItem);
