import React, { FC, useState } from 'react';
import { PlusCircle } from 'react-feather';
import { useHistory } from 'react-router-dom';
import { ListItem, ListItemText, Typography } from '@material-ui/core';
import LessIcon from '@material-ui/icons/ExpandLess';
import MoreIcon from '@material-ui/icons/ExpandMore';
import moment from 'moment';

import { EventListItem } from './EventListItem/EventListItem';
import { useUpcomingEventsStyles } from './InitiativeUpcomingEvents.style';

type InitiativeUpcomingEventsProps = {
  initiative: Initiative;
};

const EVENTS_SHOWN = 3;

export const InitiativeUpcomingEvents: FC<InitiativeUpcomingEventsProps> = ({
  initiative,
}) => {
  const classes = useUpcomingEventsStyles();

  const [showAll, setShowAll] = useState(false);
  const events = initiative.events || [];
  const sortedEvents = events
    .filter((event) => moment(event.endTime).isAfter())
    .sort((a, b) => moment(a.startTime).unix() - moment(b.startTime).unix());

  const history = useHistory();

  const goToEvent = (event: GalleryEvent) => {
    history.push(`/events/grid/${event._id}?fromInitiative=${initiative._id}`);
  };

  const createNewEvent = () => {
    history.push(`/events/grid/new`, { fromInitiative: initiative._id });
  };

  if (events.length === 0)
    return (
      <div onClick={createNewEvent} className={classes.addEventButton}>
        <PlusCircle className={classes.addEventIcon} />
        <p className={classes.addEventText}>
          Add new event for this initiative
        </p>
      </div>
    );

  return (
    <div>
      <div className={classes.titleWrapper}>
        <Typography className={classes.upcomingEventsTitle} variant="h6">
          {`Scheduled events for ${initiative.title.toLowerCase()} (${
            sortedEvents.length
          })`}
        </Typography>
      </div>

      {sortedEvents
        .filter((event, index) => showAll || index < EVENTS_SHOWN)
        .map((event) => (
          <div key={event._id}>
            <EventListItem event={event} onGoTo={() => goToEvent(event)} />
          </div>
        ))}
      {sortedEvents.length > EVENTS_SHOWN && (
        <ListItem
          dense={true}
          onClick={() => setShowAll(!showAll)}
          key={'showAll' + showAll}
        >
          <ListItemText />

          {showAll ? 'show less' : 'show more'}
          <div></div>
          {showAll ? <LessIcon /> : <MoreIcon />}
        </ListItem>
      )}
      <div onClick={createNewEvent} className={classes.addEventButton}>
        <PlusCircle className={classes.addEventIcon} />
        <p className={classes.addEventText}>
          Add new event for this initiative
        </p>
      </div>
    </div>
  );
};
