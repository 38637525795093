/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Feather } from 'react-feather';
import { AppBar, Button, IconButton, Link, Toolbar } from '@material-ui/core';
import { useMsal } from 'store/MsalProvider';

import { useHeaderStyles } from 'components/Header/Header.style';
import { getSession, logout } from 'services/AuthenticationService';

import logoImg from './header.svg';

export const Header = () => {
  const {
    logo,
    accountCircle,
    logoutButton,
    header,
    toolbar,
    feedbackButton,
    userAvatar,
  } = useHeaderStyles();

  const session = getSession();

  const { signOut } = useMsal();

  const onClickLogout = () => {
    signOut();
    logout();
  };

  return (
    <AppBar className={header} position="static">
      <Toolbar className={toolbar}>
        <img src={logoImg} alt="Synchronicity Board" className={logo} />
        {session && (
          <>
            {session.avatars?.image_48 && (
              <IconButton edge="end" color="inherit">
                <img
                  src={session.avatars.image_48}
                  className={accountCircle}
                  alt={session.slackName}
                />
              </IconButton>
            )}
          </>
        )}
        <div className="toolbarButtons">
          <Link
            className={feedbackButton}
            data-cy="feedback-button"
            onClick={() => {
              window.open(
                'https://forms.office.com/Pages/ResponsePage.aspx?id=iAIR1B6_N0OXq6XFE3Hc6oJQvrhHDS9Ht26NV8lcavdURVo3R0pLMFlZR1pVSEkyRE5IUzNXSkpHMC4u'
              );
            }}
          >
            <Feather />
            Feedback
          </Link>
          <Button
            id="logout-button"
            className={logoutButton}
            data-cy="logout-button"
            onClick={onClickLogout}
            color="inherit"
          >
            <div className={userAvatar}>
              {session !== undefined && session.outlookName !== undefined
                ? session.outlookName.charAt(0)
                : 'N'}
            </div>
            Logout
          </Button>
        </div>
      </Toolbar>
    </AppBar>
  );
};
