import _ from 'lodash';
import { BoardState } from 'store/BoardProvider';

export enum DataInitiativeActions {
  SET_INITIATIVES = 'SET_INITIATIVES',
  SET_INITIATIVE = 'SET_INITIATIVE',
  UPDATE_INITIATIVE = 'UPDATE_INITIATIVE',
  CREATE_INITIATIVE = 'CREATE_INITIATIVE',
  DELETE_INITIATIVE = 'DELETE_INITIATIVE',
}

export const initiativeReducer = (state: BoardState, { type, payload }) => {
  switch (type) {
    case DataInitiativeActions.SET_INITIATIVES:
      return { ...state, initiatives: _.keyBy(payload || [], '_id') };

    case DataInitiativeActions.SET_INITIATIVE:
    case DataInitiativeActions.UPDATE_INITIATIVE:
    case DataInitiativeActions.CREATE_INITIATIVE:
      return {
        ...state,
        initiatives: {
          ...state.initiatives,
          [payload._id]: payload,
        },
      };

    case DataInitiativeActions.DELETE_INITIATIVE:
      const next = {
        ...state,
        initiatives: {
          ...state.initiatives,
        },
      };
      delete next.initiatives[payload];
      return next;
    default:
      return state;
  }
};

export const setAllInitiatives = (initiatives: Initiative[]) => {
  return {
    type: DataInitiativeActions.SET_INITIATIVES,
    payload: initiatives,
  };
};

export const setInitiative = (initiative: Initiative) => {
  return {
    type: DataInitiativeActions.SET_INITIATIVE,
    payload: initiative,
  };
};

export const createInitiative = (initiative: Initiative) => {
  return {
    type: DataInitiativeActions.CREATE_INITIATIVE,
    payload: initiative,
  };
};

export const updateInitiative = (initiative: Initiative) => {
  return {
    type: DataInitiativeActions.UPDATE_INITIATIVE,
    payload: initiative,
  };
};

export const deleteInitiative = (initiativeId: Initiative['_id']) => {
  return {
    type: DataInitiativeActions.DELETE_INITIATIVE,
    payload: initiativeId,
  };
};
