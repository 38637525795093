import React, { FC, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useBoardContext } from 'store/BoardProvider';
import { clearAlerts } from 'store/reducers/alerts';

import { GalleryTab } from 'components/BoardTabs';
import { AlertType } from 'types/AlertType';

type BoardAlertProps = {};

export const BoardAlert: FC<BoardAlertProps> = () => {
  const { dispatch, alertMessage } = useBoardContext();
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(AlertType.success);
  const [message, setMessage] = useState('');
  const [eventId, setEventId] = useState('');

  useMemo(() => {
    alertMessage.eventId && setEventId(alertMessage.eventId);
    alertMessage.message && setMessage(alertMessage.message);
    alertMessage.type && setType(alertMessage.type);
    alertMessage.open && setOpen(alertMessage.open);
  }, [alertMessage]);

  function handleClose(_event?: React.SyntheticEvent, reason?: string) {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    dispatch(clearAlerts());
  }

  const LinkingAlertBox = () => {
    return (
      <Alert severity="success">
        <Link to={`/${GalleryTab.Events}/${eventId}`}>Event</Link> {message}
      </Alert>
    );
  };

  const AlertBox = () => {
    return <Alert severity={type}>{message.toString()}</Alert>;
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      {eventId ? LinkingAlertBox() : AlertBox()}
    </Snackbar>
  );
};
