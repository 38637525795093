import { makeStyles } from '@material-ui/core/styles';

export const useInitiativeEditButtonStyles = makeStyles((theme) => {
  return {
    editIcon: {
      width: '1rem',
      marginRight: theme.spacing(0.75),
    },
    editText: {
      textTransform: 'none',
    },
    editButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: theme.spacing(2),
      '&:hover > *': {
        color: theme.palette.primary.dark,
      },
      '& > *': {
        color: theme.palette.primary.main,
      },
    },
  };
});
