import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  cardHeaderDateContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    textAlign: 'center',
  },
  dateLight: {
    fontSize: 'medium',
    fontWeight: 'lighter',
  },
  dateBold: {
    fontWeight: 'bold',
    fontSize: '1.5rem',
  },
}));
