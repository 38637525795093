import React, { FC } from 'react';
import { useErrorableAction } from 'hooks/useErrorableAction';
import { createInitiative as createAction } from 'store/reducers/initiatives';

import { InitiativeForm } from 'components/InitiativeForm';
import { createInitiative } from 'services/InitiativeService';
import { trackEvent } from 'utils/analyticsUtils';

type InitiativeFormAddContainerProps = {
  onCancel: () => void;
};

const isProduction = process.env.REACT_APP_ENV === 'production';

export const InitiativeFormAddContainer: FC<InitiativeFormAddContainerProps> = ({
  onCancel,
}) => {
  const { ErrorComponent, submit } = useErrorableAction(
    createInitiative,
    createAction
  );

  const onSubmit = async (values: InitiativeFormData, { setSubmitting }) => {
    const initiative = {
      ...values,
      office: isProduction ? values.office : 'Test Office',
    };
    const { success } = await submit(initiative);
    setSubmitting(false);
    if (success) {
      onCancel();
    }

    trackEvent('Initiative Created');
  };

  return (
    <>
      <InitiativeForm
        title="New Initiative"
        onClickSubmit={onSubmit}
        onClickCancel={onCancel}
      />
      <ErrorComponent />
    </>
  );
};
