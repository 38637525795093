import { makeStyles } from '@material-ui/core/styles';

export const useEventListItemStyles = makeStyles((theme) => {
  return {
    eventListItem: {
      cursor: 'pointer',
      width: '100%',
      height: '80px',
      backgroundImage: `url(/boids.png)`,
      backgroundSize: 'cover',
      marginBottom: theme.spacing(1),
      borderRadius: '8px',
    },
    overlay: {
      background: 'rgba(44, 63, 107, .72)',
      width: '100%',
      height: '100%',
      borderRadius: '8px',
    },
    eventContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      padding: `0 ${theme.spacing(3)}px`,
    },
    metadataWrapper: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    eventWeekMonth: {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 400,
      color: theme.palette.common.light3,
    },
    eventDate: {
      fontSize: '24px',
      lineHeight: '24px',
      fontWeight: 700,
      color: theme.palette.common.light3,
    },
    eventTitle: {
      color: theme.palette.common.light3,
    },
  };
});
