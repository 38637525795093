import { OfficeType } from './officeType';

export const officeDictionary = {
  'All Offices': {
    type: OfficeType.OnSite,
    prod: true,
    outlookId: [
      '49b22890-4341-4584-b5fc-221da835b174',
      'ea29ead6-789f-47aa-ab76-2ba25b01b17c',
      'ee4c5d35-3df4-44de-b47a-29a39346866e',
      '7f2d8c9f-d9d1-4650-9008-14cee66852ed',
      '9513f636-5d5a-46c6-a12f-5ce89619a9ce',
      'e92cb6b0-f873-4b0a-a8d5-0fecb0cb039a',
      '3b6713e0-276d-4ec8-b9ee-faabc6dadc35',
      '4ed98cab-ba17-4763-b58b-bff799b14430',
      '94bf0405-d804-48dc-a173-d05089bebc04',
      'd5c077d8-5e91-4b02-8c55-2a09aff5b04f',
    ],
  },
  Berlin: {
    type: OfficeType.OnSite,
    prod: true,
    outlookId: '49b22890-4341-4584-b5fc-221da835b174',
  },
  Copenhagen: {
    type: OfficeType.OnSite,
    prod: true,
    outlookId: 'ea29ead6-789f-47aa-ab76-2ba25b01b17c',
  },
  Frankfurt: { type: OfficeType.OnSite, prod: true, outlookId: null },
  Hamburg: {
    type: OfficeType.OnSite,
    prod: true,
    outlookId: 'ee4c5d35-3df4-44de-b47a-29a39346866e',
  },
  Helsinki: {
    type: OfficeType.OnSite,
    prod: true,
    outlookId: '7f2d8c9f-d9d1-4650-9008-14cee66852ed',
  },
  Munich: {
    type: OfficeType.OnSite,
    prod: true,
    outlookId: '9513f636-5d5a-46c6-a12f-5ce89619a9ce',
  },
  Oslo: {
    type: OfficeType.OnSite,
    prod: true,
    outlookId: 'e92cb6b0-f873-4b0a-a8d5-0fecb0cb039a',
  },
  Stockholm: {
    type: OfficeType.OnSite,
    prod: true,
    outlookId: '3b6713e0-276d-4ec8-b9ee-faabc6dadc35',
  },
  Zurich: {
    type: OfficeType.OnSite,
    prod: true,
    outlookId: '4ed98cab-ba17-4763-b58b-bff799b14430',
  },
  Wormhole: {
    type: OfficeType.Remote,
    prod: true,
    outlookId: '94bf0405-d804-48dc-a173-d05089bebc04',
  },
  Backdoor: { type: OfficeType.Remote, prod: true, outlookId: null },
  'Test Office': {
    type: OfficeType.OnSite,
    prod: false,
    outlookId: 'd5c077d8-5e91-4b02-8c55-2a09aff5b04f',
  },
  'Netlight Calendar': {
    type: OfficeType.OnSite,
    prod: true,
    outlookId: 'fc1e95c3-2a9a-4328-8f8c-767cdf7aa9c6',
  },
};

export type OfficeName = keyof typeof officeDictionary;

const isProduction = process.env.REACT_APP_ENV === 'production';

export const offices: OfficeName[] = Object.entries(officeDictionary)
  .filter(([, office]) => office.type === OfficeType.OnSite)
  .filter(([, office]) => !isProduction || office.prod === isProduction)
  .filter(([name]) => name !== 'All Offices')
  .map(([name]) => name as OfficeName);

export const remoteOffices: OfficeName[] = Object.entries(officeDictionary)
  .filter(([, office]) => office.type === OfficeType.Remote)
  .filter(([, office]) => !isProduction || office.prod === isProduction)
  .filter(([name]) => name !== 'All Offices')
  .map(([name]) => name as OfficeName);

export const allOffices: OfficeName[] = Object.entries(officeDictionary)
  .filter(([, office]) => office.type === OfficeType.OnSite)
  .filter(([, office]) => !isProduction || office.prod === isProduction)
  .map(([name]) => name as OfficeName);
