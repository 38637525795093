import React, { useState } from 'react';
import { useBoardContext } from 'store/BoardProvider';

import { ErrorBox } from 'components/UI/ErrorBox';

//tsx wouldn't get the generic type for <T>; any doesn't harm here
//eslint-disable-next-line
export const useErrorableAction = <T extends any, A extends any[]>(
  apiAction: (...args: A) => Promise<T>,
  stateAction: (T) => Action
) => {
  const [submitError, setError] = useState<Error | null>(null);
  const { dispatch } = useBoardContext();

  const ErrorComponent = () =>
    submitError && <ErrorBox>Error: {submitError.message}</ErrorBox>;

  const submit = async (...args: A) => {
    try {
      setError(null);
      const newEntity = await apiAction(...args);
      dispatch(stateAction(newEntity));
      return { success: true, entity: newEntity };
    } catch (error) {
      setError(error);
      return { success: false };
    }
  };

  return {
    ErrorComponent,
    error: submitError,
    submit,
  };
};
