import { useCallback, useState } from 'react';
import { useBoardContext } from 'store/BoardProvider';
import { showErrorAlert } from 'store/reducers/alerts';
import { setEvent } from 'store/reducers/events';
import { setInitiative } from 'store/reducers/initiatives';

import { getGalleryEvent } from 'services/GalleryEventService';
import { getInitiative } from 'services/InitiativeService';

export function useFetch<T>(
  id: string | undefined,
  get: (string) => Promise<T>,
  set: (T) => { type: string; payload: T }
) {
  const { dispatch } = useBoardContext();
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState(false);

  const load = useCallback(
    async function () {
      setLoading(true);
      try {
        setError(null);
        const result = await get(id);
        result && dispatch(set(result));
      } catch (fetchingError) {
        setError(fetchingError);
        dispatch(showErrorAlert(fetchingError));
      } finally {
        setLoading(false);
      }
    },
    [setLoading, setError, dispatch, set, get, id]
  );

  return { error, loading, load };
}

export function useInitiative(id: Initiative['_id'] | undefined) {
  const { initiatives } = useBoardContext();
  const { error, loading, load } = useFetch<Initiative>(
    id,
    getInitiative,
    setInitiative
  );

  return {
    initiative: id ? initiatives[id] : undefined,
    error,
    loading,
    load,
  };
}
export function useEvent(id: GalleryEvent['_id'] | undefined) {
  const { galleryEvents, galleryDetailedEvents } = useBoardContext();
  const { error, loading, load } = useFetch<GalleryEventDetails>(
    id,
    getGalleryEvent,
    setEvent
  );
  const event = id
    ? galleryDetailedEvents[id] ?? galleryEvents[id] ?? undefined
    : undefined;

  return {
    event,
    error,
    loading,
    load,
  };
}
