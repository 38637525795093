import { useEffect } from 'react';
import { usePageVisibility } from 'hooks';

import { trackEvent } from 'utils/analyticsUtils';

const INTERVAL_TIME = 1000 * 60 * 10;

export const useRefreshWhenVisible = (load) => {
  const visibility = usePageVisibility();
  useEffect(() => {
    let interval;
    if (visibility) {
      load();
      interval = setInterval(() => {
        load();
        trackEvent('Data Interval Refetch');
      }, INTERVAL_TIME);
    } else {
      clearInterval(interval);
    }
    return () => {
      clearInterval(interval);
    };
  }, [load, visibility]);
};
