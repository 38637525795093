import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  buttonContainer: { display: 'flex', justifyContent: 'center' },
  loadingCircle: {
    color: 'black',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    height: '42px',
  },
}));
