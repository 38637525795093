import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { useMsal } from 'store/MsalProvider';

import LoginButton from './LoginButton';
import { useLoginStyles } from './LoginStyle';

export const Login: FC = () => {
  const {
    loginScreen,
    loginContainer,
    loginRow,
    loginFormContainer,
    logo,
    loginHeading,
    loginSubHeading,
  } = useLoginStyles();
  const { signIn } = useMsal();

  return (
    <div className={loginScreen}>
      <div className={loginContainer}>
        <Grid className={loginFormContainer} container spacing={0}>
          <Grid className={loginRow} item xs={12}>
            <img className={logo} src="/logo.png" alt="Netlight" />
          </Grid>
          <Grid className={loginRow} item xs={12}>
            <div className={loginHeading}>Synchronicity Board</div>
          </Grid>
          <Grid className={loginRow} item xs={12}>
            <div className={loginSubHeading}>
              Discover events and initiatives all across the Netlight universe.{' '}
            </div>
          </Grid>
          <Grid className={loginRow} item xs={12}>
            <LoginButton onClick={() => signIn()} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
