import { makeStyles } from '@material-ui/core/styles';

export const useSimpleToggleStyles = makeStyles((theme) => ({
  simpleToggleContainer: {
    display: 'flex',
    height: '44px',
  },
  simpleToggleElement: {
    border: '1px solid ' + theme.palette.text.disabled,
    color: theme.palette.text.secondary,
    marginRight: '-1px',
    textAlign: 'center',
    lineHeight: '2.75rem',
    fontSize: '1rem',
    cursor: 'pointer',
    width: 80,
    '&:first-child': {
      borderRadius: '8px 0 0 8px',
    },
    '&:last-child': {
      borderRadius: '0 8px 8px 0',
    },
  },
  activeToggleElement: {
    border: '1px solid ' + theme.palette.primary.main,
    background: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    zIndex: 1,
  },
  inactiveToggleElement: {
    '&:hover': {
      color: theme.palette.primary.main,
      borderColor: theme.palette.primary.main,
      zIndex: 2,
    },
  },
}));
