import { makeStyles } from '@material-ui/core/styles';

export const useGalleryFormStyle = makeStyles((theme) => ({
  form: {
    margin: `${theme.spacing(2)}px`,
    padding: `${theme.spacing(3)}px`,
    borderRadius: '6px',
    backgroundColor: theme.palette.common.light3,
  },
  formTitle: {
    margin: '0 0 20px 0',
    fontSize: theme.typography.h6.fontSize,
    lineHeight: theme.typography.h6.lineHeight,
    fontWeight: 400,
    color: theme.palette.primary.baseText,
  },
  buttonRow: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
  },
  cancelButton: {
    marginRight: theme.spacing(2),
  },
  autocompleteField: {
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input': {
      padding: `0 0 0 ${theme.spacing(2)}px`,
    },
    '& .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
      padding: `0px ${theme.spacing(2)}px`,
      '& .MuiInputBase-input:not(.MuiOutlinedInput-inputAdornedStart)': {
        padding: '0px',
      },
    },
  },
  switchInput: {
    marginBottom: theme.spacing(2),
  },
  selectOption: {
    padding: theme.spacing(1),
    fontSize: '0.875rem',
    '&:hover': {
      background: theme.palette.text.hint,
      color: theme.palette.text.primary,
    },
    '&:active': {
      background: theme.palette.text.hint,
      color: theme.palette.text.primary,
    },
  },

  attendeeContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },

  attendeeDropDownName: {
    paddingLeft: theme.spacing(0.75),
    paddingRight: '13px',

    ...theme.typography.body1,
    alignItems: 'center',

    /* Base / Gray+1 */
    color: theme.palette.primary.baseText,
  },

  attendeeDropDownEmail: {
    paddingLeft: theme.spacing(0.75),
    paddingRight: '13px',
    color: theme.palette.text.secondary,
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '0.75rem',
    lineHeight: '0.75rem',
    alignItems: 'center',
    letterSpacing: '0.1px',
  },

  absoluteInputStyle: {
    position: 'absolute',
    top: '50%',
    left: '16px',
  },

  autocompleteInput: {
    '&&& .MuiAutocomplete-inputRoot': {
      padding: '6px 6px 6px 36px',
      minHeight: '44px',
    },
    '&&& .MuiInputBase-input': {
      maxHeight: '30px', //height of chip (24) + margin (6)
    },
  },
  dropdownStyle: {
    borderRadius: '8px',
    '& .MuiAutocomplete-listbox': {
      '& .MuiAutocomplete-option[data-focus="true"]': {
        background: theme.palette.primary.light3,
        color: theme.palette.primary.main,
        '& $attendeeDropDownName': {
          color: theme.palette.primary.main,
          fontWeight: 500,
        },
        '& $attendeeDropDownEmail': {
          color: theme.palette.primary.main,
        },
      },
      '& .MuiAutocomplete-option[aria-selected="true"]': {
        background: theme.palette.primary.light3,
        color: theme.palette.primary.main,
        '& $attendeeContainer': {
          paddingLeft: theme.spacing(0.5),
        },
        '& $attendeeDropDownName': {
          color: theme.palette.primary.main,
          fontWeight: 500,
        },
        '& $attendeeDropDownEmail': {
          color: theme.palette.primary.main,
        },
      },
    },
    '& .MuiAutocomplete-listbox .MuiAutocomplete-option span.MuiCheckbox-colorSecondary.Mui-checked.MuiIconButton-colorSecondary': {
      background: theme.palette.primary.light3,
      color: theme.palette.primary.main,
    },
    '& .MuiAutocomplete-listbox .MuiAutocomplete-option span.MuiCheckbox-root': {
      color: theme.palette.primary.main,
    },
  },
}));
