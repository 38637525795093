import { makeStyles } from '@material-ui/core/styles';

export const useUpcomingEventsStyles = makeStyles((theme) => {
  return {
    upcomingEventsTitle: {
      ...theme.typography.body2,
      color: theme.palette.common.main,
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    addEventIcon: {
      width: '20px',
      marginRight: theme.spacing(0.75),
    },
    addEventText: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    addEventButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginRight: theme.spacing(2),
      '&:hover > *': {
        color: theme.palette.primary.dark,
      },
      '& > *': {
        color: theme.palette.primary.main,
      },
    },
  };
});
