export const getInputDelta = (oldValue, newValue) => {
  let delta = '';
  let divergenceAt;
  for (let i = 0; i < Math.min(oldValue.length, newValue.length); i++) {
    if (oldValue[i] !== newValue[i]) {
      divergenceAt = i;
      break;
    }
  }

  if (divergenceAt === undefined) {
    if (Math.min(oldValue.length, newValue.length) === oldValue.length)
      delta = newValue.replace(oldValue, '');
    else delta = '';
  } else {
    delta = newValue.replace(oldValue.substr(0, divergenceAt), '');
    let secondHalf = oldValue.substr(divergenceAt);
    const secondHalfLength = secondHalf.length;

    for (let i = 0; i < secondHalfLength; i++) {
      if (!delta.endsWith(secondHalf)) {
        secondHalf = secondHalf.substr(1);
      } else {
        delta = delta.replace(secondHalf, '');
        break;
      }
    }
  }
  return delta;
};
