import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Board } from 'components/Board';
import { GalleryTab } from 'components/BoardTabs';
import { GalleryDialog } from 'components/GalleryDialog';

import { GalleryEventBoard } from './GalleryEventBoard';
import { ViewType } from './GalleryEventBoard';

export const EventsPage: FC = () => {
  const { id, viewType } = useParams<{ id?: string; viewType: ViewType }>();
  const history = useHistory();

  const EVENTS_PATH = `/${GalleryTab.Events}/${viewType}`;

  const clearId = () => history.push(EVENTS_PATH);

  return (
    <Board>
      <GalleryEventBoard viewType={viewType} />
      <GalleryDialog
        open={id !== undefined}
        id={id}
        type={GalleryTab.Events}
        onClose={clearId}
      />
    </Board>
  );
};
