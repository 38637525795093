import React, { FC, useState } from 'react';
import { Clock, MapPin, Users, Video } from 'react-feather';
import { Link as LinkIcon } from 'react-feather';
import { CardContent, Link, Snackbar, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import moment from 'moment';

import { getOwnerNameFromUser } from 'utils/userUtils';

import { useStyles } from './EventGridCardBody.style';

export const EventGridCardBody: FC<{ galleryEvent: GalleryEvent }> = ({
  galleryEvent,
}) => {
  const classes = useStyles();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const formatDate = (date) => moment(date).format('HH:mm');
  const timeField = `${formatDate(galleryEvent.startTime)} - ${formatDate(
    galleryEvent.endTime
  )}`;

  const onCopyClick = () => {
    navigator.clipboard.writeText(
      `${window.location.href}/${galleryEvent._id}`
    );
    setSnackBarOpen(true);
  };
  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  return (
    <CardContent className={classes.cardContent}>
      <div className={classes.eventDetail}>
        <div className={classes.descriptionRow}>
          <Users className={classes.icon} />
          <Typography variant="body2">
            {getOwnerNameFromUser(galleryEvent.owner)}
          </Typography>
        </div>
        <div className={classes.descriptionRow}>
          <MapPin className={classes.icon} />
          <Typography variant="body2">{galleryEvent.office}</Typography>
        </div>
        <div className={classes.descriptionRow}>
          <Clock className={classes.icon} />
          <Typography variant="body2">{timeField}</Typography>
        </div>
      </div>
      <div className={classes.buttonContainer}>
        <div onClick={(e) => e.stopPropagation()}>
          <Link onClick={onCopyClick} className={classes.descriptionRow}>
            <LinkIcon className={classes.icon} />
            <Typography className={classes.link} variant="body2">
              Copy Link
            </Typography>
          </Link>
          <Snackbar
            open={snackBarOpen}
            onClose={handleClose}
            autoHideDuration={3000}
          >
            <Alert severity="success">
              Event&apos;s link copied to the clipboard
            </Alert>
          </Snackbar>
        </div>
        {galleryEvent.remotelyAccessible && (
          <Link
            target="_blank"
            rel="noopener noreferrer"
            href={galleryEvent.remoteLink}
            className={classes.descriptionRow}
          >
            <Video className={classes.icon} />
            <Typography className={classes.link} variant="body2">
              Join Remotely!
            </Typography>
          </Link>
        )}
      </div>
    </CardContent>
  );
};
