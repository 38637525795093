const SESSION_NAME = '__session';

export const tokenStorage = {
  setItem(key: string, value: string) {
    localStorage.setItem(key, value);
  },
  getItem(key: string): string | null {
    return localStorage.getItem(key);
  },
  setAuthenticationToken(value: string) {
    this.setItem(SESSION_NAME, value);
  },
  getAuthenticationToken(): string | null {
    return this.getItem(SESSION_NAME);
  },
  removeAuthenticationToken() {
    localStorage.removeItem(SESSION_NAME);
  },
};
