/**
 * Add a URL parameter
 * @param {search} string  this is typically document.location.search
 * @param {key}    string  the key to set
 * @param {val}    string  value
 */
export function addUrlSearchParam(search: string, key: string, val: string) {
  // Check if param already exists, and remove it
  if (search.includes(key)) {
    const re = new RegExp(`[&]*${key}=[^&]*`);
    search = search.replace(re, '');
  }

  // If the value is empty, return the search without the param
  if (val === '') {
    return search;
  }

  const newParam = key + '=' + val;

  let result = '';
  result += search;
  if (search) {
    if (search === '?') {
      // Then we shouldnt add a '&'
      result += newParam;
    } else {
      // Then it contains something already so just add the new param
      result += '&' + newParam;
    }
  } else {
    // Then the search is empty and we just need to append to new param
    result += '?' + newParam;
  }

  return result;
}

/**
 * Makes ONLY the first letter capitalized (the rest are lowercase)
 */
export function capitalizeFirstLetter(string) {
  string = string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function encodeURL(params: object) {
  const paramKeys = Object.keys(params);
  if (paramKeys.length === 0) {
    return '';
  }
  const encodedURL = paramKeys
    .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
    .join('&');
  return `?${encodedURL}`;
}
