import React, { FC } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
// import { useHistory, useLocation } from 'react-router';
import { Tab, Tabs } from '@material-ui/core';

import { trackTabChange } from 'utils/analyticsUtils';

import { useBoardTabsStyles } from './BoardTabsStyles';

export enum GalleryTab {
  Events = 'events',
  Initiatives = 'initiatives',
}

type BoardTabsProps = {
  activeTab: GalleryTab;
};

export const BoardTabs: FC<BoardTabsProps> = (props) => {
  const { boardTabContainer, boardTabList, boardTab } = useBoardTabsStyles();

  const history = useHistory();
  const location = useLocation();

  const goToTab = (tab: GalleryTab) => {
    const search = location.search;
    if (search) {
      history.push(`/${tab}${search}`);
    } else {
      history.push(`/${tab}`);
    }
  };

  const handleTabChange = (
    event: React.ChangeEvent<{}>,
    newValue: GalleryTab
  ) => {
    goToTab(newValue);
    trackTabChange(newValue);
  };

  return (
    <>
      <div data-cy="tab-container" className={boardTabContainer}>
        <Tabs
          className={boardTabList}
          value={props.activeTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange}
        >
          <Tab
            className={boardTab}
            label="Events"
            disableRipple
            value={GalleryTab.Events}
          />
          <Tab
            className={boardTab}
            label="Initiatives"
            disableRipple
            value={GalleryTab.Initiatives}
          />
        </Tabs>
      </div>
    </>
  );
};
