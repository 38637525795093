import { Category } from 'components/Category/categories';
import { OfficeName, offices, remoteOffices } from 'constants/offices';
import {
  OfficeType,
  OfficeTypeAll,
  OfficeTypeFilter,
} from 'constants/officeType';
import { getOwnerNameFromUser } from 'utils/userUtils';

export const filterByLocation = (
  galleryEvents: GalleryEvent[],
  locations: OfficeName[]
): GalleryEvent[] => {
  return galleryEvents.filter(
    (galleryEvent) =>
      remoteOffices
        .map((remoteOffice) => remoteOffice.toLowerCase())
        .includes(galleryEvent.office.toLowerCase()) ||
      locations
        .map((location) => location.toLowerCase())
        .includes(galleryEvent.office.toLowerCase())
  );
};

const getEventSearchContent = (galleryEvent: GalleryEvent) => {
  const titleContent = galleryEvent.title?.toLowerCase();
  const descriptionContent = galleryEvent.description?.toLowerCase();
  const officeContent = galleryEvent.office?.toLowerCase();
  const ownerContent = getOwnerNameFromUser(galleryEvent.owner).toLowerCase();

  return titleContent + descriptionContent + officeContent + ownerContent;
};

export const searchGalleryEvent = (
  galleryEvents: GalleryEvent[],
  searchTerm: string
) => {
  const cleanedSearchTerm = searchTerm.toLowerCase();

  return galleryEvents.filter((galleryEvent) =>
    getEventSearchContent(galleryEvent).includes(cleanedSearchTerm)
  );
};

export const filterByOfficeType = (
  galleryEvents: GalleryEvent[],
  value: OfficeTypeFilter
): GalleryEvent[] => {
  if (!value || value === OfficeTypeAll.All) return galleryEvents;

  return galleryEvents.filter(
    (galleryEvent) =>
      (value === OfficeType.Remote && galleryEvent.remotelyAccessible) ||
      (value === OfficeType.OnSite &&
        offices.includes(galleryEvent.office as OfficeName))
  );
};

export const filterByCategories = (
  galleryEvents: GalleryEvent[],
  categories: Category[]
): GalleryEvent[] => {
  if (categories.length) {
    return galleryEvents.filter((galleryEvent) => {
      return categories.some((category) =>
        galleryEvent.categories
          .map((category) => (category === 'Leisure' ? 'Off-Topic' : category))
          .includes(category)
      );
    });
  }
  return galleryEvents;
};

/**
 * Finds the image that is closest to the target width. If no image is found
 * with the target width, then the next largest image will be picked in order to not sacrifice
 * on quality.
 * @param galleryEvent
 * @param options
 */
export const findClosestImage = (
  galleryEvent: GalleryEventDetails,
  options?: { targetWidth?: number }
) => {
  const { targetWidth } = { targetWidth: 300, ...options };

  if (!galleryEvent.image) {
    return undefined;
  }
  if (!galleryEvent.image.variations || !galleryEvent.image.variations.length) {
    return galleryEvent.image;
  }

  // ignore variations smaller than the target size to prevent quality downscale
  const relevantVariations = galleryEvent.image.variations.filter(
    (variation) => variation.width >= targetWidth
  );

  if (relevantVariations.length === 0) {
    return galleryEvent.image;
  }

  // find the smallest variation greater or equal to the target width
  return relevantVariations.reduce((agg, current) => {
    if (current.width < agg.width) {
      return current;
    }
    return agg;
  });
};
