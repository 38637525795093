import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(() => ({
  root: {
    display: 'flex',
    margin: '70px 0',
  },
  columnsWidth: {
    width: '100%',
    maxWidth: '210px',
    position: 'relative',
  },
  activeDay: {
    backgroundColor: 'rgba(214, 216, 255, 0.1)',
  },
}));
