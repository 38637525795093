import { makeStyles } from '@material-ui/core/styles';

export const useEventDescriptionCardStyle = makeStyles((theme) => ({
  headerContainer: {
    height: '120px',
    width: '100%',
    backgroundSize: 'cover',
    borderRadius: '10px',
  },
  headerImageOverlayContainer: {
    width: '100%',
    height: '100%',
    background: 'rgba(44, 63, 107, .72)',
    borderRadius: '10px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLeftContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  headerCardTitle: {
    paddingLeft: '20px',
    fontWeight: 'bold',
    fontSize: '32px',
    letterSpacing: '0.1px',
    color: '#FFFFFF',
    textAlign: 'left',
  },
  headerInitiativeChip: {
    padding: '2px 14px 2px 14px',
    marginLeft: '20px',
    marginTop: '10px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    justifySelf: 'flex-end',
  },
  headerRightContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-end',
    paddingTop: '10px',
    paddingRight: '15px',
    paddingBottom: '8px',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  headerCloseButton: {
    border: 0,
    padding: 0,
    minWidth: '10px',
  },
  headerCopyLinkButton: {
    margin: 'inherit',
  },
  headerCopyLinkIcon: {
    width: '14px',
    height: '14px',
  },
  headerCopyLinkText: {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '16px',
    paddingLeft: '5px',
  },
  detailCardContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(4)}px`,
    marginTop: '20px',
    marginBottom: '10px',
  },
  detailCardEventDetailContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  addToCalendarButton: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  detailCardButtonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-end',
  },
  detailCardButtonContainerIcon: {
    marginRight: theme.spacing(1),
    width: '12px',
    height: '12px',
  },
  detailCardButtonContainerText: {
    marginTop: '3px',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
  },
  divider: {
    backgroundColor: '#D6D8FF',
    margin: '0 8px',
  },
  detailCardDescriptionContainer: {
    margin: `0 ${theme.spacing(3)}px`,
  },
  detailCardDescriptionTitle: {
    color: ' #787878',
    margin: '15px 10px 10px',
  },
  detailCardDescriptionText: {
    color: theme.palette.text.primary,
    whiteSpace: 'pre-line',
    wordBreak: 'break-word',
    marginLeft: '10px',
    textAlign: 'justify',
  },
  loadingIndicator: {
    marginTop: theme.spacing(1),
  },
  iconButton: {
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
}));
