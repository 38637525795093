export const categoryMap = {
  EDGE: '#FFCECE',
  Tech: '#FFE3C0',
  Product: '#A0EDF4',
  'Soft Skills': '#B9F1AD',
  ES: '#BFB6F3',
  TS: '#BFB6F3',
  OPE: '#BFB6F3',
  'Off-Topic': '#D3D0D0',
};

export type Category = keyof typeof categoryMap;

export const categories: Category[] = Object.keys(categoryMap) as Category[];
