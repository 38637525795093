import React, { FC } from 'react';
import { MapPin } from 'react-feather';
import { InputAdornment } from '@material-ui/core';
import { Field } from 'formik';

import { CategorySelectBox } from 'components/Category/CategorySelectBox';
import { GalleryForm, GalleryFormTextField } from 'components/GalleryForm';
import { initialInitiativeFormData } from 'constants/initialStates';
import { offices, remoteOffices } from 'constants/offices';

import { GalleryFormAutocomplete } from '../GalleryForm/GalleryFormAutocomplete';

import { initiativeFormStyle } from './InitiativeForm.style';
import { ReactComponent as SlackSVG } from './slack.svg';

type InitiativeFormProps = {
  title: string;
  initiative?: InitiativeFormData;
  onClickSubmit: (values: InitiativeFormData, { setSubmitting }) => void;
  onClickCancel: () => void;
};

export const InitiativeForm: FC<InitiativeFormProps> = ({
  title,
  initiative,
  onClickSubmit,
  onClickCancel,
  children,
}) => {
  const classes = initiativeFormStyle();

  const validateFields = (values: InitiativeFormData) => {
    const errors: Partial<ErrorValues> = {};
    if (!values.title) {
      errors.title = 'Required';
    }
    if (!values.description) {
      errors.description = 'Required';
    }
    if (!values.office) {
      errors.office = 'Required';
    }
    return errors;
  };

  const fields = (values, setFieldValue, errors) => {
    return (
      <>
        <div className={classes.initiativeDetailsWrapper}>
          <GalleryFormTextField
            inactive={false}
            name="title"
            label="Title"
            placeholder="Title"
            value={values.title}
            error={errors.title}
            onChange={(e) => {
              errors.title = '';
              setFieldValue('title', e.target.value);
            }}
            required
          />
          <GalleryFormTextField
            inactive={false}
            name="description"
            label="Description"
            placeholder="Description"
            value={values.description}
            error={errors.description}
            onChange={(e) => {
              errors.description = '';
              setFieldValue('description', e.target.value);
            }}
            multiline
            required
            rows={2}
          />
          <Field
            as={CategorySelectBox}
            name="categories"
            selectedCategories={values.categories}
            onChange={setFieldValue}
          />
        </div>
        <GalleryFormTextField
          inactive={false}
          name="slackChannel"
          placeholder="Slack Channel (optional)"
          value={values.slackChannel}
          error={errors.slackChannel}
          onChange={(e) => {
            errors.slackChannel = '';
            setFieldValue('slackChannel', e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SlackSVG />
              </InputAdornment>
            ),
          }}
        />
        <GalleryFormAutocomplete
          options={
            remoteOffices.includes(values.office) // If the office value is set to a remote office previously (edit screen), still show that option.
              ? offices.concat(values.office)
              : offices
          }
          name="office"
          value={values.office}
          error={errors.office}
          placeholder="Office *"
          setField={setFieldValue}
          onInputChange={() => (errors.office = '')}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MapPin />
              </InputAdornment>
            ),
          }}
        />
      </>
    );
  };

  return (
    <GalleryForm
      title={title}
      initialValues={initiative ?? initialInitiativeFormData}
      validateFields={validateFields}
      onSubmit={onClickSubmit}
      onClose={onClickCancel}
      fields={fields}
    >
      {children}
    </GalleryForm>
  );
};
