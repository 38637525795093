export const getOwnerNameFromUser = (user: User): string => {
  return user?.outlookName?.trim() || user?.slackName?.trim() || 'Unknown';
};

//Splits array in two based on presence of predicate in each array elements
function partition(array, predicate) {
  return array.reduce(
    (acc, item) =>
      predicate(item) ? (acc[0].push(item), acc) : (acc[1].push(item), acc),
    [[], []]
  );
}

export const sortAutocompleteUserList = (
  data: Autocomplete[],
  prefix: string
): Autocomplete[] => {
  prefix = prefix.toLowerCase();
  const result = partition(
    data,
    (i) => i.name.toLowerCase().indexOf(prefix) >= 0
  );
  let matchName = result[0];
  const matchEmail = result[1];
  matchName = matchName.sort((a, b) => {
    if (
      a.name.toLowerCase().startsWith(prefix) &&
      b.name.toLowerCase().startsWith(prefix)
    )
      return a.name.localeCompare(b.name);
    else if (a.name.toLowerCase().startsWith(prefix)) return -1;
    else if (b.name.toLowerCase().startsWith(prefix)) return 1;
    else
      return a.name.toLowerCase().indexOf(prefix) <
        b.name.toLowerCase().indexOf(prefix)
        ? -1
        : 1;
  });
  return matchName.concat(matchEmail);
};

export const removeNullUsers = (options: Autocomplete[]) =>
  options.filter((option) => !!option.email && !!option.name);

export const attendeeToAutocomplete = (value: Attendee[]): Autocomplete[] => {
  return value?.map(
    (registration) =>
      ({
        name: getOwnerNameFromUser(registration.attendee),
        email: registration.attendee.emails.sort(function (a, b) {
          return b.length - a.length;
        })[0],
        _id: registration.attendee._id,
      } as Autocomplete)
  );
};

export const attendeesToIds = (value): string[] => {
  return value?.map((a) => a.attendee._id);
};
