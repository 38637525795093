import React, { FC, useCallback } from 'react';
import { Video } from 'react-feather';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

import { GalleryTab } from 'components/BoardTabs';

import { useStyle } from './CalendarEvent.style';

export const CalendarEvent: FC<{
  key?: string;
  id?: string;
  title?: string;
  isRemote?: boolean;
  office?: string;
  eventDuration?: number;
  noEvent?: boolean;
  activeClass?: string;
  officeColour?;
}> = ({
  office,
  title,
  isRemote,
  noEvent,
  activeClass,
  eventDuration,
  officeColour,
  id,
}) => {
  const classes = useStyle();
  const history = useHistory();

  const onClick = useCallback(() => {
    if (!!id) history.push(`/${GalleryTab.Events}/calendar/${id}`);
  }, [id, history]);

  const officeClasses = classNames(classes.eventWrapper, activeClass, {
    [classes.emptyWrapper]: noEvent === true,
    [classes.eventDuration15]: eventDuration === 15,
    [classes.eventDuration60]: eventDuration === 60,
    [classes.eventDuration90]: eventDuration === 90,
    [classes.eventDuration120]: eventDuration === 120,
  });

  const textColour = {
    color: `${officeColour !== undefined && officeColour.colour}`,
  };
  const backgroundColour = {
    backgroundColor: `${officeColour !== undefined && officeColour.colour}`,
  };

  const borderColour = {
    borderLeft: `5px solid ${
      officeColour !== undefined && officeColour.colour
    }`,
  };

  return (
    <div className={officeClasses} style={borderColour} onClick={onClick}>
      <div className={classes.eventHeader}>
        <span className={classes.eventOffice} style={textColour}>
          {office}
        </span>
        {isRemote && (
          <span className={classes.eventIconWrapper} style={backgroundColour}>
            <Video className={classes.eventIcon} />
          </span>
        )}
      </div>
      <div className={classes.eventTitle} style={textColour}>
        {title}
      </div>
    </div>
  );
};
