import { makeStyles } from '@material-ui/core/styles';

export const initiativeFormStyle = makeStyles((theme) => ({
  initiativeFormTitle: {
    margin: '0 0 12px 0',
    fontSize: '1.125rem',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  initiativeDetailsWrapper: {
    marginBottom: theme.spacing(5),
  },
}));
