import React, { FC } from 'react';
import { QueryBuilder } from '@material-ui/icons';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import classNames from 'classnames';

import { useFormfieldStyle } from './GalleryFormfield.style';

type GalleryFormTimePickerProps = {
  name: string;
  inactive: boolean;
  placeholder: string;
  error?: string;
  value: Date;
  onChange: (value: MaterialUiPickersDate) => void;
};

export const GalleryFormTimePicker: FC<GalleryFormTimePickerProps> = ({
  name,
  value,
  placeholder,
  error,
  onChange,
}) => {
  const classes = useFormfieldStyle();
  return (
    <KeyboardTimePicker
      name={name}
      value={value}
      inputVariant="outlined"
      format="HH:mm"
      ampm={false}
      minutesStep={5}
      openTo="hours"
      emptyLabel={undefined}
      placeholder={placeholder}
      className={classNames(classes.inputField, classes.inputWithAdornment)}
      InputLabelProps={{
        shrink: true,
      }}
      error={!!error}
      helperText={error}
      onChange={onChange}
      InputAdornmentProps={{ position: 'start' }}
      KeyboardButtonProps={{
        className: classes.noPaddingField,
        name: 'timePicker-popup-' + name,
      }}
      keyboardIcon={<QueryBuilder />}
    />
  );
};
