import { makeStyles } from '@material-ui/core/styles';

export const useCategorySelectBoxStyles = makeStyles((theme) => ({
  selectField: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: theme.spacing(5),

    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
      top: 'calc((44px - 1rem) / 2)',
    },
    '& .MuiInputBase-root': {
      cursor: 'pointer',
    },
    '& fieldset': {
      borderColor: theme.palette.text.disabled,
    },
    '& .MuiInputBase-root.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      border: '1px solid',
    },
    '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      border: '1px solid',
    },
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer',
      color: theme.palette.text.secondary,
      fontStyle: 'normal',
      '&::placeholder': {
        color: theme.palette.text.secondary,
        opacity: 'unset',
      },
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.text.disabled}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.disabled,
    },
  },
  selectOption: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    ...theme.typography.body1,
    color: theme.palette.primary.baseText,

    '&:hover': {
      background: theme.palette.primary.light3,
      paddingLeft: theme.spacing(1.5),
      color: theme.palette.primary.mediumDark,
    },
    '&:active': {
      background: theme.palette.primary.light3,
      color: theme.palette.primary.mediumDark,
      paddingLeft: theme.spacing(1.5),
    },
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light3,
      color: theme.palette.primary.mediumDark,
    },
    '& span.MuiCheckbox-colorSecondary.Mui-checked.MuiIconButton-colorSecondary': {
      background: theme.palette.primary.light3,
      color: theme.palette.primary.main,
    },
  },
  optionsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  optionsTitle: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    paddingLeft: theme.spacing(2),
  },
  dropdownStyle: {
    borderRadius: '8px',
  },
  errorText: {
    fontSize: '12px',
    color: theme.palette.error.main,
    marginRight: theme.spacing(2),
  },
  selected: {
    color: theme.palette.text.primary,
  },
}));
