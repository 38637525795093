import React, { FC, useState } from 'react';
import { CheckSquare, Square } from 'react-feather';
import { RouteComponentProps, withRouter } from 'react-router';
import { MenuItem } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import { useBoardContext } from 'store/BoardProvider';
import { resetSelectedOffices, setLocation } from 'store/reducers/filter';

import { allOffices as offices, OfficeName } from 'constants/offices';
import { addUrlSearchParam } from 'utils/urlUtils';

import { useFilterRowStyles } from './BoardFilters.style';

const OfficeFilterWithoutRouter: FC<RouteComponentProps> = (
  props: RouteComponentProps
) => {
  const { history } = props;
  const routerLocation = props.location;
  const classes = useFilterRowStyles();
  const { boardFilters, dispatch } = useBoardContext();
  const { location } = boardFilters;
  const [isAll, setIsAll] = useState(false);

  const setFilters = (locations: OfficeName[]) => {
    const officeNamesStr = locations.join(',').toLowerCase();
    const searchURL = addUrlSearchParam(
      routerLocation.search,
      'locations',
      officeNamesStr
    );

    if (locations.includes('All Offices') && !isAll) {
      dispatch(setLocation([...offices]));
      setIsAll(true);
      history.push({ search: searchURL });
    } else if (!locations.includes('All Offices') && isAll) {
      dispatch(resetSelectedOffices());
      setIsAll(false);
      history.push({ search: '' });
    } else {
      dispatch(setLocation(locations));
      history.push({ search: searchURL });
    }
  };
  const isAllSelected = location.length === offices.length;
  const isSomeSelected =
    location.length > 0 && location.length < offices.length;

  const getLocationRenderValue = (locations) => {
    if (isAllSelected) {
      return 'All Offices';
    } else if (locations.length === 0) {
      return 'Select Offices';
    } else {
      return (locations as OfficeName[]).join(', ');
    }
  };

  const handleLocationChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newLocations = event.target.value as OfficeName[];

    setFilters(offices.filter((office) => newLocations.indexOf(office) >= 0));
  };

  const clearIcon = () => (
    <ClearIcon
      className={classes.clearIcon}
      onClick={(event) => {
        event.stopPropagation();
        history.push({ search: '' });
        dispatch(resetSelectedOffices());
        setIsAll(false);
      }}
    />
  );

  return (
    <Select
      className={classNames(classes.filterField, classes.boxedFilterField, {
        [classes.filterFieldActive]: isSomeSelected,
      })}
      variant="outlined"
      IconComponent={location.length === 0 ? ArrowDropDownIcon : clearIcon}
      multiple
      displayEmpty
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      value={location}
      onChange={handleLocationChange}
      renderValue={getLocationRenderValue}
      data-testid="office-input"
    >
      <MenuItem disabled value="OFFICES" className={classes.selectOptionTitle}>
        OFFICES
      </MenuItem>
      {/* To remove the highlight on the first menu item*/}
      <MenuItem key="placeholder" style={{ display: 'none' }} />
      {offices.map((office) => (
        <MenuItem value={office} key={office} className={classes.selectOption}>
          <Checkbox
            checked={location.indexOf(office) > -1}
            icon={<Square />}
            checkedIcon={<CheckSquare />}
          />
          {office}
        </MenuItem>
      ))}
    </Select>
  );
};

const OfficeFilter = withRouter(OfficeFilterWithoutRouter);
export { OfficeFilter };
