import { BoardState } from 'store/BoardProvider';

import { alertsReducer } from './alerts';
import { dataReducer } from './data';
import { eventsReducer } from './events';
import { filterReducer } from './filter';
import { initiativeReducer } from './initiatives';

export type Reducer = (state: BoardState, action: Action) => BoardState;

export const boardStateReducer: Reducer = (state: BoardState, action) => {
  let newState = state;
  newState = filterReducer(newState, action);
  newState = dataReducer(newState, action);
  newState = eventsReducer(newState, action);
  newState = initiativeReducer(newState, action);
  newState = alertsReducer(newState, action);
  if (state === newState) {
    throw new Error(`Unknown action: ${action?.type}`);
  }
  return newState;
};
