import { makeStyles } from '@material-ui/core/styles';

export const useDateRangePickerDialogStyles = makeStyles((theme) => ({
  dateRangeDialog: {
    '& .MuiPaper-root': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: '1100px',
    },
  },
  dialogContent: {
    padding: theme.spacing(2),
    paddingTop: 0,
    paddingBottom: 0,
  },
  buttonRow: {
    height: theme.spacing(9),
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  dateRange: {
    '& .rdrMonthAndYearPickers': {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: '1.4rem',
    },

    '& .rdrStaticRange': {
      fontFamily: theme.typography.fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.1rem',
      padding: theme.spacing(0.5, 0),
    },
    '& .rdrDayNumber > span:after': {
      display: 'none', // the underline for current day
    },

    '& .rdrInputRanges': {
      display: 'none',
    },
    '@media screen and (max-width: 600px)': {
      '& .rdrDefinedRangesWrapper': {
        display: 'none',
      },
    },
    '& .rdrDayToday .rdrDayNumber span::after': {
      background: 'black',
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span': {
      // color: 'black',
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after': {
      background: 'black',
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span': {
      // color: 'black',
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after': {
      background: 'black',
    },
    '& .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span': {
      //color: 'black',
    },
    '& .rdrMonthName': {
      visibility: 'hidden',
      height: 0,
      padding: 0,
    },
    '& .rdrNextPrevButton': {
      background: 'white',
    },
    '& .rdrNextButton i': {
      borderLeftColor: 'black',
    },
    '& .rdrPrevButton i': {
      borderRightColor: 'black',
    },
  },
}));
