import React, { FC } from 'react';
import {
  useFetchAllEvents,
  useFilteredEvents,
  useRefreshWhenVisible,
} from 'hooks';
import _ from 'lodash';

import {
  CalendarFilter,
  CategoryFilter,
  OfficeFilter,
} from 'components/BoardFilters';
import {
  BoardHeader,
  BoardNav,
  NewButton,
  SearchBox,
} from 'components/BoardHeader';
import { GalleryTab } from 'components/BoardTabs';
import { CalendarView } from 'components/CalendarView/CalendarView';
import { EventGridView } from 'components/EventGridView/EventGridView';
import { EventViewSelector } from 'components/EventsPage';
import { LoadingWrapper } from 'components/LoadingWrapper';
import { ErrorBox } from 'components/UI/ErrorBox';

export type EventViewProp = {
  galleryEvents: GalleryEvent[];
};

const viewTypeToComponent = {
  calendar: CalendarView,
  grid: EventGridView,
};
export type ViewType = keyof typeof viewTypeToComponent;

export const GalleryEventBoard: FC<{ viewType: ViewType }> = ({ viewType }) => {
  const { loading, error, galleryEvents, load } = useFetchAllEvents(false);
  const filteredEvents = useFilteredEvents(Object.values(galleryEvents));
  useRefreshWhenVisible(load);

  const EventView = _.get(viewTypeToComponent, viewType, EventGridView);

  return (
    <>
      <LoadingWrapper loading={loading} />
      {error && (
        <ErrorBox>
          An error occurred, please reload page. ({error.toString()})
        </ErrorBox>
      )}
      <BoardHeader>
        <BoardHeader.Row>
          <SearchBox placeholder="Search for an event" />
          <NewButton
            linkTo={`/${GalleryTab.Events}/${viewType}/new`}
            label="Create Event"
          />
        </BoardHeader.Row>
        <BoardHeader.Row>
          <BoardNav />
          <EventViewSelector />
        </BoardHeader.Row>
        <BoardHeader.Row>
          <CategoryFilter />
          <OfficeFilter />
          <CalendarFilter />
        </BoardHeader.Row>
      </BoardHeader>
      <EventView galleryEvents={filteredEvents} />
    </>
  );
};
