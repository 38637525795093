import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  loadingWrapper: {
    position: 'absolute',
    width: '100%',
    left: 0,
    right: 0,
  },
}));
