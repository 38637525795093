import React, { FC } from 'react';
import { Clock, MapPin, Users, Video } from 'react-feather';
import { CardContent, Link, Typography } from '@material-ui/core';
import classNames from 'classnames';
import moment from 'moment';

import { getOwnerNameFromUser } from 'utils/userUtils';

import { useStyles } from './EventDescriptionCardBody.style';

export const EventDescriptionCardBody: FC<{ galleryEvent: GalleryEvent }> = ({
  galleryEvent,
}) => {
  const classes = useStyles();

  const eventDuration = moment
    .utc(
      moment
        .duration(
          moment(galleryEvent.endTime).diff(moment(galleryEvent.startTime))
        )
        .asMilliseconds()
    )
    .format('H[h] mm[min]')
    .replace(/^0h(?:0:0?)?|00min$/, '')
    .trim();

  return (
    <CardContent className={classes.cardInfo}>
      <div className={classes.cardInfoLine}>
        <Users className={classes.cardInfoIcon} />
        <Typography variant="body2" className={classes.cardInfoText}>
          <span className={classes.cardInfoTitle}>Organizer:</span>
          {getOwnerNameFromUser(galleryEvent.owner)}
        </Typography>
      </div>
      <div className={classes.cardInfoLine}>
        <Clock className={classes.cardInfoIcon} />
        <Typography variant="body2" className={classes.cardInfoText}>
          <span className={classes.cardInfoTitle}>Date & Time:</span>
          {moment(galleryEvent.startTime).format('DD.MM.YY HH:mm')}
          {moment(galleryEvent.endTime).format(' - HH:mm')}
          {'  (' + eventDuration + ')'}
        </Typography>
      </div>
      <div className={classes.cardInfoLine}>
        <MapPin className={classes.cardInfoIcon} />
        <Typography variant="body2" className={classes.cardInfoText}>
          <span className={classes.cardInfoTitle}>Location:</span>
          {galleryEvent.office}
        </Typography>
      </div>
      {galleryEvent.remoteLink ? (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={galleryEvent.remoteLink}
        >
          <div
            className={classNames(
              classes.cardInfoLine,
              classes.cardInfoLinkActive
            )}
          >
            <Video className={classes.cardInfoLinkIcon} />
            <Typography variant="body2">Digital Event: Join here</Typography>
          </div>
        </Link>
      ) : (
        <div
          className={classNames(
            classes.cardInfoLine,
            classes.cardInfoLinkInactive
          )}
        >
          <Video className={classes.cardInfoLinkIcon} />
          <Typography variant="body2">Digital Event: No</Typography>
        </div>
      )}
    </CardContent>
  );
};
