import React, { FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import moment from 'moment';
import { useBoardContext } from 'store/BoardProvider';
import { setDateRange } from 'store/reducers/filter';

import { CalendarEvents } from './CalendarEvents/CalendarEvents';
import { CalendarHeader } from './CalendarHeader/CalendarHeader';
import { CalendarTimeSlots } from './CalendarTimeSlots/CalendarTimeSlots';
import { useStyle } from './CalendarView.style';

export const CalendarView: FC<{
  galleryEvents: GalleryEvent[];
}> = ({ galleryEvents }) => {
  const { boardFilters } = useBoardContext();
  const { dispatch } = useBoardContext();
  const { root, columnsWidth, activeDay } = useStyle();
  const startDate = boardFilters.startDate || moment().toDate();
  const [week, setWeek] = useState(moment(startDate).startOf('isoWeek'));

  useEffect(() => {
    setWeek(moment(boardFilters.startDate));
  }, [boardFilters.startDate]);

  const days = [0, 1, 2, 3, 4].map((d) =>
    week.startOf('isoWeek').clone().add(d, 'days')
  );

  const setDate = () =>
    dispatch(
      setDateRange({
        startDate: moment(week).isoWeek(week.isoWeek()),
        endDate: moment(week).isoWeek(week.isoWeek()).endOf('isoWeek'),
      })
    );

  useEffect(setDate, []);
  const formattedDate = (date) => date.format('YYYY-MM-DD');

  const today = formattedDate(moment(new Date()));

  return (
    <>
      <Grid className={root}>
        <CalendarTimeSlots />
        {days.map((day) => (
          <div
            key={formattedDate(day)}
            className={classNames(columnsWidth, {
              [activeDay]: today === formattedDate(day),
            })}
          >
            <CalendarHeader
              activeClass={classNames({
                [activeDay]: today === formattedDate(day),
              })}
              date={formattedDate(day)}
            />
            <CalendarEvents
              activeClass={classNames({
                [activeDay]: today === formattedDate(day),
              })}
              date={formattedDate(day)}
              events={galleryEvents}
            />
          </div>
        ))}
        <CalendarTimeSlots position="right" />
      </Grid>
    </>
  );
};
