import React, { FC } from 'react';
import { Edit2 } from 'react-feather';
import { Typography } from '@material-ui/core';

import { useInitiativeEditButtonStyles } from './InitiativeEditButton.style';

type InitiativeEditButtonProps = {
  onClick: () => void;
  className?;
  disabled?;
};

const InitiativeEditButton: FC<InitiativeEditButtonProps> = ({ onClick }) => {
  const { editButton, editIcon, editText } = useInitiativeEditButtonStyles();
  return (
    <>
      <div onClick={onClick} className={editButton}>
        <Edit2 className={editIcon} />
        <Typography variant="button" className={editText}>
          Edit
        </Typography>
      </div>
    </>
  );
};

export default InitiativeEditButton;
