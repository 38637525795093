import React, { FC } from 'react';
import { Container } from '@material-ui/core';

import { BoardAlert } from 'components/BoardAlert';

import { useStyles } from './Board.style';

export const Board: FC = ({ children }) => {
  const { boardContainer } = useStyles();

  return (
    <>
      <BoardAlert />
      <Container maxWidth="lg" className={boardContainer}>
        {children}
        {/* This is really annoying, but the Container does not allow only children as child component*/}
        <div />
      </Container>
    </>
  );
};
