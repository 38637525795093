const BASE = process.env.REACT_APP_BASE_URL;

export const ROUTES = {
  AUTH_CALLBACK: '/authenticated',
  LOGIN: '/login',
  ROOT: '/',
};

const API_ENDPOINTS = {
  EVENTS: '/events',
  INITIATIVES: '/initiatives',
  AUTH_TOKEN: '/oauth2/login',
  UPLOAD: '/files',
  USERSEARCH: '/users/filter',
};

export const ENDPOINT_GALLERY_EVENTS = `${BASE}${API_ENDPOINTS.EVENTS}`;
export const ENDPOINT_INITIATIVES = `${BASE}${API_ENDPOINTS.INITIATIVES}`;
export const ENDPOINT_AUTH_TOKEN = `${BASE}${API_ENDPOINTS.AUTH_TOKEN}`;
export const ENDPOINT_UPLOAD = `${BASE}${API_ENDPOINTS.UPLOAD}`;
export const ENDPOINT_USERSEARCH = `${BASE}${API_ENDPOINTS.USERSEARCH}`;

export const LINKS = {
  SLACK_CHANNEL_BASE: 'https://slack.com/app_redirect?channel=',
};
