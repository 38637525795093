import React, { FC } from 'react';
import { useFetchAllInitiatives, useRefreshWhenVisible } from 'hooks';

import { CategoryFilter, OfficeFilter } from 'components/BoardFilters';
import {
  BoardHeader,
  BoardNav,
  NewButton,
  SearchBox,
} from 'components/BoardHeader';
import { GalleryTab } from 'components/BoardTabs';
import { InitiativesGallery } from 'components/InitiativesGallery';
import { LoadingWrapper } from 'components/LoadingWrapper';
import { ErrorBox } from 'components/UI/ErrorBox';

export const InitiativeBoard: FC = () => {
  const { loading, error, initiatives, load } = useFetchAllInitiatives(false);
  useRefreshWhenVisible(load);

  return (
    <>
      <LoadingWrapper loading={loading} />
      {error && (
        <ErrorBox>
          An error occurred, please reload page. ({error.toString()})
        </ErrorBox>
      )}
      <BoardHeader>
        <BoardHeader.Row>
          <SearchBox placeholder="Search for an Initiative" />
          <NewButton
            linkTo={`/${GalleryTab.Initiatives}/new`}
            label="Create initiative"
          />
        </BoardHeader.Row>
        <BoardHeader.Row>
          <BoardNav />
        </BoardHeader.Row>
        <BoardHeader.Row>
          <CategoryFilter />
          <OfficeFilter />
        </BoardHeader.Row>
      </BoardHeader>
      <InitiativesGallery initiatives={Object.values(initiatives)} />
    </>
  );
};
