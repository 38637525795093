import React, { FC, useEffect, useState } from 'react';
import { Edit2, Link, Trash, X } from 'react-feather';
import { useHistory } from 'react-router-dom';
import {
  Chip,
  Divider,
  IconButton,
  LinearProgress,
  Typography,
} from '@material-ui/core';
import { useEvent } from 'hooks';
import { useLazyLoadImage } from 'hooks';
import { useErrorableAction } from 'hooks/useErrorableAction';
import { deleteEvent } from 'store/reducers/events';

import { GalleryTab } from 'components/BoardTabs';
import { AlertDialog } from 'components/UI';
import { AddToCalendarButton } from 'components/UI/AddToCalendarButton';
import { officeDictionary } from 'constants/offices';
import { outlookSource } from 'constants/sources';
import { getSession } from 'services/AuthenticationService';
import { deleteGalleryEvent } from 'services/GalleryEventService';

import { useEventDescriptionCardStyle } from './EventDescriptionCard.style';
import { EventDescriptionCardBody } from './EventDescriptionCardBody';

type EventDescriptionCardProps = {
  onEdit: () => void;
  onClose: () => void;
  onCancel: () => void;
  id: string;
};

export const EventDescriptionCard: FC<EventDescriptionCardProps> = ({
  onEdit,
  onClose,
  onCancel,
  id,
}) => {
  const history = useHistory();
  const session = getSession();
  const userId = session._id;
  const userRole = session.role;

  const { event: galleryEvent, loading, error, load } = useEvent(id);

  const [openDeleteAlertDialog, setDeleteAlertDialog] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    ErrorComponent: ErrorDelete,
    submit: submitDelete,
  } = useErrorableAction(deleteGalleryEvent, deleteEvent);

  useEffect(() => {
    load();
  }, [id, load]);
  const classes = useEventDescriptionCardStyle();
  const loaded = useLazyLoadImage(galleryEvent?.image?.location);

  if (galleryEvent === undefined) return null;

  const eventComesFromOutlook = galleryEvent.source === outlookSource;
  const eventOwnerIsOffice =
    galleryEvent.owner == null
      ? false
      : Object.values(officeDictionary).find(
          (entry) => entry.outlookId === galleryEvent.owner.outlookId
        );
  const cannotEditPersonalEvent = eventComesFromOutlook && !eventOwnerIsOffice;
  const canEdit =
    !loading &&
    !error &&
    (userId === galleryEvent.owner?._id || userRole === 'admin');

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${window.location.href}/${galleryEvent._id}`
    );
  };

  const onClickDelete = () => {
    setDeleteAlertDialog(!openDeleteAlertDialog);
  };

  const onDeleteGalleryEvent = async (galleryEvent: GalleryEvent) => {
    setIsLoading(true);
    const { success } = await submitDelete(galleryEvent._id);
    setIsLoading(false);
    if (success) {
      history.replace(`/${GalleryTab.Events}`);
      onCancel();
    }
  };

  return (
    <>
      <div
        className={classes.headerContainer}
        style={{
          backgroundImage: `url(${loaded || '/boids.png'})`,
        }}
      >
        <div className={classes.headerImageOverlayContainer}>
          <div className={classes.headerLeftContainer}>
            <Typography className={classes.headerCardTitle} variant="h4">
              {galleryEvent.title}
            </Typography>
            {galleryEvent.initiative &&
              galleryEvent.initiative.title.length > 0 && (
                <Chip
                  label={`#${galleryEvent.initiative?.title}`}
                  className={classes.headerInitiativeChip}
                  onClick={() => {
                    history.push(`/${GalleryTab.Initiatives}/`);
                  }}
                />
              )}
          </div>
          <div className={classes.headerRightContainer}>
            <div className={classes.headerCloseButton}>
              <IconButton onClick={onClose} className={classes.iconButton}>
                <X />
              </IconButton>
            </div>
            <div className={classes.headerCopyLinkButton}>
              <IconButton onClick={copyLink} className={classes.iconButton}>
                <Link className={classes.headerCopyLinkIcon} />
                <Typography className={classes.headerCopyLinkText}>
                  Copy Link
                </Typography>
              </IconButton>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <LinearProgress className={classes.loadingIndicator} />}

      <div className={classes.detailCardContent}>
        <div className={classes.detailCardEventDetailContainer}>
          <EventDescriptionCardBody galleryEvent={galleryEvent} />
          <span className={classes.addToCalendarButton}>
            <AddToCalendarButton galleryEvent={galleryEvent} />
          </span>
        </div>
        {canEdit && !cannotEditPersonalEvent && (
          <div className={classes.detailCardButtonContainer}>
            <IconButton
              title="Edit"
              onClick={onEdit}
              className={classes.iconButton}
              style={{ color: '#6559FF' }}
            >
              <Edit2
                className={classes.detailCardButtonContainerIcon}
                style={{ fill: '#6559FF' }}
              />
              <Typography className={classes.detailCardButtonContainerText}>
                Edit
              </Typography>
            </IconButton>
            <IconButton
              title="Delete"
              onClick={onClickDelete}
              className={classes.iconButton}
              style={{ color: '#FF7979' }}
            >
              <Trash className={classes.detailCardButtonContainerIcon} />
              <Typography className={classes.detailCardButtonContainerText}>
                Delete
              </Typography>
            </IconButton>
            <ErrorDelete />
          </div>
        )}
      </div>
      <Divider className={classes.divider} />
      <div className={classes.detailCardDescriptionContainer}>
        <h3 className={classes.detailCardDescriptionTitle}>Description</h3>
        <Typography
          className={classes.detailCardDescriptionText}
          variant="body2"
        >
          {galleryEvent.description}
        </Typography>
      </div>
      <AlertDialog
        open={openDeleteAlertDialog}
        title="Are you really sure you want to delete this event?"
        onClick={() => {
          setDeleteAlertDialog(!openDeleteAlertDialog);
          onDeleteGalleryEvent(galleryEvent);
        }}
        onCancel={() => setDeleteAlertDialog(!openDeleteAlertDialog)}
      />
    </>
  );
};
