import React, { FC } from 'react';
import { CheckSquare, Square } from 'react-feather';
import { useHistory, useLocation } from 'react-router-dom';
import { MenuItem } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import { useBoardContext } from 'store/BoardProvider';
import { resetSelectedCategory, setCategories } from 'store/reducers/filter';

import {
  categories as allCategories,
  Category,
} from 'components/Category/categories';
import { addUrlSearchParam } from 'utils/urlUtils';

import { useFilterRowStyles } from './BoardFilters.style';

export const CategoryFilter: FC = () => {
  const classes = useFilterRowStyles();
  const { boardFilters, dispatch } = useBoardContext();
  const location = useLocation();
  const history = useHistory();
  const { categories } = boardFilters;

  const handleCategoryChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const newCategories = event.target.value as Category[];
    dispatch(setCategories(newCategories));
    const categoriesString = newCategories.join(',').toLowerCase();
    const searchURL = addUrlSearchParam(
      location.search,
      'tags',
      categoriesString
    );
    history.push({ search: searchURL });
  };

  const clearIcon = () => (
    <ClearIcon
      className={classes.clearIcon}
      onClick={(event) => {
        event.stopPropagation();
        history.push({ search: '' });
        dispatch(resetSelectedCategory());
      }}
    />
  );

  return (
    <Select
      className={classNames(classes.filterField, classes.boxedFilterField)}
      variant="outlined"
      IconComponent={categories.length === 0 ? ArrowDropDownIcon : clearIcon}
      displayEmpty
      value={categories}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      onChange={handleCategoryChange}
      data-testid="category-input"
      multiple
      renderValue={(selected) => {
        if ((selected as string[]).length === 0) {
          return 'Select Categories';
        }
        return (selected as string[]).join(', ');
      }}
    >
      <MenuItem
        disabled
        value="CATEGORIES"
        className={classes.selectOptionTitle}
      >
        CATEGORIES
      </MenuItem>
      {/* To remove the highlight on the first menu item*/}
      <MenuItem key="placeholder" style={{ display: 'none' }} />
      {allCategories.map((cat) => (
        <MenuItem value={cat} key={cat} className={classes.selectOption}>
          <Checkbox
            checked={categories.indexOf(cat) > -1}
            icon={<Square />}
            checkedIcon={<CheckSquare />}
          />
          {cat}
        </MenuItem>
      ))}
    </Select>
  );
};
