import { makeStyles } from '@material-ui/core/styles';

export const useFormfieldStyle = makeStyles((theme) => ({
  inputField: {
    width: '100%',
    marginBottom: '16px',

    '& .MuiIconButton-label': {
      color: theme.palette.primary.main,
    },

    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.disabled,
    },
    '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.text.disabled}`,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${theme.palette.primary.main}`,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: theme.palette.primary.main,
    },
    '& .MuiFormLabel-root': {
      color: theme.palette.text.secondary,
    },
    '& .MuiAutocomplete-endAdornment': {
      color: theme.palette.text.secondary,
    },
    '& .MuiInputBase-input': {
      '&::placeholder': { color: theme.palette.text.secondary, opacity: 1 },
    },
    '& .Mui-error': {
      '& .MuiInputBase-input': {
        color: theme.palette.error.main,
        opacity: 1,
        '&::placeholder': { color: theme.palette.error.main, opacity: 1 },
      },
      '& .MuiInputAdornment-root': { color: theme.palette.error.main },
      '& .MuiSelect-root': { color: theme.palette.error.main },
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '18.5px 0px',
    },
  },
  inputWithAdornment: {
    '& .MuiAutocomplete-inputRoot.MuiInputBase-adornedStart.MuiOutlinedInput-adornedEnd': {
      '& svg': {
        color: theme.palette.primary.main,
        width: '20px',
      },
    },

    '& > .MuiFormLabel-root:not(.MuiFormLabel-filled):not(.Mui-focused)': {
      transform: 'translate(50px, 14px) scale(1)',
      '& ~ div': {
        '&:not(.Mui-focused) > .MuiInputBase-input::placeholder': {
          opacity: 0,
        },
        '& > .MuiOutlinedInput-notchedOutline > legend': {
          maxWidth: '0',
        },
      },
    },
  },
  optionWithAdornment: {
    color: theme.palette.secondary.main,
  },
  noPaddingField: {
    padding: 0,
  },
}));
