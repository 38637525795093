import React, { FC } from 'react';
import { useInitiative } from 'hooks';
import { useErrorableAction } from 'hooks/useErrorableAction';
import { updateInitiative as updateInitiativeState } from 'store/reducers/initiatives';

import { InitiativeForm } from 'components/InitiativeForm';
import { updateInitiative } from 'services/InitiativeService';

type InitiativeFormEditContainerProps = {
  id: Initiative['_id'];
  onCancel: () => void;
};

const isProduction = process.env.REACT_APP_ENV === 'production';

export const InitiativeFormEditContainer: FC<InitiativeFormEditContainerProps> = ({
  id,
  onCancel,
}) => {
  const { initiative } = useInitiative(id);
  const { ErrorComponent, submit } = useErrorableAction(
    updateInitiative,
    updateInitiativeState
  );

  if (!initiative) return null;

  const onSubmit = (initiative: Initiative) => {
    return async (values: InitiativeFormData, { setSubmitting }) => {
      const updatedInitiative: InitiativeFormData = {
        title: values.title,
        categories: values.categories,
        description: values.description,
        slackChannel: values.slackChannel,
        office: isProduction ? values.office : 'Test Office',
        remoteLink: values.remoteLink,
      };

      const { success } = await submit(updatedInitiative, initiative._id);

      setSubmitting(false);
      if (success) {
        onCancel();
      }
    };
  };

  return (
    <>
      <InitiativeForm
        title="Edit Initiative"
        initiative={initiative}
        onClickSubmit={onSubmit(initiative)}
        onClickCancel={onCancel}
      ></InitiativeForm>
      <ErrorComponent />
    </>
  );
};
