import { makeStyles } from '@material-ui/core/styles';

export const eventFormStyle = makeStyles((theme) => ({
  locationWrapper: {
    width: '100%',
    display: 'flex',
    '& > :first-child': {
      width: '41%',
    },
    '& > :nth-child(2)': {
      width: '55.5%',
    },
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  dateTimeWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    '& > :first-child': {
      width: '35%',
    },
    '& > :nth-child(2)': {
      width: '25%',
    },

    '& > :nth-child(3)': {
      margin: '10px 10px 0',
      fontSize: theme.typography.subtitle1.fontSize,
      lineHeight: theme.typography.subtitle1.lineHeight,
    },
    '& > :nth-child(4)': {
      width: '25%',
    },
  },
}));
