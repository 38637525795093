import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import { useStyle } from './CalendarHeader.style';

export const CalendarHeader: FC<{ date: string; activeClass: string }> = ({
  date,
  activeClass,
}) => {
  const { weekHeader } = useStyle();
  const { weekCell, weekDay, weekDate } = useStyle();

  return (
    <Grid
      container
      justify="flex-start"
      direction="row"
      className={`${weekHeader} ${activeClass}`}
    >
      <div className={weekCell}>
        <div className={weekDay}>
          {moment(date).format('ddd').toUpperCase()}
        </div>
        <div className={weekDate}>{moment(date).format('DD')}</div>
      </div>
    </Grid>
  );
};
