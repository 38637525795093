import React from 'react';

import { useLoginButtonStyles } from './LoginButtonStyle';

type LoginButtonProps = {
  onClick: () => void;
};

export default function LoginButton<LoginButtonProps>({ onClick }) {
  const { loginButton } = useLoginButtonStyles();
  return (
    <img
      data-cy="login-button"
      className={loginButton}
      onClick={onClick}
      src={require('./ms.svg')}
      alt="Ms Auth Btn"
    />
  );
}
