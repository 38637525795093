import { makeStyles } from '@material-ui/core/styles';

export const useHeaderStyles = makeStyles((theme) => ({
  header: {
    // TODO check if we need to adjust the left & right padding
    // after the new board views are merged
    padding: '32px 0 12px 0',
    maxWidth: theme.breakpoints.values.lg,
    margin: '0 auto',
    borderBottom: '1px solid #F0F0F0',
    background: 'white',
  },
  toolbar: {
    width: '100%',
    justifyContent: 'space-between',
    '& .toolbarButtons': {
      display: 'flex',
      width: '25%',
      justifyContent: 'flex-end',
    },
  },
  logo: {
    width: '351px',
  },
  title: {
    alignContent: 'start',
    color: theme.palette.primary.dark,
    fontSize: '1.5rem',
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
  },
  accountCircle: {
    height: '48px',
    width: '48px',
    borderRadius: '50%',
  },
  logoutButton: {
    display: 'block',
    color: theme.palette.primary.main,
    fontSize: 18,
    fontWeight: 700,
    border: 'none',
    marginTop: '12px',
  },
  feedbackButton: {
    color: theme.palette.primary.main,
    fontSize: '16px',
    flexDirection: 'column',
    display: 'flex',
    fontWeight: 600,
    marginTop: '12px',
    marginRight: '30px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '& svg': {
      width: '20px',
      marginBottom: '5px',
    },
  },
  userAvatar: {
    borderRadius: '50%',
    border: `1px solid ${theme.palette.common.white}`,
    height: '25px',
    width: '25px',
    fontSize: '12px',
    margin: 'auto auto',
    textAlign: 'center',
    paddingTop: '1px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
}));
