import _ from 'lodash';
import { initialBoardState, useBoardContext } from 'store/BoardProvider';

import {
  filterInitiativesByCategories,
  filterInitiativesByLocation,
  filterInitiativesBySearchTerm,
} from 'utils/initiativeUtils';

export const useFilteredInitiatives = (initiatives: Initiative[]) => {
  const { searchTerm, boardFilters } = useBoardContext();
  const { categories, location } = boardFilters;

  let filteredInitiatives = initiatives;

  if (searchTerm) {
    filteredInitiatives = filterInitiativesBySearchTerm(
      filteredInitiatives,
      searchTerm
    );
  }

  if (categories.length) {
    filteredInitiatives = filterInitiativesByCategories(
      filteredInitiatives,
      categories
    );
  }

  if (location.length !== initialBoardState.boardFilters.location.length) {
    filteredInitiatives = filterInitiativesByLocation(
      filteredInitiatives,
      location
    );
  }

  _.orderBy(filteredInitiatives, ['_id']);

  return filteredInitiatives;
};
