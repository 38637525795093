export const initialEventFormData: EventFormData = {
  title: '',
  initiative: null,
  description: '',
  office: '',
  startDate: null,
  startTime: null,
  endTime: null,
  remotelyAccessible: false,
  generateRemoteLink: false,
  remoteLink: '',
  categories: [],
  image: null,
  newAttendeeIds: [],
};

export const initialInitiativeFormData: InitiativeFormData = {
  title: '',
  description: '',
  office: '',
  slackChannel: '',
  remoteLink: '',
  categories: [],
};
