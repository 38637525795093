import React, { FC, HTMLAttributes } from 'react';
import {
  Dialog,
  DialogContent,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { useDialogStyle } from './DialogStyle';

type DialogBaseProps = {
  open: boolean;
  onClose: () => void;
  title?: string;
  width?: 'lg' | 'md' | 'sm' | 'xl' | 'xs';
};

export const DialogBase: FC<
  DialogBaseProps & HTMLAttributes<HTMLDivElement>
> = ({ open, onClose, width, children, className }) => {
  const classes = useDialogStyle();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth
      maxWidth={width || 'sm'}
    >
      <DialogContent classes={{ root: className || classes.dialogContent }}>
        {children}
      </DialogContent>
    </Dialog>
  );
};
