import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
  eventWrapper: {
    display: 'block',
    borderRadius: '4px',
    padding: '8px 4px 6px 9px',
    borderTop: '1px solid #E0E0E0',
    borderRight: '1px solid #E0E0E0',
    width: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.common.white,
    borderLeft: `5px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.baseText,
    cursor: 'pointer',
  },
  eventDuration15: {
    position: 'relative',
    height: '36px',
    display: 'flex',
    marginBottom: '35px',
  },
  eventDuration60: {
    position: 'relative',
    minHeight: '144px',
    marginBottom: '-72px',
  },
  eventDuration90: {
    position: 'relative',
    minHeight: '180px',
    marginBottom: '-108px',
    borderBottom: '1px solid #E0E0E0',
  },
  eventDuration120: {
    position: 'relative',
    minHeight: '288px',
    marginBottom: '-215px',
  },
  eventHeader: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.primary.baseText,
    display: 'inline',
  },
  eventOffice: {
    paddingTop: '3px',
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '12px',
    lineHeight: '16px',
  },
  eventIconWrapper: {
    marginLeft: '10px',
    borderRadius: '50%',
    fontSize: '18px',
    padding: '1px 2px 1px 1px',
  },
  eventIcon: {
    color: theme.palette.common.white,
    paddingTop: '6px',
    paddingLeft: '2px',
    width: '17px',
    height: '17px',
  },
  eventTitle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: '12px',
    lineHeight: '16px',
    color: theme.palette.primary.baseText,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  emptyWrapper: {
    minHeight: '72px',
    borderRadius: '1px',
    backgroundColor: theme.palette.common.white,
    borderLeft: 'none',
  },
}));
