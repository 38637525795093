import React, { FC } from 'react';
import { Calendar } from 'react-feather';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import classNames from 'classnames';

import { useFormfieldStyle } from './GalleryFormfield.style';

type GalleryFormDatePickerProps = {
  name: string;
  label: string;
  inactive: boolean;
  placeholder: string;
  disablePast: boolean;
  shouldDisableDate?: (date: MaterialUiPickersDate) => boolean;
  error?: string;
  value: string;
  onChange: (value: MaterialUiPickersDate) => void;
};

export const GalleryFormDatePicker: FC<GalleryFormDatePickerProps> = ({
  name,
  label,
  placeholder,
  disablePast,
  shouldDisableDate,
  error,
  value,
  onChange,
}) => {
  const classes = useFormfieldStyle();

  return (
    <KeyboardDatePicker
      name={name}
      value={value}
      label={label}
      placeholder={placeholder}
      inputVariant="outlined"
      format="DD.MM.YYYY"
      autoOk={true}
      okLabel={null}
      cancelLabel={null}
      disablePast={disablePast}
      shouldDisableDate={shouldDisableDate}
      keyboardIcon={<Calendar />}
      className={classNames(classes.inputField, classes.inputWithAdornment)}
      InputLabelProps={{
        shrink: true,
      }}
      error={!!error}
      helperText={error}
      onChange={onChange}
      InputAdornmentProps={{ position: 'start', variant: 'standard' }}
      KeyboardButtonProps={{
        className: classes.noPaddingField,
        name: 'datePicker-popup',
      }}
    />
  );
};
