import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMsal } from 'store/MsalProvider';

import { ROUTES } from 'constants/endpoints';
import { GO_TO_AFTER_LOGIN } from 'constants/localStorage';
import { login } from 'services/AuthenticationService';

import { useLoaderStyles } from './LoaderStyle';

export const AuthCallback: FC = () => {
  const { loadContainer } = useLoaderStyles();

  const [error, setError] = useState(null);

  const history = useHistory();

  const { idToken } = useMsal();

  useEffect(() => {
    const onSuccess = () => {
      const goToAfterLogin = localStorage.getItem(GO_TO_AFTER_LOGIN);

      history.replace(goToAfterLogin || '/events');
      localStorage.removeItem(GO_TO_AFTER_LOGIN);
    };

    const onError = (error) => setError(error.message || 'unknown');
    if (idToken.length > 0) {
      login(idToken).then(onSuccess).catch(onError);
    }
  }, [idToken, history]);

  return (
    <div data-cy="load-container" className={loadContainer}>
      {!error ? (
        <>
          <CircularProgress size="10em" />
          <div>Logging in</div>
        </>
      ) : (
        <>
          <div>Error while logging in.</div>
          <Link to={ROUTES.LOGIN}>Retry</Link>
          <div>Error message: {error}</div>
        </>
      )}
    </div>
  );
};
