import React, { FC, useState } from 'react';
import { Trash } from 'react-feather';
import { Typography } from '@material-ui/core';

import { AlertDialog } from 'components/UI';

import { useInitiativeDeleteButtonStyles } from './InitiativeDeleteButton.style';

type InitiativeEditButtonProps = {
  onClick: () => void;
  dialogMessage: string;
  tooltipText?: string;
  className?;
  disabled?;
};

const InitiativeDeleteButton: FC<InitiativeEditButtonProps> = ({
  onClick,
  dialogMessage,
}) => {
  const [deleteDialog, setDeleteDialog] = useState(false);
  const {
    deleteButton,
    deleteIcon,
    deleteText,
  } = useInitiativeDeleteButtonStyles();
  return (
    <>
      <div
        onClick={() => setDeleteDialog(!deleteDialog)}
        className={deleteButton}
      >
        <Trash className={deleteIcon} />
        <Typography variant="button" className={deleteText}>
          Delete
        </Typography>
      </div>
      <AlertDialog
        open={deleteDialog}
        title={dialogMessage}
        onClick={() => {
          setDeleteDialog(!deleteDialog);
          onClick();
        }}
        onCancel={() => setDeleteDialog(!deleteDialog)}
      />
    </>
  );
};

export default InitiativeDeleteButton;
