import ReactGA from 'react-ga';

import { GalleryTab } from 'components/BoardTabs';

const TRACKING_ID = process.env.REACT_APP_ANALYTICS_TRACKING_ID;

const initializeGoogleAnalytics = (trackingId: string) => {
  ReactGA.initialize(trackingId, {
    gaOptions: {
      siteSpeedSampleRate: 100,
    },
  });
  ReactGA.set({ anonymizeIp: true });
};

export const initializeAnalytics = () => {
  if (TRACKING_ID === undefined) {
    console.log('Tracking not configured.');
  } else {
    initializeGoogleAnalytics(TRACKING_ID);
  }
};

// export const trackPageView = () => {
//   ReactGA.pageview(window.location.pathname + window.location.search);
// };

export const trackEvent = (action: string) => {
  ReactGA.event({
    category: 'User',
    action,
  });
};

export const trackEventWithLabel = (action: string, label: string) => {
  ReactGA.event({
    category: 'User',
    action,
    label,
  });
};

export const trackDetailedEvent = (category: string, action: string) => {
  ReactGA.event({
    category,
    action,
  });
};

export const trackTabChange = (value: GalleryTab) => {
  if (value === GalleryTab.Events) {
    trackEvent('Events Tab');
  } else if (value === GalleryTab.Initiatives) {
    trackEvent('Initiatives Tab');
  }
};
