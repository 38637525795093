import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles(() => ({
  weekCell: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    position: 'relative',
    height: '142px',
    width: '60px',
  },
  positionRight: {
    justifyContent: 'flex-end',
  },
  timeSlotsWrapper: {
    marginTop: '-40px',
    width: '60px',
  },
}));
