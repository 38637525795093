import { makeStyles } from '@material-ui/core/styles';

export const useDialogStyle = makeStyles((theme) => ({
  dialogContent: {
    padding: '10px',
    background: theme.palette.common.white,
    display: 'flex',
    flexDirection: 'column',
  },
  eventDialog: {
    padding: '10px',
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },
  initiativeDialog: {
    padding: '10px',
    background: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',
  },
}));
