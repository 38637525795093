import React, { FC, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Redirect } from 'react-router';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { BoardProvider } from 'store/BoardProvider';
import { useMsal } from 'store/MsalProvider';

import { AuthCallback } from 'components/AuthCallback';
import { GalleryTab } from 'components/BoardTabs';
import { EventsPage } from 'components/EventsPage';
import { Header } from 'components/Header';
import { InitiativesPage } from 'components/InitiativesPage';
import { Login } from 'components/Login';
import { NotFoundPage } from 'components/NotFoundPage';
import { PrivateRoute } from 'components/PrivateRoute';
import { ROUTES } from 'constants/endpoints';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'constants/fonts.css';

import { AppTheme } from './AppTheme';

moment.updateLocale('en', {
  week: {
    dow: 1, // week should start on Monday
  },
});

export const App: FC = () => {
  const { mounted } = useMsal();

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={AppTheme}>
        <CssBaseline />
        {mounted && (
          <BrowserRouter basename={process.env.PUBLIC_URL || '/'}>
            <Analytics />
            <Switch>
              <Redirect exact={true} from="/" to={`/${GalleryTab.Events}`} />
              <Redirect
                exact={true}
                from={`/${GalleryTab.Events}`}
                to={`/${GalleryTab.Events}/grid`}
              />
              <Route path={ROUTES.AUTH_CALLBACK} component={AuthCallback} />
              <Route path={ROUTES.LOGIN} component={Login} />
              <BoardProvider>
                <Route component={Header} />
                <Switch>
                  <PrivateRoute
                    path="/events/:viewType/:id?"
                    component={EventsPage}
                  />
                  <PrivateRoute
                    path="/initiatives/:id?"
                    component={InitiativesPage}
                  />
                  <PrivateRoute component={NotFoundPage} />
                </Switch>
              </BoardProvider>
            </Switch>
          </BrowserRouter>
        )}
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  );
};

const Analytics: FC = () => {
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });
  }, [history]);

  return <></>;
};
