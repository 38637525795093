import React, { FC, useState } from 'react';
import { Button, CircularProgress, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useEvent } from 'hooks';
import { useErrorableAction } from 'hooks/useErrorableAction';
import { inviteEvent } from 'store/reducers/events';

import { officeDictionary } from 'constants/offices';
import { outlookSource } from 'constants/sources';
import { getSession } from 'services/AuthenticationService';
import { inviteGalleryEvent } from 'services/GalleryEventService';

import { useStyles } from './AddToCalendarButton.style';
import { StyledTooltip } from './Tooltip';

function getButtonTitle(
  cannotSendInvite: boolean,
  eventIsOver: boolean,
  isInviteSent: boolean,
  cannotEditPersonalEvent: boolean
): string {
  if (cannotSendInvite) {
    if (eventIsOver) {
      return 'Invites for past events cannot be sent.';
    } else if (!!isInviteSent) {
      return 'An invite for this event was already sent.';
    } else if (cannotEditPersonalEvent) {
      return 'This feature is not supported. Events that are synced from personal calendars do not support all features yet. ';
    }
  }

  return '';
}
export const AddToCalendarButton: FC<{ galleryEvent: GalleryEvent }> = ({
  galleryEvent,
}) => {
  const classes = useStyles();
  const session = getSession();
  const userId = session !== undefined && session._id;
  const { event: galleryEventDetail } = useEvent(galleryEvent._id);

  const [invitationSuccess, setInvitationSuccess] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { submit: submitInvite } = useErrorableAction(
    inviteGalleryEvent,
    inviteEvent
  );

  if (galleryEventDetail === undefined) return null;

  const eventOwnerIsOffice =
    galleryEventDetail.owner == null
      ? false
      : Object.values(officeDictionary).find(
          (entry) => entry.outlookId === galleryEventDetail.owner.outlookId
        );
  const eventComesFromOutlook = galleryEvent.source === outlookSource;

  const cannotEditPersonalEvent = eventComesFromOutlook && !eventOwnerIsOffice;

  const isInviteSent =
    galleryEventDetail.attendees?.find((x) => x?.attendee?._id === userId) !==
    undefined;
  const eventIsOver =
    new Date(galleryEventDetail.endTime) <= new Date(Date.now());
  const cannotSendInvite =
    eventIsOver || !!isInviteSent || cannotEditPersonalEvent;

  const title = getButtonTitle(
    cannotSendInvite,
    eventIsOver,
    isInviteSent,
    cannotEditPersonalEvent
  );

  const addToCalendar = async (galleryEvent: GalleryEvent) => {
    setIsLoading(true);

    if (!galleryEvent) return Error;
    const attendeeIds = [userId];
    const { success } = await submitInvite(
      { attendeeIds },
      galleryEvent._id
    ).catch(() => ({ success: false }));

    setOpen(true);
    setInvitationSuccess(success);
    setIsLoading(false);
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  const AlertBox = (success: boolean) => {
    if (success) {
      return (
        <Alert severity="success">Invitation is sent to your inbox!</Alert>
      );
    }
    return <Alert severity="error">Oops, something went wrong!</Alert>;
  };

  return (
    <div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        {AlertBox(invitationSuccess)}
      </Snackbar>
      <StyledTooltip title={title} placement="top">
        <div className={classes.buttonContainer}>
          <div style={{ position: 'relative' }}>
            <Button
              id="AddToCalendarButton"
              title={title}
              className={classes.button}
              disabled={cannotSendInvite || isLoading}
              variant="contained"
              color="primary"
              onClick={() => {
                addToCalendar(galleryEventDetail);
              }}
            >
              Add to my calendar
            </Button>
            {isLoading && (
              <CircularProgress className={classes.loadingCircle} size={24} />
            )}
          </div>
        </div>
      </StyledTooltip>
    </div>
  );
};
