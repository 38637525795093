import React, { createContext, Dispatch, useContext, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import qs from 'query-string';

import { GalleryTab } from 'components/BoardTabs';
import { Category } from 'components/Category/categories';
import { OfficeTypeAll } from 'constants/officeType';
import { AlertMessage } from 'types/AlertMessage';
import { AlertType } from 'types/AlertType';
import { BoardFilterState } from 'types/Filters';
import { capitalizeFirstLetter } from 'utils/urlUtils';

import { boardStateReducer } from './reducers';

export type BoardState = {
  loading: boolean;
  error: Error | null;
  galleryEvents: { [key: string]: GalleryEvent };
  galleryDetailedEvents: { [key: string]: GalleryEventDetails };
  eventGroupDictionary: { [key: string]: GalleryEventDetails[] };
  initiatives: { [key: string]: Initiative };
  searchTerm: string;
  activeTab: GalleryTab;
  alertMessage: AlertMessage;
  boardFilters: BoardFilterState;
};

type BoardContextProps = {
  state: BoardState;
  dispatch: Dispatch<Action>;
};

export const initialBoardState: BoardState = {
  loading: true,
  error: null,
  galleryEvents: {},
  galleryDetailedEvents: {},
  eventGroupDictionary: {},
  initiatives: {},
  searchTerm: '',
  activeTab: GalleryTab.Events,
  alertMessage: {
    type: AlertType.success,
    message: '',
    eventId: undefined,
    open: false,
  },
  boardFilters: {
    location: [],
    startDate: moment().startOf('isoWeek'), // startDate: moment().startOf('d'),
    endDate: moment().endOf('isoWeek'), // endDate: moment().add(7, 'd'),
    officeType: OfficeTypeAll.All,
    categories: [],
    isDateRangeSet: false,
  },
};

// expects the input to be a string that might be a comma separated list of values
// can also be only one value or undefined if the parameter is not set
const arrayFromSearchString = (searchParam) => {
  if (searchParam && typeof searchParam === 'string') {
    const paramArray = searchParam.toLowerCase().split(',');
    const valueArray = paramArray.map((value) => capitalizeFirstLetter(value));
    return valueArray;
  }
  return [];
};

// Location and Categories filters are stored in the URL's search params
// when the app starts, the values from the URl are read and added to the initial state
const getInitialFiltersFromUrl = (search) => {
  const queryParams = qs.parse(search);
  const location: string[] = arrayFromSearchString(queryParams.locations);
  const categories: Category[] = arrayFromSearchString(queryParams.tags);

  return { location, categories };
};

export const BoardContext = createContext({} as BoardContextProps);

export const BoardProvider = ({ children }) => {
  const { search } = useLocation();
  const { location, categories } = getInitialFiltersFromUrl(search);

  const initialState = {
    ...initialBoardState,
    boardFilters: {
      ...initialBoardState.boardFilters,
      categories,
      location,
    },
  };
  const [state, dispatch] = useReducer(boardStateReducer, initialState);
  const { Provider } = BoardContext;

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export const useBoardContext = () => {
  const { state, dispatch } = useContext(BoardContext);

  return {
    ...state,
    dispatch,
  };
};
