import { makeStyles } from '@material-ui/core/styles';

export const useTooltipStyles = makeStyles((theme) => ({
  tooltip: {
    background: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
  },
  arrow: {
    color: theme.palette.primary.main,
  },
}));
