import moment from 'moment';

export const joinDateAndTime = (date, time, endTime = true) => {
  const timeObject = moment(date).clone();

  timeObject.set({
    hour: moment(time).hour(),
    minute: moment(time).minute(),
  });

  if (endTime && moment(date).isAfter(timeObject)) {
    timeObject.add(1, 'days');
  }

  return timeObject;
};
