import React, { FC } from 'react';
import { XCircle as Close } from 'react-feather';
import { TextField } from '@material-ui/core';
import { Autocomplete, AutocompleteRenderInputParams } from '@material-ui/lab';
import classNames from 'classnames';
import { Field } from 'formik';

import { useGalleryFormStyle } from './GalleryForm.style';
import { useFormfieldStyle } from './GalleryFormfield.style';

type GalleryFormAutocompleteProps<T> = {
  value: T;
  inactive?: boolean;
  freeSolo?: boolean;
  name: string;
  placeholder?: string;
  options: T;
  setField: (name: string, value: T) => void;
  error?: string;
  getOptionLabel?: (option: T) => string;
  getOptionSelected?: (option: T, value: T) => boolean;
  InputProps?: {
    startAdornment: JSX.Element;
  };
  renderOption?: (option: T) => JSX.Element;
  label?: string;
  required?: boolean;
  onInputChange?: (event: React.SyntheticEvent, value: string) => void;
  resetError?: () => void;
};

// We do not want to use any here, but FC generic props have to be provided at initialization.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const GalleryFormAutocomplete: FC<GalleryFormAutocompleteProps<any>> = ({
  value,
  name,
  placeholder,
  options,
  setField,
  error,
  resetError,
  getOptionLabel,
  getOptionSelected,
  onInputChange,
  InputProps,
  children,
  label,
  required,
  freeSolo,
  renderOption,
}) => {
  const classes = useGalleryFormStyle();
  const formfieldClasses = useFormfieldStyle();

  return (
    <>
      <Field
        id={name}
        component={Autocomplete}
        autoHighlight
        autoComplete
        blurOnSelect
        options={options}
        freeSolo={freeSolo}
        getOptionLabel={getOptionLabel}
        getOptionSelected={getOptionSelected}
        value={value || null} // Whenever the value is '' we want Autocomplete to use null instead, so it doesn't show the "clear chip".
        onChange={(_, newValue) => {
          resetError && resetError();
          setField(name, newValue || '');
        }}
        onInputChange={onInputChange}
        className={classNames(
          formfieldClasses.inputField,
          classes.autocompleteField
        )}
        closeIcon={<Close />}
        classes={{ paper: classes.dropdownStyle }}
        renderOption={renderOption}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            error={!!error}
            helperText={error}
            variant="outlined"
            className={
              InputProps?.startAdornment
                ? formfieldClasses.inputWithAdornment
                : ''
            }
            placeholder={placeholder}
            required={required}
            label={label}
            InputProps={{ ...params.InputProps, ...InputProps }}
          />
        )}
        placeholder={placeholder}
        label={label}
      />
      {children}
    </>
  );
};
