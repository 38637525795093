import React, { ComponentType, FC, ReactNode } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
  useLocation,
} from 'react-router-dom';

import { ROUTES } from 'constants/endpoints';
import { GO_TO_AFTER_LOGIN } from 'constants/localStorage';
import { isLoggedIn } from 'services/AuthenticationService';

type PrivateRouteProps = {
  component?: ComponentType<RouteComponentProps<{}>>;
  children?: ReactNode;
};

export const PrivateRoute: FC<RouteProps & PrivateRouteProps> = ({
  component: Component,
  children,
  ...rest
}) => {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isLoggedIn()) {
          if (location.pathname !== '/') {
            localStorage.setItem(GO_TO_AFTER_LOGIN, location.pathname);
          }
          return <Redirect to={ROUTES.LOGIN} />;
        }
        if (Component) {
          return <Component {...props} />;
        }
        return children;
      }}
    />
  );
};
