import { ENDPOINT_GALLERY_EVENTS, ENDPOINT_UPLOAD } from 'constants/endpoints';
import { authorizedFetch, HttpMethod } from 'services/DataService';
import { authorizedForm } from 'services/DataService/DataService';

export const getGalleryEvents = async (
  queryParams: string
): Promise<GalleryEvent[]> => {
  return authorizedFetch<GalleryEvent[]>({
    endpoint: `${ENDPOINT_GALLERY_EVENTS}${queryParams}`,
    httpMethod: HttpMethod.GET,
  });
};

export const getGalleryEvent = async (
  id: GalleryEventDetails['_id']
): Promise<GalleryEventDetails> => {
  return authorizedFetch<GalleryEventDetails>({
    endpoint: `${ENDPOINT_GALLERY_EVENTS}/${id}`,
    httpMethod: HttpMethod.GET,
  });
};

export const updateGalleryEvent = async (
  updatedGalleryEvent,
  eventId: string
): Promise<GalleryEventDetails> => {
  return authorizedFetch<GalleryEventDetails>({
    endpoint: `${ENDPOINT_GALLERY_EVENTS}/${eventId}`,
    httpMethod: HttpMethod.PUT,
    body: updatedGalleryEvent,
  });
};

export const createGalleryEvent = async (
  galleryEvent
): Promise<GalleryEvent> => {
  return authorizedFetch<GalleryEvent>({
    endpoint: `${ENDPOINT_GALLERY_EVENTS}`,
    httpMethod: HttpMethod.POST,
    body: galleryEvent,
  });
};

export const deleteGalleryEvent = async (eventId: string): Promise<string> => {
  return authorizedFetch<{ ok: number }>({
    endpoint: `${ENDPOINT_GALLERY_EVENTS}/${eventId}`,
    httpMethod: HttpMethod.DELETE,
  }).then((response) => {
    if (!response.ok) throw new Error('Unknown Database error');
    return eventId;
  });
};

export const createGalleryEventImage = async (
  image: File
): Promise<GalleryImage> => {
  const formData = new FormData();
  formData.append('file', image);
  formData.append('type', 'image');
  return authorizedForm<GalleryImage>({
    endpoint: `${ENDPOINT_UPLOAD}`,
    httpMethod: HttpMethod.POST,
    body: formData,
  });
};

export const inviteGalleryEvent = async (
  attendeeIds,
  eventId: string
): Promise<GalleryEvent> => {
  return authorizedFetch<GalleryEvent>({
    endpoint: `${ENDPOINT_GALLERY_EVENTS}/${eventId}`,
    httpMethod: HttpMethod.PATCH,
    body: attendeeIds,
  });
};
