import React, { FC } from 'react';
import moment from 'moment';

import { useStyles } from './EventGridCardDate.style';

function formatDate(date: string) {
  const parsedDate = moment(date);
  const formatString = (textToFormat: string) =>
    textToFormat.substring(0, 3).toUpperCase();
  const dayOfWeek = formatString(parsedDate.format('dddd'));
  const day = parsedDate.format('D');
  const month = formatString(parsedDate.format('MMMM'));
  return { day, dayOfWeek, month };
}
export const EventGridCardDate: FC<{ date: string }> = ({ date }) => {
  const classes = useStyles();
  const { day, dayOfWeek, month } = formatDate(date);

  return (
    <div className={classes.cardHeaderDateContainer}>
      <div className={classes.dateLight}>{dayOfWeek}</div>
      <div className={classes.dateBold}>{day}</div>
      <div className={classes.dateLight}>{month}</div>
    </div>
  );
};
