import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useFilteredInitiatives } from 'hooks';

import { InitiativeCard } from 'components/InitiativeCard';

export const InitiativesGalleryContainer: FC<{ initiatives: Initiative[] }> = ({
  initiatives,
}) => {
  const filteredInitiatives = useFilteredInitiatives(initiatives);

  return (
    <>
      <Grid container direction="row" spacing={2}>
        {filteredInitiatives.map((initiative: Initiative) => (
          <Grid item key={initiative._id} xs={12} sm={12} md={6}>
            <InitiativeCard initiative={initiative} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
