import React, { FC } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useAlertDialogStyle } from './AlertDialogStyle';

type AlertDialogProps = {
  open: boolean;
  title: string;
  onCancel: () => void;
  onClick: () => void;
};

export const AlertDialog: FC<AlertDialogProps> = ({
  open,
  title,
  onCancel,
  onClick,
}) => {
  const classes = useAlertDialogStyle();
  return (
    <Dialog open={open} classes={{ paper: classes.dialogBox }}>
      <DialogTitle
        classes={{ root: classes.dialogTitle }}
        disableTypography={true}
      >
        {title}
      </DialogTitle>
      <DialogActions
        classes={{ root: classes.buttonContainer }}
        disableSpacing={true}
      >
        <Button classes={{ root: classes.cancelButton }} onClick={onCancel}>
          Cancel
        </Button>
        <Button classes={{ root: classes.deleteButton }} onClick={onClick}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};
