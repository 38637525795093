import React, { FC } from 'react';
import { useEvent } from 'hooks';
import { useErrorableAction } from 'hooks/useErrorableAction';
import moment from 'moment';
import { useBoardContext } from 'store/BoardProvider';
import { showSuccessEventAlert } from 'store/reducers/alerts';
import { updateEvent } from 'store/reducers/events';

import { EventForm } from 'components/EventForm';
import { updateGalleryEvent } from 'services/GalleryEventService';
import { joinDateAndTime } from 'utils/dateUtils';
import { attendeesToIds } from 'utils/userUtils';

type EventFormEditContainerProps = {
  id: GalleryEvent['_id'];
  onCancel: () => void;
};

const isProduction = process.env.REACT_APP_ENV === 'production';

export const EventFormEditContainer: FC<EventFormEditContainerProps> = ({
  id,
  onCancel,
}) => {
  const { event: galleryEvent } = useEvent(id);
  const { dispatch } = useBoardContext();

  const { ErrorComponent, submit } = useErrorableAction(
    updateGalleryEvent,
    updateEvent
  );

  if (!galleryEvent) return null;

  const onSubmit = (galleryEvent: GalleryEventDetails) => {
    return async (values: EventFormData, { setSubmitting }) => {
      const updatedEvent = {
        title: values.title,
        description: values.description,
        startTime: moment(values.startTime).toISOString(),
        endTime: joinDateAndTime(
          values.startTime,
          values.endTime
        ).toISOString(),
        office: isProduction ? values.office : 'Test Office',
        remotelyAccessible: values.remotelyAccessible,
        generateRemoteLink: values.generateRemoteLink,
        remoteLink: values.remotelyAccessible ? values.remoteLink : '',
        categories: values.categories,
        initiative: values.initiative?._id,
        image: values.image,
        newAttendeeIds:
          values.newAttendeeIds ?? attendeesToIds(galleryEvent.attendees),
      };
      const { success, entity } = await submit(updatedEvent, galleryEvent._id);
      setSubmitting(false);
      if (success) onCancel();
      if (entity) {
        dispatch(showSuccessEventAlert('updated successfully!', entity._id));
      }
    };
  };

  return (
    <>
      <EventForm
        title="Edit Event"
        galleryEvent={{
          ...galleryEvent,
          startDate: moment(galleryEvent.startTime) ?? null,
          image: galleryEvent.image?._id,
          imageUrl: galleryEvent.image?.location,
        }}
        onClickSubmit={onSubmit(galleryEvent)}
        onClickCancel={onCancel}
      ></EventForm>
      <ErrorComponent />
    </>
  );
};
