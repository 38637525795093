import { makeStyles } from '@material-ui/core/styles';

export const useGalleryDetailsStyle = makeStyles(
  (theme) => {
    return {
      cardInfo: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
      },
      cardInfoColor: {
        color: theme.palette.primary.main,
      },
      cardInfoLine: {
        display: 'flex',
        alignItems: 'flex-start',
        paddingBottom: theme.spacing(1),
      },
      cardInfoLineIcon: {
        marginRight: theme.spacing(1),
        fontSize: '1.2rem',
        lineHeight: '1.1',
        color: theme.palette.primary.baseText,
      },
      cardInfoLineIconOutlined: {
        fontFamily: 'Material Icons Outlined',
      },
      cardInfoLineTitleText: {
        marginTop: '3px',
        marginRight: '2px',
        fontWeight: 600,
        color: theme.palette.primary.baseText,
      },
      cardInfoLineText: {
        marginTop: '3px',
        color: theme.palette.primary.baseText,
      },
      cardInfoLinkActive: {
        color: '#6559FF',
        fontWeight: 'bold',
      },
      cardInfoLinkInactive: {
        color: '#C2C2C2',
      },
    };
  },
  { index: 1 }
);
