import { useEffect } from 'react';
import { useBoardContext } from 'store/BoardProvider';
import { setAllEvents } from 'store/reducers/events';
import { setAllInitiatives } from 'store/reducers/initiatives';

import { getGalleryEvents } from 'services/GalleryEventService';
import { getInitiatives } from 'services/InitiativeService';
import { xor } from 'utils/logicUtils/logic';
import { encodeURL } from 'utils/urlUtils';

import { useFetch } from './useFetch';
import { usePageVisibility } from './usePageVisiblity';

export const useFetchAllEvents = (initialLoad = true) => {
  const { galleryEvents, boardFilters } = useBoardContext();
  const queryParams = encodeURL({
    startDate: boardFilters.startDate.clone().format(),
    endDate: boardFilters.endDate.clone().format(),
  });

  const { loading, error, load } = useFetch<GalleryEvent[]>(
    queryParams,
    getGalleryEvents,
    setAllEvents
  );

  const doLoad = xor(!usePageVisibility(), initialLoad);
  useEffect(() => {
    doLoad && load();
    // disable dependency on "doLoad" since this effect should only run at page load - once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  return { galleryEvents, loading, error, load };
};

export const useFetchAllInitiatives = (initialLoad = true) => {
  const { initiatives } = useBoardContext();
  const { loading, error, load } = useFetch<Initiative[]>(
    undefined,
    getInitiatives,
    setAllInitiatives
  );

  const doLoad = xor(!usePageVisibility(), initialLoad);
  useEffect(() => {
    doLoad && load();
    // disable dependency on "doLoad" since this effect should only run at page load - once.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [load]);

  return { initiatives, loading, error, load };
};
