import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  menu: {
    minWidth: 90,
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
  },
  icon: {
    padding: '12px 5px',
    '&:hover': {
      backgroundColor: theme.palette.common.white,
    },
  },
  viewChangeWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    marginRight: '10px',

    '& svg': {
      color: theme.palette.text.disabled,
    },

    '&:hover': {
      color: theme.palette.primary.main,
    },

    '& a:first-child': {
      paddingRight: '20px',
    },
  },
  navLinkActive: {
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
}));
