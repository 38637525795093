import { makeStyles } from '@material-ui/core/styles';

export const useFilterRowStyles = makeStyles((theme) => ({
  boardFiltersContainer: {
    position: 'relative',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  filterField: {
    display: 'flex',
    justifyContent: 'space-evenly',
    maxWidth: '35%',

    '& .MuiSelect-icon': {
      color: theme.palette.primary.main,
    },
    marginRight: theme.spacing(2),
    '&:last-child': {
      marginRight: '0px',
    },
    '& .MuiInputBase-root': {
      cursor: 'pointer',
    },
    '& fieldset': {
      borderColor: theme.palette.text.disabled,
    },
    '& .MuiInputBase-root.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      border: '1px solid',
    },
    '&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.primary.main,
      border: '1px solid',
    },
    '& .MuiOutlinedInput-input': {
      cursor: 'pointer',
      color: theme.palette.text.primary,
      fontStyle: 'normal',
      '&::placeholder': {
        color: theme.palette.text.primary,
        opacity: 'unset',
      },
    },
    '&:hover .MuiOutlinedInput-notchedOutline': {
      border: `2px solid ${theme.palette.text.disabled}`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.palette.text.disabled,
    },
  },
  filterFieldActive: {
    '& .MuiOutlinedInput-input': {
      color: theme.palette.common.black,
    },
  },
  boxedFilterField: {
    width: '100%',
  },
  selectOption: {
    padding: theme.spacing(0.5, 0, 0.5, 1.5),
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    color: theme.palette.primary.baseText,

    '&.MuiListItem-root': {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row-reverse',
    },

    '&:hover': {
      background: theme.palette.primary.light3,
      color: theme.palette.primary.mediumDark,

      '& .MuiCheckbox-root': {
        color: theme.palette.primary.main,
      },
    },
    '&:active': {
      background: theme.palette.primary.light3,
      color: theme.palette.text.primary,
    },
    '&.MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light3,
      color: '#4A49CB',
    },
    '& span.MuiCheckbox-colorSecondary.Mui-checked.MuiIconButton-colorSecondary': {
      background: theme.palette.primary.light3,
      color: theme.palette.primary.main,
    },
  },
  selectOptionTitle: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: theme.typography.body1.fontWeight,
    fontSize: theme.typography.body1.fontSize,
    lineHeight: theme.typography.body1.lineHeight,
    padding: theme.spacing(2, 1, 0.3, 1.2),
    color: '#939393',
  },
  dateRangeDialog: {},
  clearIcon: {
    marginRight: theme.spacing(1),
    cursor: 'pointer',
  },
}));
