import React, { FC } from 'react';
import { Calendar, Grid } from 'react-feather';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';

import { GalleryTab } from 'components/BoardTabs';

import { useStyles } from './EventsPage.style';

export const EventViewSelector: FC = () => {
  const styles = useStyles();

  return (
    <div className={classNames(styles.viewChangeWrapper)}>
      <NavLink
        to={(location) => ({
          ...location,
          pathname: `/${GalleryTab.Events}/grid`,
        })}
        activeClassName={styles.navLinkActive}
      >
        <Grid />
      </NavLink>
      <NavLink
        to={(location) => ({
          ...location,
          pathname: `/${GalleryTab.Events}/calendar`,
        })}
        activeClassName={styles.navLinkActive}
      >
        <Calendar />
      </NavLink>
    </div>
  );
};
