import React, { FC, useEffect, useState } from 'react';

import { GalleryTab } from 'components/BoardTabs';
import { DialogBase } from 'components/DialogBase';
import {
  EventFormAddContainer,
  EventFormEditContainer,
} from 'components/EventForm';
import {
  InitiativeFormAddContainer,
  InitiativeFormEditContainer,
} from 'components/InitiativeForm';

import { useDialogStyle } from '../DialogBase/DialogStyle';
import { EventDescriptionCard } from '../EventDescriptionCard/EventDescriptionCard';

type GalleryDialogProps = {
  open: boolean;
  onClose: () => void;
  id: string | undefined;
  type: GalleryTab;
};

export const GalleryDialog: FC<GalleryDialogProps> = ({
  open,
  onClose,
  id,
  type,
}) => {
  const [editMode, setEditMode] = useState(false);
  const classes = useDialogStyle();
  useEffect(() => setEditMode(false), [open]);

  if (!id) return null;

  return (
    <>
      {!editMode && id !== 'new' && type === GalleryTab.Events && (
        <DialogBase open={open} onClose={onClose} width="md">
          <EventDescriptionCard
            id={id}
            onEdit={() => setEditMode(true)}
            onClose={onClose}
            onCancel={() => setEditMode(false)}
          />
        </DialogBase>
      )}

      {editMode && type === GalleryTab.Events && (
        <DialogBase
          className={classes.eventDialog}
          open={open}
          onClose={onClose}
          width="md"
        >
          <EventFormEditContainer id={id} onCancel={() => setEditMode(false)} />
        </DialogBase>
      )}

      {id === 'new' && type === GalleryTab.Events && (
        <DialogBase
          className={classes.eventDialog}
          open={open}
          onClose={onClose}
          width="md"
        >
          <EventFormAddContainer onCancel={onClose} />
        </DialogBase>
      )}

      {editMode && type === GalleryTab.Initiatives && (
        <DialogBase
          className={classes.initiativeDialog}
          open={open}
          onClose={onClose}
          width="md"
        >
          <InitiativeFormEditContainer
            id={id}
            onCancel={() => setEditMode(false)}
          />
        </DialogBase>
      )}

      {id === 'new' && type === GalleryTab.Initiatives && (
        <DialogBase
          className={classes.initiativeDialog}
          open={open}
          onClose={onClose}
          width="md"
        >
          <InitiativeFormAddContainer onCancel={onClose} />
        </DialogBase>
      )}
    </>
  );
};
