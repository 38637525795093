import React, { FC } from 'react';
import { PropTypes } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import { StyledTooltip } from 'components/UI';

type ButtonWihTooltipProps = {
  tooltipText: string;
  className?: string;
  round?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  color?: PropTypes.Color;
  onClick?: () => void;
  disabled?: boolean;
  id?: string;
};

export const ButtonWithTooltip: FC<ButtonWihTooltipProps> = ({
  className,
  tooltipText,
  variant,
  color,
  onClick,
  children,
  round,
  disabled,
  id,
}) => {
  return (
    <StyledTooltip title={tooltipText}>
      {disabled ? (
        <span style={{ display: 'block' }}>
          {round ? (
            <IconButton
              classes={{ root: className }}
              color={color || 'primary'}
              disabled={!!disabled && disabled === true}
            >
              {children}
            </IconButton>
          ) : (
            <Button
              id={id}
              className={className}
              color={color || 'primary'}
              variant={variant || 'contained'}
              disabled={!!disabled && disabled === true}
            >
              {children}
            </Button>
          )}
        </span>
      ) : round ? (
        <IconButton
          onClick={onClick}
          classes={{ root: className }}
          color={color || 'primary'}
          disabled={!!disabled && disabled === true}
        >
          {children}
        </IconButton>
      ) : (
        <Button
          id={id}
          onClick={onClick}
          className={className}
          color={color || 'primary'}
          variant={variant || 'contained'}
          disabled={!!disabled && disabled === true}
        >
          {children}
        </Button>
      )}
    </StyledTooltip>
  );
};
