import _ from 'lodash';
import { BoardState } from 'store/BoardProvider';

export enum DataEventActions {
  SET_GALLERY_EVENTS = 'SET_GALLERY_EVENTS',
  SET_GALLERY_EVENT = 'SET_GALLERY_EVENT',
  UPDATE_GALLERY_EVENT = 'UPDATE_GALLERY_EVENT',
  CREATE_GALLERY_EVENT = 'CREATE_GALLERY_EVENT',
  DELETE_GALLERY_EVENT = 'DELETE_GALLERY_EVENT',
  INVITE_GALLERY_EVENT = 'INVITE_GALLERY_EVENT',
}

export const eventsReducer = (state: BoardState, action) => {
  const { type, payload } = action;
  switch (type) {
    case DataEventActions.SET_GALLERY_EVENTS:
      return {
        ...state,
        galleryEvents: _.keyBy(payload || [], '_id'),
        eventGroupDictionary: _.groupBy(payload || [], 'iCalUId'),
      };

    case DataEventActions.SET_GALLERY_EVENT:
      return {
        ...state,
        galleryDetailedEvents: {
          ...state.galleryDetailedEvents,
          [payload._id]: payload,
        },
        galleryEvents: {
          ...state.galleryEvents,
          [payload._id]: payload as GalleryEvent,
        },
        // eventGroupDictionary: {
        //   ...state.eventGroupDictionary,
        //   [payload.iCalUId]: {
        //     ...state.eventGroupDictionary[payload.iCalUId],
        //     payload,
        //   },
        // },
      };
    case DataEventActions.UPDATE_GALLERY_EVENT:
    case DataEventActions.INVITE_GALLERY_EVENT:
    case DataEventActions.CREATE_GALLERY_EVENT:
      return {
        ...state,
        galleryEvents: {
          ...state.galleryEvents,
          [payload._id]: payload,
        },
      };

    case DataEventActions.DELETE_GALLERY_EVENT:
      const next = {
        ...state,
        galleryEvents: {
          ...state.galleryEvents,
        },
      };
      delete next.galleryEvents[payload];
      return next;
    default:
      return state;
  }
};

export const setAllEvents = (events: GalleryEvent[]) => {
  return {
    type: DataEventActions.SET_GALLERY_EVENTS,
    payload: events,
  };
};

export const setEvent = (event: GalleryEventDetails) => {
  return {
    type: DataEventActions.SET_GALLERY_EVENT,
    payload: event,
  };
};

export const createEvent = (event: GalleryEvent) => {
  return {
    type: DataEventActions.CREATE_GALLERY_EVENT,
    payload: event,
  };
};

export const updateEvent = (event: GalleryEvent) => {
  return {
    type: DataEventActions.UPDATE_GALLERY_EVENT,
    payload: event,
  };
};

export const inviteEvent = (attendeeIds: [string]) => {
  return {
    type: DataEventActions.INVITE_GALLERY_EVENT,
    payload: attendeeIds,
  };
};

export const deleteEvent = (eventId: GalleryEvent['_id']) => {
  return {
    type: DataEventActions.DELETE_GALLERY_EVENT,
    payload: eventId,
  };
};
