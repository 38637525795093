import { makeStyles } from '@material-ui/core/styles';

export const useAlertDialogStyle = makeStyles((theme) => ({
  dialogBox: {
    backgroundColor: theme.palette.common.white,
    width: theme.spacing(58),
    padding: `${theme.spacing(5)}px ${theme.spacing(5)}px ${theme.spacing(
      2.5
    )}px ${theme.spacing(5)}px`,
  },
  dialogTitle: {
    fontSize: '1rem',
    fontWeight: 400,
    color: theme.palette.common.black,
    padding: 0,
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
    float: 'right',
    padding: 0,
  },
  cancelButton: {
    fontSize: '0.875rem',
    fontWeight: 500,
    width: theme.spacing(8.75),
    height: theme.spacing(4),
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
  },

  deleteButton: {
    background: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
    border: 'none',
    color: theme.palette.common.white,
    fontSize: '0.875rem',
    width: theme.spacing(8.75),
    height: theme.spacing(4),
    boxShadow: 'none',
    fontWeight: 500,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    marginLeft: theme.spacing(1.5),
  },
}));
