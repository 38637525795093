import { createMuiTheme, ThemeOptions } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';

declare module '@material-ui/core/styles/createPalette' {
  interface CommonColors {
    dark: string;
    main: string;
    light1?: string;
    ligh2?: string;
    light3?: string;
  }

  interface PaletteColor {
    dark: string;
    mediumDark?: string;
    main: string;
    light1?: string;
    light2?: string;
    light3: string;
    light4?: string;
    disabled?: string;
    baseText?: string;
  }

  interface Palette {
    common: CommonColors;
    card: {
      accent: string;
      border: string;
    };
    officeColours: {
      AllOffices: string;
      Berlin: string;
      Copenhagen: string;
      Frankfurt: string;
      Hamburg: string;
      Helsinki: string;
      Munich: string;
      Oslo: string;
      Stockholm: string;
      Zurich: string;
      TestOffice: string;
      NetlightCalendar: string;
    };
  }
  interface PaletteOptions {
    card: {
      accent: string;
      border: string;
    };
  }
}
const palette = {
  background: {
    paper: '#fafafa',
    default: '#fff',
  },
  common: {
    main: '#787878',
    light1: '#c2c2c2',
    light2: '#EFEFEF',
    light3: '#FAFAFA',
  },
  primary: {
    dark: '#2C3F6B',
    mediumDark: '#4A49CB',
    main: '#6559FF',
    light1: '#9D8DFF',
    light2: '#D6D8FF',
    light3: '#ECF0FF',
    lieght4: '#F3F5F8',
    disabled: '#efefef',
    baseText: '#4D4D4D',
  },
  secondary: {
    mediumDark: '#FC4A4A',
    main: '#FF7979',
    light1: '#FFA4A4',
    light2: '#FFC9C9',
    light3: '#FFF8F8',
  },
  tertiary: {
    main: '#FFB04B',
    light1: '#FDBF6D',
    light2: '#FED9A8',
    light3: '#FFE9CC',
    extraLight: '#FFFCF8',
  },
  error: {
    contrastText: '#fff',
    light: '#ff7979',
    main: '#FC4A4A',
  },
  text: {
    primary: '#221F20',
    secondary: '#c2c2c2',
    disabled: '#c4c4c4',
    hint: '#efefef',
  },
  divider: '#C2C2C2',
  card: {
    accent: '#D6D8FF',
    border: '#C2C2C2',
  },
  status: {
    success: '#3BB5A3',
    error: '#FF7979',
    info: '#769DD8',
  },
  officeColours: {
    Berlin: '#FFCECE',
    Copenhagen: '#FFE3C0',
    Frankfurt: '#BFB6F3',
    Hamburg: '#6559FF',
    Helsinki: '#2C3F6B',
    Munich: '#FFA4A4',
    Oslo: '#FF7979',
    Stockholm: '#FC4A4A',
    Zurich: '#C2C2C2',
    TestOffice: '#4D4D4D',
    NetlightCalendar: '#FC9A1A',
  },
};

type MuiOverrides = {
  MuiPickersCalendarHeader?: Record<string, DOMStringMap>;
  MuiPickersDay?: Record<string, DOMStringMap>;
  MuiAlert?: Record<string, DOMStringMap>;
};
type CustomOverrides = Overrides & MuiOverrides;
type CustomThemeOptions = ThemeOptions & { overrides: CustomOverrides };
const customCreateMuiTheme = (options?: CustomThemeOptions) =>
  createMuiTheme(options);

export const AppTheme = customCreateMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 520,
      md: 640,
      lg: 1280,
      xl: 1920,
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette,
  typography: {
    fontFamily: 'Proxima Nova',
    h1: {
      fontWeight: 300,
      fontSize: '52px',
      lineHeight: '64px',
      letterSpacing: '0.2px',
    },
    h2: {
      fontWeight: 500,
      fontSize: '44px',
      lineHeight: '54px',
      letterSpacing: '0px',
    },
    h3: {
      fontWeight: 600,
      fontSize: '32px',
      lineHeight: '40px',
      letterSpacing: '0.1px',
    },
    h4: {
      fontWeight: 500,
      fontSize: '26px',
      lineHeight: '32px',
      letterSpacing: '0.2px',
    },
    h5: {
      fontWeight: 600,
      fontSize: '20px',
      lineHeight: '26px',
      letterSpacing: '0.2px',
    },
    h6: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '24px',
      letterSpacing: '0.2px',
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0.1px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.1px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '22px',
      letterSpacing: '0.1px',
    },
    button: {
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '18px',
      letterSpacing: '0.2px',
    },
  },
  shape: {
    borderRadius: 0,
  },
  overrides: {
    MuiLink: {
      root: {
        color: palette.primary.main,
        fontWeight: 600,
        textDecoration: 'none',
        '& $disabled': {
          opacity: '0.6',
        },
        '&:hover': {
          color: '#1300FF',
        },
        '&:active': {
          color: '#6559FF',
        },
      },
      underlineNone: {
        textDecoration: 'none',
      },
      underlineAlways: {
        textDecoration: 'none',
      },
      underlineHover: {
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'none',
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '1.375rem',
        padding: '6px 16px',
        borderRadius: 8,
        border: `1px solid ${palette.primary.main}`,
        '&:hover': {
          boxShadow: 'none',
        },
        '&:active': {
          boxShadow: 'none',
        },
        '&:focus': {
          boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
      },
      outlined: {
        padding: '6px 16px',
        borderColor: palette.primary.main,
        color: palette.primary.main,
        '&:hover': {
          backgroundColor: 'rgba(44, 63, 107, .2)',
        },
      },
      contained: {
        '&:disabled': {
          backgroundColor: palette.primary.disabled,
          borderColor: palette.primary.disabled,
          color: palette.text.secondary,
        },
      },
    },
    MuiAlert: {
      root: {
        borderRadius: '5px',
      },
      filledSuccess: {
        backgroundColor: palette.status.success,
      },
      filledError: {
        backgroundColor: palette.status.error,
      },
      filledInfo: {
        backgroundColor: palette.status.info,
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: '6px',
      },
    },
    MuiAppBar: {
      root: {
        'box-shadow': 'none',
      },
    },
    MuiCard: {
      root: {
        'box-shadow': 'none',
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '$labelcolor',
        },
      },
    },
    MuiInputBase: {
      root: {
        color: palette.text.secondary,
      },
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#787878',
      },
    },
    MuiPickersDay: {
      dayDisabled: {
        color: palette.text.disabled,
      },
    },
    // Base input field styles
    MuiOutlinedInput: {
      root: {
        fontSize: '1rem',
        borderRadius: '8px',
        '& $input': {
          padding: '0 16px 0 16px',
          lineHeight: '2.75rem',
          height: '44px',
        },
        '& .MuiInputBase-inputMultiline': {
          lineHeight: '1.1875rem',
          height: 'auto',
        },
        '& .MuiSvgIcon-root': {
          fontSize: '1.25rem',
        },
        '& .MuiInputAdornment-positionStart': {
          marginRight: '0',
        },
        '& .MuiInputBase-input': {
          color: palette.text.primary,
        },
      },
    },
    // Styles for input fields with floating label
    MuiTextField: {
      root: {
        '& .MuiInputLabel-outlined': {
          transform: 'translate(14px, 14px) scale(1)',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
          transform: 'translate(14px, -6px) scale(0.75)',
        },
        '& .MuiFormLabel-root': {
          fontSize: '1rem',
        },
      },
    },
    MuiChip: {
      root: {
        width: 'max-content',
        height: '1.5rem',
        backgroundColor: palette.primary.light3,
        borderRadius: '12px',
        border: '1px solid',
        borderColor: palette.primary.light1,
        '&&:hover': {
          backgroundColor: palette.primary.light2,
        },
        '& .MuiChip-label': {
          paddingLeft: '6px',
          paddingRight: '5px',
          fontStyle: 'normal',
          fontWeight: 'normal',
          fontSize: '14px',
          lineHeight: '14px',
          letterSpacing: '0.1px',
          color: palette.primary.baseText,
        },
        '& .MuiChip-deleteIcon': {
          height: '1rem',
          width: '1rem',
          color: '#4D4D4D',
          paddingLeft: '5px',
        },
      },
    },
    MuiListItem: {
      button: {
        '&:hover': {
          backgroundColor: palette.primary.light3,
        },
      },
    },
  },
});
