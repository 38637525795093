import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import { useStyles } from './BoardHeader.style';

interface HeaderComposition {
  Row: React.FC;
}

export const BoardHeader: FC & HeaderComposition = ({ children }) => {
  const styles = useStyles();

  return (
    <div className={styles.container}>
      <Typography variant="h4" className={styles.headline}>
        Let&apos;s synchronize{'\n'}
        and get to know each other!
      </Typography>
      {children}
    </div>
  );
};

const BoardHeaderRow: FC = ({ children }) => {
  const styles = useStyles();

  return <Grid className={styles.row}>{children}</Grid>;
};

BoardHeader.Row = BoardHeaderRow;
