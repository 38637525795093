import React, { FC, useCallback, useState } from 'react';
import { Link as LinkIcon } from 'react-feather';
import { useHistory } from 'react-router-dom';
import {
  Card,
  LinearProgress,
  Link,
  Snackbar,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useErrorableAction } from 'hooks/useErrorableAction';
import { deleteInitiative as deleteInitiativeState } from 'store/reducers/initiatives';

import { GalleryTab } from 'components/BoardTabs/BoardTabs';
import { DialogBase } from 'components/DialogBase';
import { InitiativeFormEditContainer } from 'components/InitiativeForm';
import { LINKS } from 'constants/endpoints';
import { getSession } from 'services/AuthenticationService/AuthenticationService';
import { deleteInitiative } from 'services/InitiativeService';
import { getSlackChannelName } from 'utils/initiativeUtils';
import { getOwnerNameFromUser } from 'utils/userUtils';

import { useStyles } from './InitiativeCard.style';
import InitiativeDeleteButton from './InitiativeDeleteButton';
import InitiativeEditButton from './InitiativeEditButton';
import { InitiativeUpcomingEvents } from './InitiativeUpcomingEvents';
import { ReactComponent as SlackSVG } from './slack.svg';

export const InitiativeCard: FC<{ initiative: Initiative }> = ({
  initiative,
}) => {
  const classes = useStyles();
  const session = getSession();

  const userId = session !== undefined && session._id;
  const userRole = session.role;

  const {
    ErrorComponent: DeleteErrorComponent,
    submit: submitDelete,
  } = useErrorableAction(deleteInitiative, deleteInitiativeState);

  const safeSlackChannelName = getSlackChannelName(initiative?.slackChannel);
  const slackLink = `${LINKS.SLACK_CHANNEL_BASE}${safeSlackChannelName}`;

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);

  const onClose = useCallback(() => {
    setOpen(false);
    setEditMode(false);
  }, []);

  const onEdit = useCallback(() => {
    setEditMode(true);
    setOpen(true);
  }, []);

  const history = useHistory();

  const canEdit = userId === initiative.owner?._id || userRole === 'admin';

  const onCopyClick = () => {
    navigator.clipboard.writeText(`${window.location.href}`);
    setSnackBarOpen(true);
  };
  const handleClose = (_event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBarOpen(false);
  };

  const onDelete = async (initiative: Initiative) => {
    setIsLoading(true);
    const { success } = await submitDelete(initiative._id);
    setIsLoading(false);
    if (success) {
      history.replace(`/${GalleryTab.Initiatives}`);
    }
  };

  const getCategories = (initiative: Initiative) => {
    if (initiative.categories.length === 0) return;
    return (
      <div>
        {initiative.categories.map((categorie, index) => (
          <span className={classes.categories} key={categorie}>
            #{categorie}
            {index !== initiative.categories.length - 1 && <>&nbsp;</>}
          </span>
        ))}
      </div>
    );
  };

  const getInitiativeOwnerText = (owner: User) => {
    if (userId === owner?._id) return 'Me';
    return getOwnerNameFromUser(owner);
  };

  return (
    <>
      <Card data-testid="gallery-card" className={classes.card}>
        {getCategories(initiative)}
        <div className={classes.cardHeader}>
          <Typography className={classes.cardHeaderTitle} variant="h3">
            {initiative.title}
          </Typography>
          <div className={classes.ownerCotainer}>
            <div className={classes.ownerIcon}>
              {getOwnerNameFromUser(initiative.owner)
                .substr(0, 1)
                .toUpperCase()}
            </div>
            <Typography variant="subtitle2" className={classes.cardHeaderOwner}>
              {`Owner: ${getInitiativeOwnerText(initiative.owner)}`}
            </Typography>
          </div>
        </div>
        <div className={classes.descriptionHeader}>
          <Typography className={classes.descriptionHeaderTitle} variant="h5">
            About the initiative
          </Typography>
          {canEdit && (
            <div className={classes.buttonContainer}>
              <InitiativeEditButton onClick={onEdit} />
              <InitiativeDeleteButton
                onClick={() => onDelete(initiative)}
                dialogMessage="Delete Initiative?"
              />
              <DeleteErrorComponent />
            </div>
          )}
        </div>
        <div className={classes.cardContent}>{initiative.description}</div>
        <div className={classes.actionBar}>
          <div className={classes.links} onClick={(e) => e.stopPropagation()}>
            <Link className={classes.linkWrapper} onClick={onCopyClick}>
              <LinkIcon className={classes.linkIcon} />
              <Typography className={classes.link} variant="subtitle1">
                Copy Link
              </Typography>
            </Link>
            <Snackbar
              open={snackBarOpen}
              onClose={handleClose}
              autoHideDuration={3000}
            >
              <Alert severity="success">
                Event&apos;s link copied to the clipboard
              </Alert>
            </Snackbar>
            {safeSlackChannelName && (
              <Link
                className={classes.linkWrapper}
                target="_blank"
                rel="noopener noreferrer"
                href={slackLink}
              >
                <SlackSVG className={classes.linkIcon} />
                <Typography className={classes.link} variant="subtitle1">
                  {safeSlackChannelName}
                </Typography>
              </Link>
            )}
          </div>
        </div>
        {isLoading && <LinearProgress className={classes.loadingIndicator} />}
        <InitiativeUpcomingEvents initiative={initiative} />
      </Card>
      {editMode && (
        <DialogBase open={open} onClose={onClose} width="sm">
          <InitiativeFormEditContainer
            id={initiative._id}
            onCancel={() => setEditMode(false)}
          />
        </DialogBase>
      )}
    </>
  );
};
