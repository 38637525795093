import React, { FC, useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Image, XCircle as Close } from 'react-feather';
import LinearProgress from '@material-ui/core/LinearProgress';
import classNames from 'classnames';
import { Field } from 'formik';

import { ButtonWithTooltip } from 'components/UI';
import { ErrorBox } from 'components/UI/ErrorBox';
import { createGalleryEventImage } from 'services/GalleryEventService/GalleryEventService';

import { useFormfieldStyle } from './GalleryFormfield.style';
import { useStyles } from './GalleryFormUpload.style';

type GalleryFormUploadProps = {
  name: string;
  fieldname?: string;
  onChangeImage?: (file: File) => void;
  imageUrl?: string;
  setFieldValue: (name: string, value: string | null) => void;
  onChangeReady?: (value: boolean) => void;
};

const FormUpload: FC<GalleryFormUploadProps> = ({
  onChangeImage,
  imageUrl,
  setFieldValue,
  onChangeReady,
  fieldname,
}) => {
  const formfieldClasses = useFormfieldStyle();
  const classes = useStyles();

  const [image, setImage] = useState<string | null>(imageUrl || null);
  const [error, setError] = useState<Error | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);

  const unsetImage = () => {
    setImage(null);
    if (fieldname) setFieldValue(fieldname, null);
  };

  const defaultUpload = useCallback(
    async (image: File) => {
      if (onChangeReady) onChangeReady(false);

      try {
        setError(null);
        setUploading(true);
        const galleryImage = await createGalleryEventImage(image);
        if (fieldname) setFieldValue(fieldname, galleryImage._id);
      } catch (error) {
        setError(error);
        setImage(imageUrl || null);
      } finally {
        if (onChangeReady) onChangeReady(true);
        setUploading(false);
      }
    },
    [fieldname, setFieldValue, onChangeReady, imageUrl]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      setImage(URL.createObjectURL(acceptedFiles[0]));
      if (onChangeImage) {
        onChangeImage(acceptedFiles[0]);
      } else {
        defaultUpload(acceptedFiles[0]);
      }
    },
    [onChangeImage, defaultUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false,
  });

  return (
    <>
      <div
        className={classNames(
          image ? classes.uploadedBox : classes.uploadBox,
          formfieldClasses.inputField,
          {
            [classes.uploadBoxDrag]: isDragActive,
          }
        )}
        {...getRootProps()}
      >
        {image ? (
          <>
            <img src={image} className={classes.uploadImage} alt="" />
            <ButtonWithTooltip
              round={true}
              className={classes.imageRemoveButton}
              tooltipText="Remove your uploaded image"
              onClick={unsetImage}
            >
              <Close className={classes.imageRemoveButtonIcon} />
            </ButtonWithTooltip>
          </>
        ) : (
          <div className={classes.uploadBoxInput}>
            <label className={classes.uploadBoxLabel}>
              <Image className={classes.uploadIcon} />
              Add image
            </label>
            <input
              className={classes.uploadBoxFile}
              type="file"
              accept="image/png, image/jpeg"
              id="file"
              {...getInputProps()}
            />
          </div>
        )}
        {uploading && <LinearProgress variant="indeterminate" />}
      </div>
      {error && <ErrorBox>Error during upload. ({error.toString()})</ErrorBox>}
    </>
  );
};

export const GalleryFormUpload: FC<GalleryFormUploadProps> = ({
  name,
  onChangeImage,
  imageUrl,
  children,
  setFieldValue,
  onChangeReady,
}) => {
  return (
    <Field
      name={name}
      component={FormUpload}
      fieldName={name}
      onChangeImage={onChangeImage}
      setFieldValue={setFieldValue}
      onChangeReady={onChangeReady}
      fieldname={name}
      imageUrl={imageUrl}
    >
      {children}
    </Field>
  );
};
