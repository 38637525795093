import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import moment from 'moment';

import { useStyle } from './CalendarTimeSlots.style';

export const CalendarTimeSlots: FC<{ position?: string }> = ({ position }) => {
  const { weekCell, timeSlotsWrapper, positionRight } = useStyle();

  const timeSlotsSettings = {
    startTime: '7:00',
    endTime: '21:00',
  };

  const timeSlots: string[] = [];
  let startTime = moment(timeSlotsSettings.startTime, ['HH:mm']);
  const endTime = moment(timeSlotsSettings.endTime, ['HH:mm']);

  while (startTime < endTime) {
    timeSlots.push(moment(startTime, ['H']).format('h A'));
    startTime = startTime.add(60, 'minutes');
  }

  const cellClasses = classNames(weekCell, {
    [positionRight]: position === 'right',
  });

  return (
    <Grid container direction="column" className={timeSlotsWrapper}>
      {timeSlots.map((time) => (
        <div className={cellClasses} key={time}>
          {time}
        </div>
      ))}
    </Grid>
  );
};
