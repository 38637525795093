import React, { FC, useState } from 'react';
import { CheckSquare, Link2, MapPin, Plus, Square } from 'react-feather';
import { useLocation } from 'react-router-dom';
import microsoftTeams from '@iconify/icons-mdi/microsoft-teams';
import { Icon as IconifyIcon } from '@iconify/react';
import { Checkbox, Chip, InputAdornment, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import classNames from 'classnames';
import { Field } from 'formik';
import { LocationState } from 'history';
import { useFetchAllInitiatives } from 'hooks';
import moment from 'moment';
import { useBoardContext } from 'store/BoardProvider';

import { CategorySelectBox } from 'components/Category/CategorySelectBox';
import {
  GalleryForm,
  GalleryFormDatePicker,
  GalleryFormTextField,
  GalleryFormTimePicker,
  GalleryFormUpload,
} from 'components/GalleryForm';
import { useGalleryFormStyle } from 'components/GalleryForm/GalleryForm.style';
import { initialEventFormData } from 'constants/initialStates';
import { offices, remoteOffices } from 'constants/offices';
import { searchUsers } from 'services/UserSearchService';
import { joinDateAndTime } from 'utils/dateUtils';
import { getInputDelta } from 'utils/inputUtils';
import {
  attendeeToAutocomplete,
  removeNullUsers,
  sortAutocompleteUserList,
} from 'utils/userUtils';
import { isBeforeToday, isValidURL, isWeekday } from 'utils/validationUtils';

import { useGalleryDetailsStyle } from '../GalleryDetails/GalleryDetails.style';
import { AttendeeAutocomplete } from '../GalleryForm/AttendeeAutocomplete';
import { GalleryFormAutocomplete } from '../GalleryForm/GalleryFormAutocomplete';
import { useFormfieldStyle } from '../GalleryForm/GalleryFormfield.style';

import { eventFormStyle } from './EventFormStyle';

type EventFormProps = {
  title: string;
  galleryEvent?: EventFormData;
  onClickSubmit: (values: EventFormData, { setSubmitting }) => void;
  onClickCancel: () => void;
  onChangeImage?: (image: File) => void;
};

export const EventForm: FC<EventFormProps> = ({
  title,
  galleryEvent,
  onClickSubmit,
  onClickCancel,
  onChangeImage,
  children,
}) => {
  const classes = eventFormStyle();
  const galleryClasses = useGalleryFormStyle();
  const formfieldClasses = useFormfieldStyle();
  const galleryDetailsClasses = useGalleryDetailsStyle();
  useFetchAllInitiatives(); // Make sure we have all the initiatives in the BoardContext.
  const [ready, setReady] = useState(true);
  const { initiatives } = useBoardContext();
  const location = useLocation();
  const generateRemoteLink = 'Generate Teams link';
  const initialGalleryEvent = galleryEvent;

  if (location.state && location.state['time'] && location.state['date']) {
    const state: LocationState = location.state;
    const time = state['time'];
    const date = state['date'];

    const startTime = moment(date).startOf('day');
    const endTime = moment(date).startOf('day');

    if (time === 'Morning') {
      startTime.add(9, 'hours');
      endTime.add(10, 'hours');
    } else if (time === 'Afternoon') {
      startTime.add(13, 'hours');
      endTime.add(14, 'hours');
    } else if (time === 'Evening') {
      startTime.add(17, 'hours');
      endTime.add(18, 'hours');
    }
    initialEventFormData.startDate = startTime;
    initialEventFormData.startTime = startTime;
    initialEventFormData.endTime = endTime;
  } else {
    initialEventFormData.startDate = null;
    initialEventFormData.startTime = null;
    initialEventFormData.endTime = null;
  }

  const validateRequiredString = (string: string) =>
    !string ? 'Required' : '';

  const validateStartTime = (
    time: EventFormData['startTime'],
    date: EventFormData['startDate']
  ) => {
    if (!time) return 'Required';
    if (!moment(time).isValid()) return 'Invalid time';
    return date &&
      moment(date).isSame(Date.now(), 'day') &&
      moment(time).isBefore(Date.now())
      ? 'Cannot create past events'
      : '';
  };

  const validateEndTime = (time: EventFormData['startTime']) =>
    !time ? 'Required' : !moment(time).isValid() ? 'Invalid time' : '';

  const validateDate = (date: EventFormData['startDate']) => {
    if (!date || !moment(date).isValid()) return 'Invalid date';
    if (isBeforeToday(moment(date))) return 'Cannot create past events';
    return !isWeekday(moment(date)) ? 'Date must be week day' : '';
  };

  const validateFields = (values: EventFormData) => {
    const errors: Partial<ErrorValues> = {};

    errors.title = validateRequiredString(values.title);
    errors.description = validateRequiredString(values.description);
    errors.startDate = validateDate(values.startDate);
    errors.startTime = validateStartTime(values.startTime, values.startDate);
    errors.endTime = validateEndTime(values.endTime);
    errors.office = validateRequiredString(values.office);

    if (!errors.startDate && !errors.startTime && !errors.endTime) {
      values.startTime = joinDateAndTime(
        values.startDate,
        values.startTime,
        false
      ).toDate();
      values.endTime = joinDateAndTime(
        values.startTime,
        values.endTime
      ).toDate();
    }

    // Removes non-errors in error object
    Object.keys(errors).forEach((field) => {
      if (!errors[field]) delete errors[field];
    });

    values.remotelyAccessible = Boolean(values.remoteLink);
    values.generateRemoteLink = false;
    if (values.remoteLink) {
      if (
        values.remoteLink === generateRemoteLink ||
        (values.remoteLink === initialGalleryEvent?.remoteLink &&
          initialGalleryEvent?.generateRemoteLink)
      ) {
        values.generateRemoteLink = true;
      } else if (!isValidURL(values.remoteLink)) {
        errors.remoteLink = 'Invalid Link';
      }
    }

    return errors;
  };

  const isReady = () => {
    return ready;
  };

  const onChangedStartTime = (
    setFieldValue,
    newValue: MaterialUiPickersDate,
    startDate: MaterialUiPickersDate,
    errors: Partial<ErrorValues>
  ) => {
    if (newValue) {
      errors.startTime = '';
      setFieldValue('startTime', newValue?.toDate());
      errors.endTime = '';
      setFieldValue('endTime', newValue?.add(1, 'hours').toDate());
      if (!startDate && newValue.isValid()) {
        errors.startDate = '';
        setFieldValue('startDate', newValue?.toDate());
      }
    }
  };

  const disableWeekendsAndDaysBeforeToday = (date: MaterialUiPickersDate) => {
    return date === null || !isWeekday(date) || isBeforeToday(date);
  };

  const fields = (values, setFieldValue, errors) => (
    <>
      <GalleryFormTextField
        inactive={false}
        name="title"
        label="Give title to the event"
        value={values.title}
        error={errors.title}
        onChange={(e) => {
          errors.title = '';
          setFieldValue('title', e.target.value);
        }}
        required
      />
      <GalleryFormTextField
        inactive={false}
        name="description"
        label="Describe the event"
        value={values.description}
        error={errors.description}
        onChange={(e) => {
          errors.description = '';
          setFieldValue('description', e.target.value);
        }}
        multiline
        required
        rows={2}
      />
      <Field
        as={CategorySelectBox}
        name="categories"
        selectedCategories={values.categories}
        onChange={setFieldValue}
      />
      <AttendeeAutocomplete
        existingAttendees={attendeeToAutocomplete(values.attendees)}
        multiple={true}
        inputType={values.newAttendeeIds}
        getOptionLabel={(option: Autocomplete) => option.name}
        getOptionSelected={(option: Autocomplete, value: Autocomplete) =>
          option._id === value._id
        }
        filterOptions={removeNullUsers}
        sortOptions={sortAutocompleteUserList}
        renderOption={(option, { selected }) => (
          <div className={galleryClasses.attendeeContainer}>
            <div>
              <Typography className={galleryClasses.attendeeDropDownName}>
                {option.name}
              </Typography>
              <Typography className={galleryClasses.attendeeDropDownEmail}>
                {option.email}
              </Typography>
            </div>
            <Checkbox
              checked={selected}
              icon={<Square />}
              checkedIcon={<CheckSquare />}
            />
          </div>
        )}
        renderTags={(value, tagProps) =>
          value.map((option: Autocomplete, index: number) => (
            <Chip
              key={option._id}
              label={option.name}
              deleteIcon={<Close />}
              {...tagProps({ index })}
            />
          ))
        }
        error={errors.newAttendeeIds}
        name="newAttendeeIds"
        label="Invite attendees"
        setField={(selection: Autocomplete[]) => {
          const formattedSelection = selection.map((selected) => selected._id);
          setFieldValue('newAttendeeIds', formattedSelection);
        }}
        asyncApiFunction={searchUsers}
      />
      <GalleryFormAutocomplete
        options={Object.values(initiatives)}
        getOptionLabel={(option: Initiative) => option.title}
        getOptionSelected={(option: Initiative, value: Initiative) =>
          option._id === value._id
        }
        value={values.initiative}
        error={errors.initiative}
        name="initiative"
        label="Connect to initiative"
        setField={setFieldValue}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Plus />
            </InputAdornment>
          ),
        }}
      />
      <GalleryFormUpload
        name="image"
        onChangeImage={onChangeImage}
        onChangeReady={setReady}
        imageUrl={galleryEvent?.imageUrl}
        setFieldValue={setFieldValue}
      />
      <div className={classes.dateTimeWrapper}>
        <GalleryFormDatePicker
          name="startDate"
          label="Date"
          placeholder="DD.MM.YYYY"
          inactive={values.startDate === initialEventFormData.startDate}
          disablePast={!galleryEvent}
          value={values.startDate}
          error={errors.startDate}
          shouldDisableDate={disableWeekendsAndDaysBeforeToday}
          onChange={(startDate) => {
            errors.startDate = '';
            setFieldValue('startDate', startDate);
          }}
        />
        <GalleryFormTimePicker
          name="startTime"
          inactive={values.startTime === initialEventFormData.startTime}
          placeholder="__:__"
          value={values.startTime}
          error={errors.startTime}
          onChange={(startTime) => {
            onChangedStartTime(
              setFieldValue,
              startTime,
              values.startDate,
              errors
            );
          }}
        />
        <span>to</span>
        <GalleryFormTimePicker
          name="endTime"
          inactive={values.endTime === initialEventFormData.endTime}
          placeholder="__:__"
          value={values.endTime}
          error={errors.endTime}
          onChange={(endTime) => {
            errors.endTime = '';
            setFieldValue('endTime', endTime);
          }}
        />
      </div>
      <div className={classes.locationWrapper}>
        <GalleryFormAutocomplete
          options={
            remoteOffices.includes(values.office) // If the office value is set to a remote office previously (edit screen), still show that option.
              ? offices.concat(values.office)
              : offices
          }
          value={values.office}
          error={errors.office}
          name="office"
          label="Choose a location"
          setField={setFieldValue}
          resetError={() => {
            errors.office = null;
          }}
          required
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MapPin />
              </InputAdornment>
            ),
          }}
        />

        <GalleryFormAutocomplete
          label="Link to remote event"
          placeholder="Paste a link"
          options={[generateRemoteLink]}
          name="remoteLink"
          freeSolo={true}
          value={values.remoteLink}
          error={errors.remoteLink}
          setField={setFieldValue}
          InputProps={
            values.remoteLink === generateRemoteLink
              ? {
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={galleryDetailsClasses.cardInfoLineIcon}
                    >
                      <IconifyIcon icon={microsoftTeams} />
                    </InputAdornment>
                  ),
                }
              : {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Link2 />
                    </InputAdornment>
                  ),
                }
          }
          renderOption={(option) => {
            return (
              <>
                <IconifyIcon
                  icon={microsoftTeams}
                  className={classNames(
                    galleryDetailsClasses.cardInfoLineIcon,
                    formfieldClasses.optionWithAdornment
                  )}
                  id="option-generateRemoteLink"
                />
                {option}
              </>
            );
          }}
          resetError={() => {
            errors.office = null;
          }}
          onInputChange={(_, value) => {
            errors.remoteLink = null;

            if (values.remoteLink === generateRemoteLink) {
              const newValue = getInputDelta(generateRemoteLink, value);

              setFieldValue('remoteLink', newValue);
            } else {
              setFieldValue('remoteLink', value);
            }
          }}
        />
      </div>
    </>
  );

  return (
    <GalleryForm
      title={title}
      initialValues={galleryEvent ?? initialEventFormData}
      validateFields={validateFields}
      onClose={onClickCancel}
      isReady={isReady}
      fields={fields}
      onSubmit={onClickSubmit}
    >
      {children}
    </GalleryForm>
  );
};
