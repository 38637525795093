import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  addCard: {
    cursor: 'pointer',
    height: '100%',
    minHeight: '410px',
    width: '100%',
    border: `2px dashed ${theme.palette.primary.main}`,
    borderStyle: 'dashed',
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    transition: 'background-color 200ms ease-out;',
    '&:hover': {
      backgroundColor: 'rgba(101, 89, 255, .1)',
    },
  },
  addIcon: {
    fontSize: '2rem',
  },
  addText: {
    marginTop: theme.spacing(1),
  },
  paginationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    padding: '50px 0',
    '& .MuiPaginationItem-root.MuiPaginationItem-page': {
      border: `1px solid ${theme.palette.common.light1}`,
    },
  },
}));
