import { BoardState } from 'store/BoardProvider';

export enum BoardDataActions {
  SET_SEARCHTERM = 'SET_SEARCHTERM',
  SET_LOADING = 'SET_LOADING',
  SET_ERROR = 'SET_ERROR',
}

export const dataReducer = (state: BoardState, { type, payload }) => {
  switch (type) {
    case BoardDataActions.SET_SEARCHTERM:
      return { ...state, searchTerm: payload };
    case BoardDataActions.SET_ERROR:
      return { ...state, error: payload };
    case BoardDataActions.SET_LOADING:
      return { ...state, loading: payload };
    default:
      return state;
  }
};
