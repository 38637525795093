import React, { createContext, useContext, useEffect, useState } from 'react';
import * as msal from '@azure/msal-browser';

import {
  loginRequest,
  silentRequest,
  tokenRequest,
} from 'utils/msalUtils/msal-config';

type AuthContextProps = {
  isAuthenticated: boolean;
  account: msal.AccountInfo | null;
  username: string;
  idToken: string;
  loading: boolean;
  errorMsg: string;
  mounted: boolean;
  signIn: () => Promise<void> | null;
  signOut: () => void;
  acquireToken: () => Promise<void | msal.AuthenticationResult | undefined>;
};

export const MsalContext = createContext({} as AuthContextProps);
export const useMsal = () => useContext(MsalContext);

export const MsalProvider = ({ children, config }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [account, setAccount] = useState<msal.AccountInfo | null>(null);
  const [username, setUsername] = useState('');
  const [idToken, setIdToken] = useState('');
  const [msalApp, setMsalApp] = useState<msal.PublicClientApplication | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [mounted, setMounted] = useState(false);
  const { Provider } = MsalContext;

  useEffect(() => {
    const pc = new msal.PublicClientApplication(config);
    setMsalApp(pc);
    pc.handleRedirectPromise()
      .then((response) => {
        setLoading(false);
        if (response) {
          setIdToken(response?.idToken);
          setIsAuthenticated(true);
        }
      })
      .catch((error) => {
        setErrorMsg(error);
        return null;
      });

    //getAccounts(pc);
    setMounted(true);
  }, [config]);

  const getAccounts = (pc: msal.PublicClientApplication | null) => {
    const currentAccounts = pc?.getAllAccounts();

    if (!currentAccounts) {
      console.error('No accounts detected!');
      return;
    } else if (pc) {
      if (currentAccounts.length > 1) {
        console.warn('Multiple accounts detected.');
        // Add choose account code here
        setUsername(currentAccounts[0].username);
        setAccount(pc.getAccountByUsername(currentAccounts[0].username));
        setIsAuthenticated(true);
      } else if (currentAccounts.length === 1) {
        setUsername(currentAccounts[0].username);
        setAccount(pc.getAccountByUsername(currentAccounts[0].username));
        setIsAuthenticated(true);
      }
    }
  };

  /* eslint-disable @typescript-eslint/no-non-null-assertion */
  const signIn = async () => {
    return msalApp!.loginRedirect(loginRequest);
  };

  const signOut = async () => {
    const logoutRequest: msal.EndSessionRequest = {
      account: msalApp!.getAccountByUsername(username)!,
    };

    return msalApp!.logout(logoutRequest);
  };

  const handleResponse = (response: msal.AuthenticationResult) => {
    if (response !== null) {
      setAccount(response.account);
      setUsername(response.account!.username);
      setIsAuthenticated(true);
    } else {
      getAccounts(msalApp);
    }
  };

  const acquireToken = async () => {
    silentRequest.account = msalApp!.getAccountByUsername(username)!;

    return msalApp!.acquireTokenSilent(silentRequest).catch((error) => {
      console.warn(
        'silent token acquisition fails. acquiring token using interactive method'
      );
      if (error) {
        // fallback to interaction when silent call fails
        tokenRequest.account = msalApp!.getAccountByUsername(username)!;

        return msalApp!
          .acquireTokenPopup(tokenRequest)
          .then((response) => handleResponse(response))
          .catch((err) => {
            console.error(err);
            setErrorMsg(err.errorMessage);
          });
      } else {
        console.warn(error);
      }
    });
  };
  /*eslint-enable @typescript-eslint/no-non-null-assertion */

  return (
    <Provider
      value={{
        isAuthenticated,
        username,
        account,
        idToken,
        loading,
        errorMsg,
        signIn,
        signOut,
        acquireToken,
        mounted,
      }}
    >
      {children}
    </Provider>
  );
};
