import { makeStyles } from '@material-ui/core/styles';

export const useLoaderStyles = makeStyles((theme) => ({
  loadContainer: {
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& *': { margin: theme.spacing(2) },
  },
}));
