import jwt from 'jsonwebtoken';

import { ENDPOINT_AUTH_TOKEN, ROUTES } from 'constants/endpoints';
import { handleErrors } from 'utils/fetchUtils';
import { tokenStorage } from 'utils/tokenStorage';

const fetchJwToken = (idToken: string): Promise<JwtToken> => {
  return fetch(`${ENDPOINT_AUTH_TOKEN}`, {
    method: 'POST',
    headers: new Headers({
      'Content-Type': 'application/json',
    }),
    // eslint-disable-next-line @typescript-eslint/camelcase
    body: JSON.stringify({ id_token: idToken }),
  })
    .then(handleErrors)
    .then((response) => response.json());
};

const getSessionFromJwt = (jwt: string) => {
  const base64Url = jwt.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
};

export const login = (idToken: string) =>
  fetchJwToken(idToken).then((token) => {
    tokenStorage.setAuthenticationToken(token.access_token);
  });

export const logout = () => {
  tokenStorage.removeAuthenticationToken();
  window.location.replace(ROUTES.LOGIN);
};

export const getSession = () => {
  const token = tokenStorage.getAuthenticationToken();
  if (token && jwt.decode(token)) {
    const expiry = jwt.decode(token).exp;
    const now = new Date();
    const tokenExpired = now.getTime() > expiry * 1000;

    if (tokenExpired) {
      logout();
    }
    return getSessionFromJwt(token);
  }
};

export const isLoggedIn = () => {
  return !!getSession();
};
