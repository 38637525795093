export const getSlackChannelName = (channel = ''): string => {
  let channelName = channel.trim();
  const isUrl = channelName.startsWith('http');

  if (isUrl) {
    channelName = channelName.slice(channelName.lastIndexOf('/') + 1);
  }
  return channelName.startsWith('#') ? channelName.slice(1) : channelName;
};

const getInitiativeSearchContent = (initiative: Initiative): string => {
  const titleContent = initiative.title.toLowerCase();
  const descriptionContent = initiative.description.toLowerCase();
  const officeContent = initiative.office
    ? initiative.office.toLowerCase()
    : '';
  const slackChannelContent = initiative.slackChannel
    ? initiative.slackChannel.toLowerCase()
    : '';

  return (
    titleContent + descriptionContent + officeContent + slackChannelContent
  );
};

export const filterInitiativesBySearchTerm = (
  initiatives: Initiative[],
  searchTerm: string
): Initiative[] => {
  const cleanedSearchTerm = searchTerm.toLowerCase();

  return initiatives.filter((initiative) =>
    getInitiativeSearchContent(initiative).includes(cleanedSearchTerm)
  );
};

export const filterInitiativesByCategories = (
  initiatives: Initiative[],
  categories: string[]
): Initiative[] => {
  if (categories.length) {
    return initiatives.filter((initiative) => {
      return categories.some((category) =>
        initiative.categories
          .map((category) => (category === 'Leisure' ? 'Off-Topic' : category))
          .includes(category)
      );
    });
  }

  return initiatives;
};

export const filterInitiativesByLocation = (
  initiatives: Initiative[],
  locations: string[]
): Initiative[] => {
  return initiatives.filter(
    (initiative) =>
      initiative.office &&
      locations
        .map((location) => location.toLowerCase())
        .includes(initiative.office.toLowerCase())
  );
};
