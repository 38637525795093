import { initialBoardState } from 'store/BoardProvider';

import { Category } from 'components/Category/categories';
import { OfficeName } from 'constants/offices';
import { OfficeType, OfficeTypeFilter } from 'constants/officeType';
import { StoreDateRange } from 'types/Filters';

import { Reducer } from '.';

export enum BoardFiltersActions {
  RESET = 'RESET',
  SET_OFFICE_TYPE = 'SET_OFFICE_TYPE',
  SET_DATE_RANGE = 'SET_DATE_RANGE',
  RESET_DATE_RANGE = 'RESET_DATE_RANGE',
  SET_LOCATION = 'SET_LOCATION',
  SET_CATEGORIES = 'SET_CATEGORIES',
  RESET_SELECTED_OFFICES = 'RESET_SELECTED_OFFICES',
  RESET_SELECTED_CATEGORIES = 'RESET_SELECTED_CATEGORIES',
}

export const filterReducer: Reducer = (state, { type, payload }) => {
  const { boardFilters } = state;
  switch (type) {
    case BoardFiltersActions.SET_LOCATION:
      return {
        ...state,
        boardFilters: {
          ...boardFilters,
          location: payload,
        },
      };
    case BoardFiltersActions.SET_OFFICE_TYPE:
      return {
        ...state,
        boardFilters: {
          ...boardFilters,
          officeType: payload as OfficeType,
        },
      };
    case BoardFiltersActions.RESET:
      return {
        ...state,
        boardFilters: {
          ...initialBoardState.boardFilters,
        },
      };
    case BoardFiltersActions.SET_CATEGORIES:
      return {
        ...state,
        boardFilters: {
          ...boardFilters,
          categories: payload as Category[],
        },
      };
    case BoardFiltersActions.SET_DATE_RANGE:
      return {
        ...state,
        boardFilters: {
          ...boardFilters,
          startDate: payload.startDate,
          endDate: payload.endDate,
          isDateRangeSet: true,
        },
      };
    case BoardFiltersActions.RESET_DATE_RANGE:
      return {
        ...state,
        boardFilters: {
          ...boardFilters,
          startDate: initialBoardState.boardFilters.startDate,
          endDate: initialBoardState.boardFilters.endDate,
          isDateRangeSet: false,
        },
      };
    case BoardFiltersActions.RESET_SELECTED_OFFICES:
      return {
        ...state,
        boardFilters: {
          ...boardFilters,
          location: [],
        },
      };
    case BoardFiltersActions.RESET_SELECTED_CATEGORIES:
      return {
        ...state,
        boardFilters: {
          ...boardFilters,
          categories: [],
        },
      };
    default:
      return state;
  }
};

export const setLocation = (locations: OfficeName[]) => {
  return {
    type: BoardFiltersActions.SET_LOCATION,
    payload: locations,
  };
};

export const setCategories = (categories: Category[]) => {
  return {
    type: BoardFiltersActions.SET_CATEGORIES,
    payload: categories,
  };
};

export const setDateRange = (dateRange: StoreDateRange) => {
  return {
    type: BoardFiltersActions.SET_DATE_RANGE,
    payload: dateRange,
  };
};

export const setOfficeType = (value: OfficeTypeFilter) => {
  return {
    type: BoardFiltersActions.SET_OFFICE_TYPE,
    payload: value,
  };
};

export const resetDateRange = () => {
  return {
    type: BoardFiltersActions.RESET_DATE_RANGE,
    payload: null,
  };
};

export const resetSelectedOffices = () => {
  return {
    type: BoardFiltersActions.RESET_SELECTED_OFFICES,
    payload: null,
  };
};

export const resetSelectedCategory = () => {
  return {
    type: BoardFiltersActions.RESET_SELECTED_CATEGORIES,
    payload: null,
  };
};

export const resetFilters = () => {
  return {
    type: BoardFiltersActions.RESET,
    payload: null,
  };
};
