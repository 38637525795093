import React, { FC } from 'react';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

import { useFormfieldStyle } from './GalleryFormfield.style';

type GalleryFormTextFieldProps = {
  inactive: boolean;
  name: string;
  value?: string;
  error?: string;
  placeholder?: string;
  multiline?: boolean;
  rows?: number;
  select?: boolean;
  InputProps?: { startAdornment: JSX.Element };
  InputLabelProps?: { className: string };
  label?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export const GalleryFormTextField: FC<GalleryFormTextFieldProps> = ({
  name,
  value,
  error,
  placeholder,
  multiline,
  rows,
  select,
  InputProps,
  InputLabelProps,
  children,
  label,
  required,
  onChange,
}) => {
  const classes = useFormfieldStyle();
  return (
    <Field
      component={TextField}
      variant="outlined"
      className={classes.inputField}
      name={name}
      value={value}
      error={!!error}
      required={required}
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      select={select}
      InputProps={InputProps}
      InputLabelProps={InputLabelProps}
      label={label}
      onChange={onChange}
    >
      {children}
    </Field>
  );
};
