import { ENDPOINT_INITIATIVES } from 'constants/endpoints';
import { authorizedFetch, HttpMethod } from 'services/DataService';

export const getInitiatives = async (): Promise<Initiative[]> => {
  return authorizedFetch<Initiative[]>({
    endpoint: ENDPOINT_INITIATIVES,
    httpMethod: HttpMethod.GET,
  });
};

export const getInitiative = async (
  id: Initiative['_id']
): Promise<Initiative> => {
  return authorizedFetch<Initiative>({
    endpoint: `${ENDPOINT_INITIATIVES}/${id}`,
    httpMethod: HttpMethod.GET,
  });
};

export const updateInitiative = async (
  updatedInitiative: InitiativeFormData,
  initiativeId: string
): Promise<Initiative> => {
  return authorizedFetch<Initiative>({
    endpoint: `${ENDPOINT_INITIATIVES}/${initiativeId}`,
    httpMethod: HttpMethod.PUT,
    body: updatedInitiative,
  });
};

export const createInitiative = async (
  initiative: InitiativeFormData
): Promise<Initiative> => {
  return authorizedFetch<Initiative>({
    endpoint: `${ENDPOINT_INITIATIVES}`,
    httpMethod: HttpMethod.POST,
    body: initiative,
  });
};

export const deleteInitiative = async (initiativeId: string) => {
  return authorizedFetch<{ ok: number }>({
    endpoint: `${ENDPOINT_INITIATIVES}/${initiativeId}`,
    httpMethod: HttpMethod.DELETE,
  }).then((response) => {
    if (!response.ok) throw new Error('Unknown Database error');
    return initiativeId;
  });
};
