import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import moment from 'moment';

import { useEventListItemStyles } from './EventListItem.style';

type EventListItemProps = {
  event?: GalleryEvent;
  onGoTo: () => void;
};

export const EventListItem: FC<EventListItemProps> = ({ event, onGoTo }) => {
  const classes = useEventListItemStyles();

  if (event === undefined) return null;

  const startTime = moment(event.startTime);

  return (
    <div className={classes.eventListItem} onClick={onGoTo}>
      <div className={classes.overlay}>
        <div className={classes.eventContainer}>
          <Typography variant="h5" className={classes.eventTitle}>
            {event.title}
          </Typography>
          <div className={classes.metadataWrapper}>
            <span className={classes.eventWeekMonth}>
              {startTime.format('ddd').toUpperCase()}
            </span>
            <span className={classes.eventDate}>{startTime.date()}</span>
            <span className={classes.eventWeekMonth}>
              {startTime.format('MMM').toUpperCase()}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
