import { BoardState } from 'store/BoardProvider';

import { AlertType } from 'types/AlertType';

export enum AlertActions {
  SET_SUCCESS = 'SNACKBAR_SUCCESS',
  SET_SUCCESS_CREATE = 'SNACKBAR_SUCCESS_CREATE',
  SET_CLEAR = 'SNACKBAR_CLEAR',
  SET_ERROR = 'SNACKBAR_ERROR',
  SET_WARNING = 'SNACKBAR_WARNING',
}

export const alertsReducer = (state: BoardState, { type, payload }) => {
  switch (type) {
    case AlertActions.SET_SUCCESS:
      return {
        ...state,
        alertMessage: {
          type: AlertType.success,
          message: payload,
          open: true,
        },
      };
    case AlertActions.SET_SUCCESS_CREATE:
      return {
        ...state,
        alertMessage: {
          type: AlertType.success,
          message: payload.message,
          eventId: payload.newEventId,
          open: true,
        },
      };
    case AlertActions.SET_WARNING:
      return {
        ...state,
        alertMessage: {
          type: AlertType.warning,
          message: payload,
          open: true,
        },
      };
    case AlertActions.SET_ERROR:
      return {
        ...state,
        alertMessage: {
          type: AlertType.error,
          message: payload,
          open: true,
        },
      };
    case AlertActions.SET_CLEAR:
      return {
        ...state,
        alertMessage: {
          open: false,
        },
      };
    default:
      return state;
  }
};

export const showSuccessAlert = (message) => {
  return {
    type: AlertActions.SET_SUCCESS,
    payload: message,
  };
};

export const showSuccessEventAlert = (message, newEventId) => {
  return {
    type: AlertActions.SET_SUCCESS_CREATE,
    payload: { message, newEventId },
  };
};

export const showErrorAlert = (message) => {
  return {
    type: AlertActions.SET_ERROR,
    payload: message,
  };
};

export const showWarningAlert = (message) => {
  return {
    type: AlertActions.SET_WARNING,
    payload: message,
  };
};

export const clearAlerts = () => {
  return {
    type: AlertActions.SET_CLEAR,
    payload: null,
  };
};
