import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  overlay: {
    background: 'rgba(44, 63, 107, .72)',
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
  },
  headerContainer: {
    padding: 0,
    paddingBottom: '24px',
  },
  cardHeader: {
    height: '100%',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 0,
    paddingBottom: 0,
    color: 'white',
    ...theme.typography.h5,
    fontStyle: 'normal',
  },
  cardHeaderBackground: {
    height: '120px',
    width: '100%',
    backgroundSize: 'cover',
    borderRadius: '10px',
  },
  titleContainer: {
    whiteSpace: 'nowrap',
    marginRight: '30px',
    overflow: 'hidden',
    height: '100%',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: 0,
    marginTop: '25px',
  },
  intiativeChip: {
    marginTop: '15px',
  },
}));
