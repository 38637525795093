import moment from 'moment';
import { Moment } from 'moment';

const isValidURL = (urlString: string): boolean => {
  try {
    new URL(urlString);
    return true;
  } catch (_) {
    return false;
  }
};

const isWeekday = (date: Moment): boolean => {
  return date && date.day() !== 0 && date.day() !== 6;
};

const isBeforeToday = (date: Moment): boolean => {
  return date.isBefore(moment().add(-1, 'days'));
};

export { isValidURL, isWeekday, isBeforeToday };
