import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
  weekHeader: {
    position: 'sticky',
    top: 0,
    marginBottom: 0,
    backgroundColor: theme.palette.common.white,
    zIndex: 3,
  },
  weekCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: 1,
    minWidth: 0,
    position: 'relative',
    padding: '8px 8px 20px 8px',
    boxShadow: 'inset -1px -1px 0px #E0E0E0',
    height: '84px',
  },
  weekDay: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '12px',
    paddingLeft: '8px',
    paddingTop: '8px',
    color: theme.palette.primary.baseText,
  },
  weekDate: {
    fontFamily: theme.typography.fontFamily,
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '22px',
    lineHeight: '32px',
    paddingLeft: '8px',
    paddingTop: '4px',
    color: theme.palette.text.primary,
  },
}));
