import React from 'react';
import ReactDOM from 'react-dom';
import { MsalProvider } from 'store/MsalProvider';

import { initializeAnalytics } from 'utils/analyticsUtils';
import { msalConfig } from 'utils/msalUtils/msal-config';

import { App } from './components/App';
import * as serviceWorker from './serviceWorker';
initializeAnalytics();
ReactDOM.render(
  <MsalProvider config={msalConfig}>
    <App />
  </MsalProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
