import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';

import { GalleryTab } from 'components/BoardTabs';

import { useStyles } from '../BoardHeader.style';

export const BoardNav: FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.nav}>
      <NavLink
        to={(location) => ({
          ...location,
          pathname: `/${GalleryTab.Events}`,
        })}
        activeClassName={styles.navLinkActive}
        className={styles.navLink}
      >
        All Events
      </NavLink>
      <NavLink
        to={(location) => ({
          ...location,
          pathname: `/${GalleryTab.Initiatives}`,
        })}
        activeClassName={styles.navLinkActive}
        className={styles.navLink}
      >
        All Initiatives
      </NavLink>
    </div>
  );
};
