import React, { FC, useState } from 'react';
import { CheckSquare, Square } from 'react-feather';
import { Checkbox, MenuItem, Typography } from '@material-ui/core';
import Select from '@material-ui/core/Select';

import { categories, Category } from './categories';
import { useCategorySelectBoxStyles } from './CategorySelectBox.style';

type CategorySelectBoxProps = {
  onChange: (string, value) => void;
  selectedCategories: Category[];
};

const isStringArray = (obj: unknown): obj is string[] => {
  return !!obj && !!(obj as string[]);
};

export const CategorySelectBox: FC<CategorySelectBoxProps> = ({
  onChange,
  selectedCategories,
}) => {
  const classes = useCategorySelectBoxStyles();

  const [error, setError] = useState(false);

  const handleChange = (event) => {
    let newCategories = event.target.value as Category[];
    if (newCategories.length > 3) {
      newCategories = newCategories.slice(0, 3);
      setError(true);
    }
    onChange('categories', newCategories);
    setTimeout(() => setError(false), 5000);
  };

  return (
    <Select
      className={classes.selectField}
      variant="outlined"
      displayEmpty
      value={selectedCategories}
      onChange={handleChange}
      data-testid="category-input"
      multiple
      MenuProps={{
        classes: { paper: classes.dropdownStyle },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      renderValue={(selected) => {
        if (!isStringArray(selected)) return 'Select Categories';

        if (selected.length === 0) {
          return 'Select Categories';
        }

        return <span className={classes.selected}>{selected.join(', ')}</span>;
      }}
    >
      <div className={classes.optionsHeader}>
        <Typography variant="body1" className={classes.optionsTitle}>
          Categories
        </Typography>
        {error && (
          <Typography className={classes.errorText}>
            *You can only select three categories
          </Typography>
        )}
      </div>
      {categories.map((cat) => (
        <MenuItem value={cat} key={cat} className={classes.selectOption}>
          {cat}
          <Checkbox
            checked={selectedCategories.indexOf(cat) > -1}
            icon={<Square />}
            checkedIcon={<CheckSquare />}
          />
        </MenuItem>
      ))}
    </Select>
  );
};
