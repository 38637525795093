import React, { FC, useCallback, useState } from 'react';
import { Search } from 'react-feather';
import { InputAdornment } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import _ from 'lodash';
import { useBoardContext } from 'store/BoardProvider';
import { BoardDataActions } from 'store/reducers/data';

import { Inputfield } from 'components/UI';
import { trackEventWithLabel } from 'utils/analyticsUtils';

import { useStyles } from '../BoardHeader.style';

type SearchProps = {
  placeholder?: string;
};
export const SearchBox: FC<SearchProps> = ({ placeholder = 'Search...' }) => {
  const { dispatch } = useBoardContext();
  const styles = useStyles();

  const [localSearchTerm, setLocalSearchTerm] = useState('');
  const dispatchGlobalSearchTerm = (query: string) => {
    dispatch({
      type: BoardDataActions.SET_SEARCHTERM,
      payload: query,
    });
  };
  const delayedDispatch = useCallback(
    _.debounce((query: string) => {
      dispatchGlobalSearchTerm(query);
      if (query.length && query.trim()) trackEventWithLabel('Search', query);
    }, 750),
    []
  );

  const handleSearchTermChange = (event) => {
    setLocalSearchTerm(event.target.value);
    delayedDispatch(event.target.value);
  };

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Inputfield
        className={styles.search}
        variant="outlined"
        type="search"
        placeholder={placeholder}
        value={localSearchTerm}
        onChange={handleSearchTermChange}
        InputProps={{
          id: 'filled-search',
          className: styles.searchInput,
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};
