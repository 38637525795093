import { makeStyles } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme) => ({
  groupWrapper: {
    display: 'flex',
    width: '100%',
    height: '72px',
  },
  nowrapper: {
    minHeight: '72px',
  },
  moreEvents: {
    display: 'flex',
    justifyContent: 'center',
    width: '50%',
    color: theme.palette.primary.baseText,
    border: '1px solid #E0E0E0',
    cursor: 'pointer',
  },
}));
