import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import { useStyles } from '../BoardHeader.style';

export const NewButton = ({ label, linkTo }) => {
  const styles = useStyles();

  return (
    <Grid item xs={12} sm={12} md={6}>
      <Button variant="outlined" color="primary" className={styles.newButton}>
        <Link to={linkTo} className={styles.newButtonLink}>
          <AddCircleOutlineIcon
            className={styles.newButtonIcon}
            color="primary"
          />
          {label}
        </Link>
      </Button>
    </Grid>
  );
};
