import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  cardContent: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  eventDetail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifySelf: 'flex-end',
    justifyContent: 'space-between',
  },
  descriptionRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: '5px',
  },
  link: {
    fontWeight: 'inherit',
  },
  icon: {
    width: '14px',
    height: '14px',
    margin: '2px 4px',
  },
}));
