import { makeStyles } from '@material-ui/core/styles';

export const useBoardTabsStyles = makeStyles((theme) => ({
  boardTabContainer: {
    position: 'relative',
    transition: 'none',
    marginTop: theme.spacing(1.5),
  },
  boardTabList: {
    height: theme.spacing(8),
    color: theme.palette.common.white,
    transition: 'none',
    '& .MuiTabs-scroller': {
      '& .MuiTabs-indicator': {
        height: theme.spacing(0.25),
        backgroundColor: theme.palette.common.white,
        bottom: theme.spacing(1.75),
      },
    },
  },
  boardTab: {
    fontSize: '1.375rem',
    fontWeight: 400,
    padding: 0,
    lineHeight: '1.875rem',
    paddingTop: 0,
    marginRight: theme.spacing(1),
    textTransform: 'capitalize',
    color: theme.palette.common.white,
    transition: 'none',
    minWidth: '120px',
    '&.Mui-selected': {
      fontWeight: 700,
      color: theme.palette.common.white,
    },
  },
}));
