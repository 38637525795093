import React, { FC } from 'react';
import { CardContent, Divider, Typography } from '@material-ui/core';
import { Card } from '@material-ui/core';

import { AddToCalendarButton } from 'components/UI/AddToCalendarButton';

import { useStyles } from './EventGridCard.style';
import { EventGridCardBody } from './EventGridCardBody';
import { EventGridCardDate } from './EventGridCardDate';
import { EventGridCardHeader } from './EventGridCardHeader';

export const EventGridCard: FC<{ galleryEvent: GalleryEvent }> = ({
  galleryEvent,
}) => {
  const classes = useStyles();
  const event = galleryEvent;

  return (
    <Card
      key={event._id}
      className={classes.card}
      data-testid="event-grid-card"
    >
      <EventGridCardHeader galleryEvent={event}>
        <EventGridCardDate date={event.startTime} />
      </EventGridCardHeader>
      <EventGridCardBody galleryEvent={event} />
      <Divider className={classes.divider} />

      <CardContent>
        <h3 className={classes.descTitle}>Description</h3>
        <Typography className={classes.descriptionText}>
          {event.description}
        </Typography>
      </CardContent>
      <div
        className={classes.buttonContainer}
        onClick={(e) => e.stopPropagation()}
      >
        <AddToCalendarButton galleryEvent={event} />
      </div>
    </Card>
  );
};
