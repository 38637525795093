import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  cardInfo: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: '16px 0 0 0',
  },
  cardInfoLine: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingBottom: '10px',
  },
  cardInfoIcon: {
    width: '20px',
    height: '20px',
    marginRight: '8px',
  },
  cardInfoLinkIcon: {
    border: '1px solid',
    borderRadius: '50%',
    padding: '2px',
    width: '22px',
    height: '22px',
    marginRight: '8px',
  },
  cardInfoTitle: {
    marginRight: '5px',
    fontWeight: 600,
  },
  cardInfoText: {
    color: '#4D4D4D',
  },
  cardInfoLinkActive: {
    color: theme.palette.primary.main,
  },
  cardInfoLinkInactive: {
    color: '#C2C2C2',
  },
}));
