import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    padding: '0 0 10px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '10px',
    transition: 'border 170ms ease-out',
    background: theme.palette.background.default,
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  cardContent: {
    background: theme.palette.background.default,
    ...theme.typography.body2,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '1.375rem',
  },
  cardHeaderDateContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
  },
  descriptionText: {
    color: ' #787878',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    MozBoxOrient: 'vertical',
    display: '-webkit-box',
    overflow: 'hidden',
  },
  descTitle: {
    color: ' #787878',
    margin: '5px 0',
  },
  divider: {
    backgroundColor: theme.palette.divider,
    margin: '0 8px',
  },
  buttonContainer: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'end',
  },
}));
