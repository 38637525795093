import React, { FC, useState } from 'react';
import { InputAdornment } from '@material-ui/core';
import { Event } from '@material-ui/icons';
import ClearIcon from '@material-ui/icons/Clear';
import classNames from 'classnames';
import { useBoardContext } from 'store/BoardProvider';

import { DateRangePickerDialog } from 'components/DateRangePickerDialog';
import { Inputfield } from 'components/UI';

import { useFilterRowStyles } from './BoardFilters.style';

export const CalendarFilter: FC = () => {
  const classes = useFilterRowStyles();
  const { boardFilters } = useBoardContext();
  const { isDateRangeSet } = boardFilters;

  const [dateRangeDialogOpen, setDateRangeDialogOpen] = useState(false);
  const [clearedDateRange, setClearedDateRange] = useState(false);

  const handleClickOpen = () => setDateRangeDialogOpen(true);
  const handleClose = () => setDateRangeDialogOpen(false);

  const getDateFilterString = () => {
    if (isDateRangeSet) {
      return `${boardFilters.startDate.format(
        'DD.MM.YY'
      )} - ${boardFilters.endDate.format('DD.MM.YY')}`;
    }
    return '';
  };

  return (
    <>
      <Inputfield
        className={classNames(classes.filterField, classes.boxedFilterField, {
          [classes.filterFieldActive]: isDateRangeSet,
        })}
        variant="outlined"
        placeholder="Select Date Range"
        value={getDateFilterString()}
        onClick={handleClickOpen}
        InputProps={{
          id: 'filled-calendar',
          readOnly: true,
          startAdornment: (
            <InputAdornment position="start">
              <Event />
            </InputAdornment>
          ),
          endAdornment: isDateRangeSet && (
            <InputAdornment position="end">
              <ClearIcon
                onClick={(event) => {
                  event.stopPropagation();
                  if (!clearedDateRange) {
                    setClearedDateRange(true);
                  } else {
                    setClearedDateRange(false);
                  }
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <DateRangePickerDialog
        className={classes.dateRangeDialog}
        dateRangeDialogOpen={dateRangeDialogOpen}
        onClose={handleClose}
        clearedDateRange={clearedDateRange}
      />
    </>
  );
};
