import { makeStyles } from '@material-ui/core/styles';

export const useInitiativeDeleteButtonStyles = makeStyles((theme) => {
  return {
    deleteIcon: {
      width: '1rem',
      marginRight: theme.spacing(0.75),
    },
    deleteText: {
      textTransform: 'none',
    },
    deleteButton: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '&:hover > *': {
        color: theme.palette.error.main,
      },
      '& > *': {
        color: theme.palette.error.light,
      },
    },
  };
});
