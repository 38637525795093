import { SilentRequest } from '@azure/msal-browser';

import { ROUTES } from 'constants/endpoints';

export const msalConfig = {
  auth: {
    clientId: 'd69c1a71-62c3-4a2d-aa97-89b14b251f8a',
    authority:
      'https://login.microsoftonline.com/d4110288-bf1e-4337-97ab-a5c51371dcea',
    redirectUri: ROUTES.AUTH_CALLBACK,
    navigateToLoginRequestUrl: false,
    navigateToRequestUrl: true,
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
  scopes: ['User.Read', 'email', 'openid'],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const apiRequest = {
  scopes: ['Calendars.Read'],
  forceRefresh: false, // Set this to "true" to skip a cached token and go to the server to get a new token
};

// Coordinates and required scopes for your web API
export const apiConfig = {
  resourceUri: 'https://localhost:44351/api/profile',
  resourceScopes: ['openid'],
};

// Add here scopes for silent token request
export const silentRequest: SilentRequest = {
  scopes: ['openid'],
};

// Add here scopes for access token to be used at the API endpoints.
export const tokenRequest: SilentRequest = {
  scopes: [...apiConfig.resourceScopes],
};
