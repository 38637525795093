import { logout } from 'services/AuthenticationService';
import { tokenStorage } from 'utils/tokenStorage';

export enum HttpMethod {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

type RequestParams = {
  endpoint: string;
  httpMethod: HttpMethod;
  body?: object;
};

type FormParams = {
  endpoint: string;
  httpMethod: HttpMethod;
  body: FormData;
};

const getHeaders = () => {
  return {
    Authorization: `Bearer ${tokenStorage.getAuthenticationToken()}`,
  };
};

const fetchWrapper = async <T>(
  responsePromise: Promise<Response>,
  endpoint: string
): Promise<T> => {
  const response = await responsePromise;
  const result = await response.json();
  if (response.status === 401) {
    console.error('Authentification error, please login again');
    logout();
  } else if (response.status === 404) {
    console.log(
      `Fetching "${endpoint}" resulted in the following error: `,
      response.status + ':' + result?.message
    );
    throw new Error('Page not found.');
  }
  if (!response.ok) {
    console.log(
      `Fetching "${endpoint}" resulted in the following error: `,
      response.status + ':' + result?.message
    );
    throw new Error(
      'Internal error. Please use our feedback form to submit a bug report if it is appropriate. '
    );
  }
  return result;
};

export const authorizedFetch = async <T>({
  endpoint,
  httpMethod,
  body,
}: RequestParams): Promise<T> => {
  return await fetchWrapper(
    fetch(endpoint, {
      method: httpMethod,
      headers: new Headers({
        ...getHeaders(),
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(body),
    }),
    endpoint
  );
};

export const authorizedForm = async <T>({
  endpoint,
  httpMethod,
  body,
}: FormParams): Promise<T> => {
  return await fetchWrapper(
    fetch(endpoint, {
      method: httpMethod,
      headers: new Headers({
        ...getHeaders(),
      }),
      body,
    }),
    endpoint
  );
};
