import { makeStyles } from '@material-ui/core/styles';

export const useLoginStyles = makeStyles((theme) => ({
  loginScreen: {
    width: '100%',
    height: '100vh',
    backgroundColor: '#0b0a1b',
    backgroundImage: 'url(synchronicity_background.svg)',
    backgroundSize: 'cover',
    backgroundPosition: '50% 25%',
  },
  loginContainer: {
    position: 'fixed',
    marginTop: theme.spacing(15),
    width: '500px',
    left: '50%',
    marginLeft: '-250px',
  },
  loginRow: {
    display: 'flex',
    justifyContent: 'center',

    '& .MuiGridItem': {
      padding: '0',
    },
  },
  loginFormContainer: {
    padding: theme.spacing(2),
  },
  logo: {
    height: '35px',
    width: '140px',
  },
  loginHeading: {
    color: '#9d8dff',
    fontSize: '3.5em',
    fontWeight: 'bold',
    lineHeight: 1.43,
  },
  loginSubHeading: {
    color: theme.palette.common.white,
    fontSize: '1.5em',
    fontWeight: 'bold',
    textAlign: 'center',
    lineHeight: 1.43,
  },
  loginButton: {
    display: 'flex',
    margin: theme.spacing(2),
    marginTop: theme.spacing(2),
    justifyContent: 'center',
  },
}));
