import React, { FC, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import plusCircleOutline from '@iconify/icons-mdi/plus-circle-outline';
import { Icon } from '@iconify/react';
import { Grid } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import _ from 'lodash';
import moment from 'moment';
import { useBoardContext } from 'store/BoardProvider';
import { setDateRange } from 'store/reducers/filter';

import { GalleryTab } from 'components/BoardTabs';
import { EventViewProp } from 'components/EventsPage/GalleryEventBoard';

import { EventGridCard } from './component/EventGridCard';
import { useStyles } from './EventGridView.style';

const numberCardPerPage = 6;

export const EventGridView: FC<EventViewProp> = ({ galleryEvents }) => {
  const history = useHistory();
  const classes = useStyles();
  const { dispatch } = useBoardContext();
  const [pageNumber, setPageNumber] = useState(1);
  const dateNow = moment(new Date()).startOf('day');

  const setDate = () =>
    dispatch(
      setDateRange({
        startDate: dateNow,
        endDate: dateNow.clone().add(1, 'week'),
      })
    );

  useEffect(setDate, []);
  const goToEventDetails = (id: string) => {
    history.push(`/${GalleryTab.Events}/grid/${id}`);
  };
  const createNewEvent = () => history.push(`/${GalleryTab.Events}/grid/new`);

  const sortGalleryEvents = galleryEvents.sort((a, b) =>
    a.startTime.localeCompare(b.startTime)
  );

  const eventPages = [
    _.take(sortGalleryEvents, numberCardPerPage - 1),
    ..._(sortGalleryEvents)
      .drop(numberCardPerPage - 1)
      .chunk(numberCardPerPage),
  ];

  const pageChange = (event, value) => {
    setPageNumber(value);
  };

  return (
    <>
      <Grid container direction="row" spacing={2}>
        {pageNumber === 1 && (
          <Grid onClick={() => createNewEvent()} item xs={12} sm={12} md={6}>
            <div className={classes.addCard}>
              <Icon className={classes.addIcon} icon={plusCircleOutline} />
              <h2 className={classes.addText}>Create an event</h2>
            </div>
          </Grid>
        )}
        {eventPages.length > 0 &&
          eventPages[pageNumber - 1].map((event) => (
            <Grid
              item
              key={event._id}
              xs={12}
              sm={12}
              md={6}
              onClick={() => goToEventDetails(event._id)}
            >
              <EventGridCard galleryEvent={event} />
            </Grid>
          ))}
      </Grid>
      {eventPages.length > 1 && (
        <Pagination
          className={classes.paginationContainer}
          page={pageNumber}
          onChange={pageChange}
          count={eventPages.length}
          color="primary"
          size="large"
        />
      )}
    </>
  );
};
