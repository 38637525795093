interface Document {
  hidden?: boolean;
  msHidden?: boolean;
  webkitHidden?: boolean;
}

declare let document: Document;

export const getBrowserVisibilityProp = () => {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange';
  } else {
    return '';
  }
};
export const getBrowserDocumentHiddenProp = () => {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden';
  } else if (typeof document.msHidden !== 'undefined') {
    return 'msHidden';
  } else if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden';
  } else {
    return '';
  }
};
export const getIsDocumentHidden = () => {
  return !document[getBrowserDocumentHiddenProp()];
};
