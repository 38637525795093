import React, { FC, ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { CardContent, Chip } from '@material-ui/core';
import { useLazyLoadImage } from 'hooks';

import { GalleryTab } from 'components/BoardTabs';

import { useStyles } from './EventGridCardHeader.style';

export const EventGridCardHeader: FC<{
  children: ReactNode;
  galleryEvent: GalleryEvent;
}> = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const loaded = useLazyLoadImage(props.galleryEvent.image?.location);

  return (
    <div>
      <CardContent className={classes.headerContainer}>
        <div
          className={classes.cardHeaderBackground}
          style={{
            backgroundImage: `url(${loaded || '/boids.png'})`,
          }}
        >
          <div className={classes.overlay}>
            <div className={classes.cardHeader}>
              <div className={classes.titleContainer}>
                <h3 className={classes.title}>{props.galleryEvent.title}</h3>
                {props.galleryEvent.initiative !== null && (
                  <div onClick={(e) => e.stopPropagation()}>
                    <Chip
                      label={`#${props.galleryEvent.initiative?.title}`}
                      className={classes.intiativeChip}
                      onClick={() => {
                        history.push(`/${GalleryTab.Initiatives}/`);
                      }}
                    />
                  </div>
                )}
              </div>
              {props.children}
            </div>
          </div>
        </div>
      </CardContent>
    </div>
  );
};
