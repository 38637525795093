import React, { FC, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useErrorableAction } from 'hooks/useErrorableAction';
import moment from 'moment';
import { showSuccessEventAlert } from 'store/reducers/alerts';
import { createEvent } from 'store/reducers/events';

import { EventForm } from 'components/EventForm/EventForm';
import { initialEventFormData } from 'constants/initialStates';
import { createGalleryEvent } from 'services/GalleryEventService/GalleryEventService';
import { trackEvent } from 'utils/analyticsUtils';
import { joinDateAndTime } from 'utils/dateUtils';

import { useBoardContext } from '../../store/BoardProvider';

type EventFormAddContainerProps = {
  onCancel: () => void;
};

const isProduction = process.env.REACT_APP_ENV === 'production';

export const EventFormAddContainer: FC<EventFormAddContainerProps> = ({
  onCancel,
}) => {
  const { ErrorComponent, submit } = useErrorableAction(
    createGalleryEvent,
    createEvent
  );
  const [referrerInitiative, setReferrerInitiative] = useState<Initiative>();
  const location = useLocation();
  const { initiatives } = useBoardContext();
  const { dispatch } = useBoardContext();

  React.useEffect(() => {
    // Check if EventFormAddContainer has been opened from an initiative view.
    setReferrerInitiative(
      Object.values(initiatives).find(
        (initiative) => initiative._id === location.state?.['fromInitiative']
      )
    );
  }, [initiatives, location.state]);

  const history = useHistory();
  const goToPreviousView = () => {
    if (referrerInitiative && referrerInitiative._id) {
      history.push(`/initiatives/${referrerInitiative._id}`);
    } else {
      onCancel();
    }
  };

  const onSubmit = async (values: EventFormData, { setSubmitting }) => {
    const newGalleryEvent = {
      title: values.title,
      description: values.description,
      startTime: moment(values.startTime).toISOString(),
      endTime: joinDateAndTime(values.startTime, values.endTime).toISOString(),
      office: isProduction ? values.office : 'Test Office',
      generateRemoteLink: values.generateRemoteLink,
      remotelyAccessible: values.remotelyAccessible,
      remoteLink: values.remotelyAccessible ? values.remoteLink : '',
      categories: values.categories,
      initiative: values.initiative?._id,
      image: values.image,
      newAttendeeIds: values.newAttendeeIds,
    };
    const { success, entity } = await submit(newGalleryEvent);
    setSubmitting(false);
    if (success) {
      goToPreviousView();
    }
    if (entity) {
      dispatch(showSuccessEventAlert('created successfully!', entity._id));
    }

    trackEvent('Event Created');
  };

  return (
    <>
      <EventForm
        title="New Event"
        onClickSubmit={onSubmit}
        onClickCancel={goToPreviousView}
        galleryEvent={{
          ...initialEventFormData,
          initiative: referrerInitiative || initialEventFormData.initiative,
          categories: referrerInitiative?.categories ?? [],
        }}
      />
      <ErrorComponent />
    </>
  );
};
