import React, { FC } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Board } from 'components/Board';
import { GalleryTab } from 'components/BoardTabs';
import { GalleryDialog } from 'components/GalleryDialog';

import { InitiativeBoard } from './InitiativeBoard';

const INITIATIVES_PATH = `/${GalleryTab.Initiatives}`;

export const InitiativesPage: FC = () => {
  const { id } = useParams<{ id?: string }>();

  const history = useHistory();

  const goToTab = () => {
    history.push(INITIATIVES_PATH);
  };

  return (
    <Board>
      <InitiativeBoard />
      <GalleryDialog
        open={id !== undefined}
        id={id}
        type={GalleryTab.Initiatives}
        onClose={goToTab}
      />
    </Board>
  );
};
