import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    padding: theme.spacing(2),
    flexDirection: 'column',
    boxSizing: 'border-box',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: '8px',
    transition: 'border 170ms ease-out',
    background: theme.palette.background.default,
    cursor: 'pointer',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  categories: {
    ...theme.typography.body2,
    fontWeight: 600,
  },
  cardHeader: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexWrap: 'wrap',
    alignItems: 'center',
    '& > *': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  cardHeaderTitle: {
    color: theme.palette.common.black,
  },
  cardHeaderOwner: {
    color: theme.palette.primary.baseText,
    lineHeight: 'calc(2rem - 2px)',
  },
  descriptionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  descriptionHeaderTitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    fontWeight: 400,
    color: theme.palette.primary.baseText,
  },
  buttonContainer: {
    display: 'flex',
  },
  cardContent: {
    background: theme.palette.background.default,
    ...theme.typography.body2,
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '1.375rem',
  },
  link: {
    fontWeight: 'inherit',
  },
  actionBar: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  links: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  linkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  linkIcon: {
    width: '16px',
    marginRight: theme.spacing(0.75),
  },
  ownerCotainer: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  ownerIcon: {
    width: '2rem',
    height: '2rem',
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: 'calc(2rem - 2px)',
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.light3,
    marginRight: theme.spacing(1),
  },
  loadingIndicator: {
    marginTop: theme.spacing(2),
  },
}));
