import React, { FC } from 'react';
import { Button, LinearProgress } from '@material-ui/core';
import { Form, Formik } from 'formik';

import { useGalleryFormStyle } from './GalleryForm.style';

type GalleryFormProps = {
  title?: string;
  initialValues;
  validateFields: (values) => Partial<ErrorValues>;
  onSubmit: (values: EventFormData, { setSubmitting }) => void;
  onClose: () => void;
  isReady?: () => boolean;
  fields: (values, setFieldValue, errors) => React.ReactNode;
};

export const GalleryForm: FC<GalleryFormProps> = ({
  title,
  initialValues,
  validateFields,
  onSubmit,
  onClose,
  isReady,
  fields,
  children,
}) => {
  const classes = useGalleryFormStyle();

  return (
    <Formik
      initialValues={initialValues}
      validate={validateFields}
      validateOnBlur={false}
      validateOnChange={false}
      enableReinitialize={true}
      onSubmit={onSubmit}
    >
      {({ submitForm, isSubmitting, values, setFieldValue, errors }) => (
        <Form>
          <div className={classes.form}>
            <h3 className={classes.formTitle}>{title}</h3>
            {fields(values, setFieldValue, errors)}
            {(isReady ? isReady() : true) && isSubmitting && <LinearProgress />}
            <div className={classes.buttonRow}>
              {children}
              <Button
                onClick={onClose}
                variant="outlined"
                className={classes.cancelButton}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  submitForm();
                }}
                variant="contained"
                disabled={isSubmitting || (!!isReady && !isReady())}
                color="primary"
              >
                Save
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
